import { VARIANT as CAROUSEL_VARIANT } from '@/components/data-display/carousel/carousel.types';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import { IMAGE_DESCRIPTION_METRICS } from '@/components/data-display/image/image.types';
import { SIZE, VARIANT } from '@/components/inputs/button/button.types';
import { LOGO_SIZE } from '@/components/integrated/hero-card/hero-card.types';
import {
  LOGO_SIZE as PARTNER_TILE_LOGO_SIZE,
  TEXT_SIZE as PARTNER_TILE_TEXT_SIZE,
  VARIANT as PARTNER_TILE_VARIANT,
  PartnerTileProperties,
} from '@/components/integrated/partner-tile/partner-tile.types';
import Market from '@/models/market';
import Partner from '@/models/partner';
import { SectionFormatted } from '@/modules/contentful-section/contentful-section.types';
import { TILE_TYPE } from '@/modules/partner/partner-list/partner-list.types';
import { getAccessTokenFromSession } from '@/providers/auth0';
import {
  AUDIENCES,
  GetCustomSessionResponse,
} from '@/providers/auth0/auth0.types';
import FSProvider from '@/providers/financial-services/financial-services.provider';
import { GetFinancialServicesResponse } from '@/providers/financial-services/financial-services.types';
import { B2BToken, SORT_TYPES } from '@/types';
import {
  DEFAULT_VCC_IMAGE_HEIGHT,
  DEFAULT_VCC_TEXT_ALIGN,
  DEFAULT_VCC_TEXT_COLOR,
  handleDynamicVersatileCard,
} from '@/utils/contentful-data-formatters/contentful-data-formatters.utils';
import { Session } from '@auth0/nextjs-auth0';
import metrics from 'datadog-metrics';
import { getMembershipId } from '../extract-user-data';
import { formatHigherRate } from '../format-higher-rate';
import { formatWasRate } from '../format-was-rate';
import { markdownToHTML } from '../markdown-to-html';
import {
  AccordionFormatted,
  AccordionResponse,
  ArticleStoryFormatted,
  ArticleStoryResponse,
  ButtonResponse,
  CONTENT_TYPES,
  ContainerResponse,
  ContentRowResponse,
  ContentfulMerchant,
  DynamicVersatileCardContentfulResponse,
  FLEX_POSITIONS,
  FORMATTER_METRICS,
  FilteredPartnersItems,
  FinancialSectionData,
  FinancialServicesBannerResponse,
  HydratedPartner,
  HydratedPartnerItem,
  HydratedSectionResponse,
  HydratedVersatileCard,
  IMAGE_POSITION,
  ImageOptimisedResponse,
  ItemRecord,
  MicrocopyGPT,
  PartnerListResponse,
  SECTION_VISIBILITY_FOR_SESSION,
  SectionsResponse,
  SuperheroFormatted,
  SuperheroResponse,
  TitleCopyCtaBlockFormatted,
  TitleCopyCtaBlockResponse,
  VersatileCardContentfulResponse,
  VersatileCardFormatted,
  VersatileCardResponse,
} from './contentful-data-formatters.types';

const formatTitleCopyCtaBlockContent = (
  item: Partial<TitleCopyCtaBlockResponse>,
) => ({
  headingOne: item?.headingOne ?? null,
  headingOneColor: item?.headingOneColor ?? null,
  headingOneTextAlign: item?.headingOneTextAlign ?? 'left',
  headingTwo: item?.headingTwo ?? null,
  headingTwoColor: item?.headingTwoColor ?? null,
  headingTwoTextAlign: item?.headingTwoTextAlign ?? 'left',
  headingThree: item?.headingThree ?? null,
  headingThreeColor: item?.headingThreeColor ?? null,
  headingThreeTextAlign: item?.headingThreeTextAlign ?? 'left',
  headingFour: item?.headingFour ?? null,
  headingFourColor: item?.headingFourColor ?? null,
  headingFourTextAlign: item?.headingFourTextAlign ?? 'left',
});

export const formatTextBlock = (
  item: any,
  isFullBleed: boolean | null = null,
) => {
  return item
    ? {
        ...item,
        id: item.sys.id,
        isFullBleed,
        buttonsCollection:
          item.buttonsCollection.items.length > 0
            ? item.buttonsCollection.items?.map((button: ButtonResponse) =>
                formatButton(button),
              )
            : [],
      }
    : null;
};

const formatTitleCopyCtaBlockDescription = (
  item: Pick<
    TitleCopyCtaBlockResponse,
    | 'description'
    | 'descriptionColour'
    | 'descriptionTextAlign'
    | 'overrideDescriptionFontSize'
  >,
) => {
  return {
    description: item?.description ?? null,
    descriptionColor: item?.descriptionColour ?? null,
    descriptionTextAlign: item?.descriptionTextAlign ?? 'left',
    overrideDescriptionFontSize: item?.overrideDescriptionFontSize ?? 'initial',
  };
};

export const formatPartnerLogoSize = (size: string) => {
  switch (size) {
    case 'extra-small': {
      return PARTNER_TILE_LOGO_SIZE.extraSmall;
    }
    case 'small': {
      return PARTNER_TILE_LOGO_SIZE.small;
    }
    case 'medium': {
      return PARTNER_TILE_LOGO_SIZE.medium;
    }
    case 'large': {
      return PARTNER_TILE_LOGO_SIZE.large;
    }
    default: {
      return PARTNER_TILE_LOGO_SIZE.small;
    }
  }
};

export const formatPartnerTextSize = (size: string) => {
  switch (size) {
    case 'extra-small': {
      return PARTNER_TILE_TEXT_SIZE.extraSmall;
    }
    case 'small': {
      return PARTNER_TILE_TEXT_SIZE.small;
    }
    case 'medium': {
      return PARTNER_TILE_TEXT_SIZE.medium;
    }
    case 'large': {
      return PARTNER_TILE_TEXT_SIZE.large;
    }
    default: {
      return PARTNER_TILE_TEXT_SIZE.small;
    }
  }
};

export const formatPartnerVariant = (variant: string) => {
  switch (variant) {
    case 'default': {
      return PARTNER_TILE_VARIANT.Default;
    }
    case 'inline': {
      return PARTNER_TILE_VARIANT.Inline;
    }
    default: {
      return PARTNER_TILE_VARIANT.Default;
    }
  }
};

export const formatPartnerTile = (
  partner: HydratedPartner,
  microcopy?: MicrocopyGPT,
): PartnerTileProperties | null => {
  if (!partner || !microcopy) {
    return null;
  }

  return {
    ...(partner?.partnerId && { partnerId: partner.partnerId }),
    ...(partner?.mechanicId && { mechanicId: partner.mechanicId }),
    name: partner?.name,
    slug: partner?.slug ?? '',
    logo: {
      src: partner?.logoSrc ?? '',
      alt: partner?.name ?? '',
      logoSize: formatPartnerLogoSize(partner?.logoSize ?? ''),
    },
    rate:
      formatHigherRate(
        partner.rate,
        microcopy?.merchant_rateupto,
        microcopy?.merchant_ratefrom,
        partner.noOfCollectionMethods,
      ) ?? '',
    wasRate: formatWasRate(partner?.wasRate, microcopy.merchant_ratewas) ?? '',
    textSize: formatPartnerTextSize(partner?.textSize ?? ''),
    variant: formatPartnerVariant(partner?.variant ?? ''),
    separator: partner.separator ?? false,
    isSpeedyAwarding: partner.isSpeedyAwarding ?? false,
    speedyAwardText: microcopy?.speedyaward_title ?? '',
  };
};

export const formatTitleCopyCtaBlock = (
  titleCopyCtaBlock: TitleCopyCtaBlockResponse,
  isFullBleed: boolean | null = null,
): TitleCopyCtaBlockFormatted => {
  const content = formatTitleCopyCtaBlockContent(titleCopyCtaBlock);
  const description = formatTitleCopyCtaBlockDescription(titleCopyCtaBlock);

  return {
    ...content,
    ...description,
    contentType: titleCopyCtaBlock.contentType,
    id: titleCopyCtaBlock.sys.id,
    htmlId: titleCopyCtaBlock?.htmlId ?? null,
    name: titleCopyCtaBlock?.name ?? null,
    linkPrimary: {
      url: titleCopyCtaBlock.linkPrimaryUrl ?? '',
      label: titleCopyCtaBlock.linkPrimaryLabel ?? null,
    },
    linkSecondary: {
      url: titleCopyCtaBlock.linkSecondaryUrl ?? '',
      label: titleCopyCtaBlock.linkSecondaryLabel ?? null,
    },
    primaryCta: {
      url: titleCopyCtaBlock?.primaryCta?.url ?? '',
      label: titleCopyCtaBlock?.primaryCta?.label ?? null,
    },
    secondaryCta: {
      url: titleCopyCtaBlock?.secondaryCta?.url ?? '',
      label: titleCopyCtaBlock?.secondaryCta?.label ?? null,
    },
    openLinksInNewTab: titleCopyCtaBlock?.openLinksInNewTab ?? null,
    openCtasInNewTab: titleCopyCtaBlock?.openCtasInNewTab ?? null,
    inlineButtons: titleCopyCtaBlock?.inlineButtons ?? null,
    isFullBleed,
  };
};

const formatVersatileCardPartnerData = (
  content: VersatileCardResponse,
): HydratedPartner => {
  if (!content?.partner || content.partner.length === 0) {
    return null;
  }

  return {
    ...content.partner[0],
    variant: content?.partnerVariant ?? null,
    textSize: content?.partnerTextSize ?? null,
    logoSize: content?.partnerLogoSize ?? null,
    separator: content?.partnerSeparatorLine ?? false,
  };
};

const formatVersatileCardHeadings = (content: VersatileCardResponse) => ({
  headingOne: content?.headingOne ?? null,
  headingTwo: content?.headingTwo ?? null,
  headingThree: content?.headingThree ?? null,
  headingFour: content?.headingFour ?? null,
  headingFive: content?.headingFive ?? null,
});

const formatVersatileCardContent = (
  content: VersatileCardResponse,
  microcopy?: MicrocopyGPT,
): VersatileCardFormatted['content'] => {
  const partner = formatVersatileCardPartnerData(content);

  return {
    ...formatVersatileCardHeadings(content),
    body: content?.bodyContent ? markdownToHTML(content.bodyContent) : null,
    textAlign: content?.textAlign ?? DEFAULT_VCC_TEXT_ALIGN,
    textColor: content?.textColor ?? DEFAULT_VCC_TEXT_COLOR,
    partner: partner ? formatPartnerTile(partner, microcopy) : null,
    showPartnerFavouriteToggle: partner
      ? content?.showPartnerFavouriteToggle ?? false
      : false,
  };
};

const formatVersatileCardCTAs = (item: {
  ctaFullWidth?: boolean | null;
  imageButtonPrimary: {
    url: string;
    description: string;
    height: number;
    width: number;
  } | null;
  imageButtonPrimaryUrl: string | null;
  imageButtonSecondary: {
    url: string;
    description: string;
    height: number;
    width: number;
  } | null;
  imageButtonSecondaryUrl: string | null;
  openCtasInNewTab?: boolean | null;
}) => {
  return {
    ctaFullWidth: item?.ctaFullWidth ?? false,
    imageButtonPrimary: item?.imageButtonPrimary
      ? {
          src: item.imageButtonPrimary.url,
          height: item.imageButtonPrimary.height,
          width: item.imageButtonPrimary.width,
          url: item?.imageButtonPrimaryUrl ?? '',
          alt: item?.imageButtonPrimary?.description ?? '',
        }
      : null,
    imageButtonSecondary: item?.imageButtonSecondary
      ? {
          src: item.imageButtonSecondary.url,
          height: item.imageButtonSecondary.height,
          width: item.imageButtonSecondary.width,
          url: item?.imageButtonSecondaryUrl ?? '',
          alt: item?.imageButtonSecondary?.description ?? '',
        }
      : null,
    openCtasInNewTab: item?.openCtasInNewTab ?? null,
  };
};

export const formatVersatileCardYoutube = (
  item: VersatileCardResponse,
): VersatileCardFormatted['youtube'] | null => {
  if (item?.youtubeId) {
    return {
      youtubeID: item?.youtubeId,
      autoPlay: item?.youtubeAutoPlay,
      title: item?.youtubeTitle,
      videoHeight: item?.youtubeVideoHeight,
    };
  }
  return null;
};

export const formatImageOptimised = (image: ImageOptimisedResponse) => ({
  description: image?.largeImage.description ?? '',
  desktopImageUrl: image?.largeImage.url ?? '',
  mobileImageUrl: image?.smallImage.url ?? '',
});

export const handleImageDescription = (
  imageName: string,
  description?: string | null,
) => {
  if (description) return description;

  incrementImageMetric(imageName);
  return '';
};

export const incrementImageMetric = (imageName: string) => {
  metrics.increment(
    IMAGE_DESCRIPTION_METRICS.CONTENTFUL_MISSING_DESCRIPTION,
    1,
    [`imageName:${imageName}`],
  );
};

const formatVersatileCardImage = (item: VersatileCardResponse) => ({
  src: item?.image?.url ?? '',
  alt: handleImageDescription(
    item?.image?.title as string,
    item?.image?.description,
  ),
  position: (item?.imagePosition as IMAGE_POSITION) ?? null,
  height: item?.imageHeight || DEFAULT_VCC_IMAGE_HEIGHT,
  mobileHeight: item?.imageMobileHeight || DEFAULT_VCC_IMAGE_HEIGHT,
  width: item?.imageWidth ?? null,
  contained: item?.imageContained ?? false,
  optimised: formatImageOptimised(item?.imageOptimised),
});

export const formatVersatileCard = (
  item: VersatileCardResponse,
  microcopy?: MicrocopyGPT,
): VersatileCardFormatted => {
  return {
    id: item?.sys?.id ?? '',
    htmlId: item?.htmlId ?? null,
    contentType: item?.contentType ?? null,
    backgroundColor: item?.backgroundColor ?? null,
    content: formatVersatileCardContent(item, microcopy),
    image: formatVersatileCardImage(item),
    links: {
      linkPrimary: item?.linkPrimary ?? '',
      linkLabelPrimary: item?.linkLabelPrimary ?? null,
      linkSecondary: item?.linkSecondary ?? '',
      linkLabelSecondary: item?.linkLabelSecondary ?? null,
      openLinksInNewTab: item?.openLinksInNewTab ?? null,
      cardIsPrimaryLink: item?.cardIsPrimaryLink ?? false,
    },
    ctas: formatVersatileCardCTAs(item),
    youtube: formatVersatileCardYoutube(item),
    borderless: item?.borderless ?? false,
    spaceBetweenHeadingAndBody: item?.spaceBetweenHeadingAndBody ?? false,
    buttonsCollection: item?.buttonsCollection
      ? item?.buttonsCollection?.items?.map(button => formatButton(button))
      : null,
  };
};

export const formatArticleStory = (
  article: ArticleStoryResponse,
): ArticleStoryFormatted => ({
  id: article.sys.id,
  htmlId: article.htmlId ?? null,
  contentType: article.contentType,
  imageAlt: article.imageAlt ?? null,
  imageSrc: article?.image?.url ?? null,
  youtubeId: article?.youtubeId ?? null,
  youtubeTitle: article?.youtubeTitle ?? null,
  youtubeAutoPlay: article?.youtubeAutoPlay ?? false,
  content: article?.content ? formatTitleCopyCtaBlock(article.content) : null,
});

export const formatPartnerListCard = (
  partner: HydratedPartnerItem,
  partnerTileVariant: string,
  microcopy?: MicrocopyGPT,
): VersatileCardFormatted => {
  const partnerTileVariantFormatted = formatPartnerVariant(partnerTileVariant);
  return {
    id: partner.slug,
    backgroundColor: null,
    image: {
      src: partner.heroSrc ?? '',
      alt: partner.name ?? '',
      position: IMAGE_POSITION.Top,
      height: 200,
      mobileHeight: 200,
      contained: false,
      width: null,
      optimised: null,
    },
    content: {
      partner: {
        partnerId: partner?.partnerId ?? null,
        mechanicId: partner.mechanicId,
        name: partner.name,
        slug: partner.slug,
        destinationUrl: partner.destinationUrl,
        logo: {
          src: partner.logoSrc,
          alt: partner.name,
          logoSize: LOGO_SIZE.small,
        },
        rate: formatHigherRate(
          partner.rate,
          microcopy?.merchant_rateupto,
          microcopy?.merchant_ratefrom,
          partner.noOfCollectionMethods,
        ),
        wasRate: formatWasRate(partner?.wasRate, microcopy?.merchant_ratewas),
        isSpeedyAwarding: partner.isSpeedyAwarding ?? false,
        speedyAwardText: microcopy?.speedyaward_title,
        variant: partnerTileVariantFormatted,
        separator: Boolean(
          partnerTileVariantFormatted === PARTNER_TILE_VARIANT.Inline,
        ),
      },
      textAlign: DEFAULT_VCC_TEXT_ALIGN,
    },
    youtube: null,
    borderless: false,
    spaceBetweenHeadingAndBody: false,
  };
};

export const formatPartnerList = (
  partnerList: PartnerListResponse,
  microcopy?: MicrocopyGPT,
) => {
  const partnerListBase = {
    id: partnerList.sys.id,
    contentType: partnerList.contentType,
    listType: `partner-list--${partnerList.listType}`,
    tileType: partnerList.tileType,
  };

  if (partnerList.tileType === TILE_TYPE.Hero) {
    return {
      ...partnerListBase,
      partnerList: partnerList.partners.map(partner => {
        return formatPartnerListCard(
          partner,
          partnerList?.partnerVariant,
          microcopy,
        );
      }),
    };
  }

  return {
    ...partnerListBase,
    partnerList: partnerList.partners.map(partner => {
      return {
        name: partner.name,
        mechanicId: partner.mechanicId,
        slug: partner.slug,
        destinationUrl: partner.destinationUrl,
        altLogo: partner.name,
        logoSrc: partner.logoSrc,
        logoSize: LOGO_SIZE.small,
        rate: formatHigherRate(
          partner.rate,
          microcopy?.merchant_rateupto,
          microcopy?.merchant_ratefrom,
          partner.noOfCollectionMethods,
        ),
        wasRate: formatWasRate(partner?.wasRate, microcopy?.merchant_ratewas),
        speedyAwardText: microcopy?.speedyaward_title,
        isSpeedyAwarding: partner.isSpeedyAwarding ?? false,
        variant: formatPartnerVariant(partnerList?.partnerVariant),
      };
    }),
  };
};

export const formatFilteredPartners = (
  item: ItemRecord,
  microcopy?: MicrocopyGPT & FilteredPartnersItems,
) => {
  return {
    id: item.sys.id,
    contentType: item.contentType,
    listType: `partner-list--${item.listType}`,
    tileType: item.tileType,
    includedCategories: item.includedCategories ?? [],
    sorting: [
      {
        label: microcopy?.merchantlistfilterable_sort_mostpopular,
        value: SORT_TYPES.ONLINE_POPULARITY,
        isActive: true,
      },
    ],
    loadMore: {
      loadMoreLabel: microcopy?.loadmore_loadmore,
      ofLabel: microcopy?.loadmore_of,
    },
    partners: {
      fromText: microcopy?.merchant_ratefrom,
      rateUpTo: microcopy?.merchant_rateupto,
      speedyAward: microcopy?.speedyaward_title,
      wasRateText: microcopy?.merchant_ratewas,
    },
  };
};
const formatFlexPosition = (item?: string) => {
  switch (item) {
    case FLEX_POSITIONS.LEFT:
    case FLEX_POSITIONS.TOP: {
      return FLEX_POSITIONS.START;
    }
    case FLEX_POSITIONS.MIDDLE: {
      return FLEX_POSITIONS.CENTER;
    }
    case FLEX_POSITIONS.RIGHT:
    case FLEX_POSITIONS.BOTTOM: {
      return FLEX_POSITIONS.END;
    }
    default: {
      return FLEX_POSITIONS.START;
    }
  }
};

export const formatSuperhero = (
  item: SuperheroResponse,
): SuperheroFormatted => ({
  id: item.sys.id,
  contentType: item.contentType,
  titleCopyCtaBlock: item.titleCopyCtaBlock
    ? formatTitleCopyCtaBlock(item.titleCopyCtaBlock)
    : null,
  heroImage: item.heroImage ?? null,
  imageHeight: item?.imageHeight ?? DEFAULT_VCC_IMAGE_HEIGHT,
  imageObjectPosition: item?.imageObjectPosition ?? 'top',
  partner: item.partner ?? null,
  youtubeId: item?.youtubeId ?? null,
  youtubeTitle: item?.youtubeTitle ?? null,
  youtubeAutoPlay: item?.youtubeAutoPlay ?? false,
  youtubeVideoHeight: item?.youtubeVideoHeight ?? null,
  titleCopyCtaBlockJustifyContent: formatFlexPosition(
    item?.titleCopyCtaBlockJustifyContent,
  ),
  titleCopyCtaBlockAlignItems: formatFlexPosition(
    item?.titleCopyCtaBlockAlignItems,
  ),
  titleCopyCtaBlockMaxWidth: item?.titleCopyCtaBlockMaxWidth ?? null,
  titleCopyCtaBlockPaddingTop: item?.titleCopyCtaBlockPaddingTop ?? '0',
  titleCopyCtaBlockPaddingBottom: item?.titleCopyCtaBlockPaddingBottom ?? '0',
  titleCopyCtaBlockPaddingLeft: item?.titleCopyCtaBlockPaddingLeft ?? '0',
  titleCopyCtaBlockPaddingRight: item?.titleCopyCtaBlockPaddingRight ?? '0',
  opacityRgba: item?.opacityRgba ?? 'initial',
});

const formatAccordion = (item: AccordionResponse): AccordionFormatted => {
  return {
    id: item.sys.id,
    contentType: item.contentType,
    title: item.title,
    body: item.body,
    isOpen: item.isOpen ?? false,
  };
};

export const formatCarouselVariant = (variant: string | null) => {
  switch (variant) {
    case 'MultiPartners': {
      return CAROUSEL_VARIANT.MultiPartners;
    }
    case 'TwoAndAHalfItems':
    case 'Two Components': {
      return CAROUSEL_VARIANT.TwoComponents;
    }
    case 'ThreeAndAHalfItems':
    case 'Three Components': {
      return CAROUSEL_VARIANT.ThreeComponents;
    }
    case 'Four Components': {
      return CAROUSEL_VARIANT.FourComponents;
    }
    case 'Multi': {
      return CAROUSEL_VARIANT.Multi;
    }
    case 'Banners': {
      return CAROUSEL_VARIANT.Banners;
    }
    case 'Buttons': {
      return CAROUSEL_VARIANT.Buttons;
    }
    default: {
      return CAROUSEL_VARIANT.MultiPartners;
    }
  }
};

const formatFinancialServicesBanner = (
  item: FinancialServicesBannerResponse,
) => {
  return {
    ...item,
    bannerContent: formatVersatileCard(item.bannerContent),
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const formatButton = (item: ButtonResponse) => {
  let variantType: keyof typeof VARIANT;
  switch (item.variant) {
    case 'Primary': {
      variantType = 'Contained';
      break;
    }
    case 'Secondary': {
      variantType = 'Outlined';
      break;
    }
    case 'Image': {
      variantType = 'Unstyled';
      break;
    }
    default: {
      variantType = item.variant;
      break;
    }
  }
  return {
    ...item,
    id: item?.sys?.id,
    children: item.iconName && !item.alignIcon ? null : item.label,
    variant: VARIANT[variantType] ?? VARIANT.Contained,
    size: SIZE[item?.size || 'Medium'],
    icon:
      item.label && !item.alignIcon && item?.iconName
        ? ICON_VARIANT[item.iconName]
        : null,
    leadingIcon:
      item?.iconName && item.alignIcon === 'start'
        ? ICON_VARIANT[item.iconName]
        : null,
    trailingIcon:
      item?.iconName && item.alignIcon === 'end'
        ? ICON_VARIANT[item.iconName]
        : null,
    topIcon:
      item?.iconName && item.alignIcon === 'top'
        ? ICON_VARIANT[item.iconName]
        : null,
    bottomIcon:
      item?.iconName && item.alignIcon === 'bottom'
        ? ICON_VARIANT[item.iconName]
        : null,
    as: 'link',
    target: item?.openLinksInNewTab ? '_blank' : '_self',
    href: item?.url,
    'aria-label':
      !item.alignIcon && (item?.iconName || item?.image) ? item?.label : null,
    image: item?.image,
  };
};

const formatContentTypes = (
  content: ContentRowResponse | ContainerResponse,
  microcopy?: MicrocopyGPT,
  isFullBleed?: boolean | null,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  if (content.contentType === CONTENT_TYPES.Container) {
    const containerContent = content as ContainerResponse;
    return {
      id: containerContent.sys.id,
      contentType: containerContent.contentType,
      variant: containerContent.variant,
      layout: containerContent.layout,
      alignContent: containerContent.alignContent,
      items: containerContent.componentsCollection.items.map((item: any) => {
        // In the future, convert this if statement into a switch
        if (item?.contentType === CONTENT_TYPES.Card) {
          return formatCard(item, microcopy);
        }

        if (item.contentType === CONTENT_TYPES.TextBlock) {
          return formatTextBlock(item, isFullBleed);
        }
        return null;
      }),
    };
  }

  if (content.contentType === CONTENT_TYPES.ContentRow) {
    return {
      id: content.sys.id,
      contentType: content.contentType,
      isRow: content?.isRow || false,
      alignContent: content?.alignContent ?? 'left',
      isCarousel: content?.isCarousel || false,
      carouselAutoPlay: content?.carouselAutoPlay ?? true,
      carouselVariant: formatCarouselVariant(content?.carouselPresentation),
      contentSeparators: content?.contentSeparators ?? false,
      extraPaddingBottom: content?.extraPaddingBottom ?? 0,
      items: content.itemsCollection.items.map((item: any) => {
        switch (item?.contentType) {
          case CONTENT_TYPES.VersatileCard: {
            return formatVersatileCard(item, microcopy);
          }
          case CONTENT_TYPES.TitleCopyCtaBlock: {
            return formatTitleCopyCtaBlock(item, isFullBleed);
          }
          case CONTENT_TYPES.ArticleStory: {
            return formatArticleStory(item);
          }
          case CONTENT_TYPES.Superhero: {
            return formatSuperhero(item);
          }
          case CONTENT_TYPES.PartnerList: {
            return formatPartnerList(item, microcopy);
          }
          case CONTENT_TYPES.FilteredPartners: {
            return formatFilteredPartners(
              item,
              microcopy as MicrocopyGPT & FilteredPartnersItems,
            );
          }
          case CONTENT_TYPES.Accordion: {
            return formatAccordion(item);
          }
          case CONTENT_TYPES.FinancialServicesBanner: {
            return formatFinancialServicesBanner(item);
          }
          case CONTENT_TYPES.Button: {
            return formatButton(item);
          }
          case CONTENT_TYPES.MemberGetMember: {
            return item;
          }
          case CONTENT_TYPES.Card: {
            return formatCard(item, microcopy);
          }
          default: {
            return [];
          }
        }
      }),
    };
  }
  return null;
};

const formatCard = (item: any, microcopy?: MicrocopyGPT) => ({
  ...item,
  partner: item?.partner?.[0]
    ? [
        {
          ...item.partner[0],
          wasRatePrefix: microcopy?.merchant_ratewas ?? '',
          upToPrefix: microcopy?.merchant_rateupto ?? '',
          fromPrefix: microcopy?.merchant_ratefrom ?? '',
        },
      ]
    : [],
  textBlock: formatTextBlock(item.textBlock),
});

const contentItemsAreNotEmpty = (section: HydratedSectionResponse) =>
  section.contentCollection.items
    .map((content: ContentRowResponse | ContainerResponse) =>
      'itemsCollection' in content
        ? content.itemsCollection.items.length
        : content.componentsCollection.items.length,
    )
    .some(length => length > 0);

const formatSectionData = (
  section: HydratedSectionResponse,
  microcopy?: MicrocopyGPT,
) => {
  if (!contentItemsAreNotEmpty(section)) {
    return null;
  }

  return {
    id: section?.sys?.id,
    contentType: section.contentType,
    ariaLabel: section?.ariaLabel ?? '',
    visibility: section.visibility ?? SECTION_VISIBILITY_FOR_SESSION.Both,
    backgroundColor: section.backgroundColor,
    isFullBleed: section.isFullBleed ?? false,
    paddingTop: section?.paddingTop ?? null,
    paddingBottom: section?.paddingBottom ?? null,
    isLoggedIn: section?.isLoggedIn ?? false,
    financialSectionData:
      section?.financialSectionData ??
      ({
        sectionFinancialCategoryTier: null,
        userFinancialCategoryTier: null,
      } as SectionFormatted['financialSectionData']),
    backgroundImage: section?.backgroundImage
      ? {
          title: section.backgroundImage?.title ?? null,
          url: section.backgroundImage?.url ?? null,
        }
      : null,
    items: section.contentCollection.items.map(
      (content: ContentRowResponse | ContainerResponse) =>
        formatContentTypes(content, microcopy, section.isFullBleed ?? false),
    ),
  };
};

export const formatSections = (
  sections: SectionsResponse,
  microcopy?: MicrocopyGPT,
) => {
  if (!sections?.items?.length) {
    return null;
  }

  return sections.items
    .map((section: HydratedSectionResponse) =>
      formatSectionData(section, microcopy),
    )
    .filter(Boolean);
};

const returnMerchantPromise = async (
  merchantArray: ContentfulMerchant[],
  market: Market,
  b2bToken: B2BToken,
): Promise<HydratedPartner[]> => {
  const merchantPromises = merchantArray.map(
    async (merchant: ContentfulMerchant) => {
      const partner = new Partner(market, b2bToken, merchant.slug);
      await partner.isReady();

      const collectionMethodKeys = Object.keys(partner.collectionMethods);
      return {
        partnerId: partner.mechanicId ?? undefined,
        mechanicId: partner.mechanicId,
        slug: partner.slug,
        destinationUrl: partner.destinationUrl,
        logoSrc: partner.logoSrc,
        heroSrc: merchant?.override?.heroSrc || partner.heroSrc,
        name: partner.name,
        rate: partner.getHighestRateMechanic()?.rate ?? null,
        wasRate: partner.getHighestRateMechanic()?.wasRate ?? null,
        noOfCollectionMethods: collectionMethodKeys.length,
        isSpeedyAwarding:
          partner.getHighestRateMechanic()?.isSpeedyAwarding ?? null,
        variant: null,
        separator: null,
      };
    },
  );
  return Promise.all(merchantPromises);
};

const getFinancialData = async (
  market: Market,
  session: GetCustomSessionResponse,
) => {
  const accessToken = getAccessTokenFromSession({
    audience: AUDIENCES.FS,
    session,
  });
  if (!session?.user || !accessToken) return null;

  return new FSProvider(market).getFinancialServices(
    getMembershipId(session.user),
    accessToken,
  );
};

const getFinancialSectionData = async (
  market: Market,
  session: GetCustomSessionResponse,
  sectionFinancialCategory: string,
  sectionFinancialCategoryTier: string,
): Promise<FinancialSectionData> => {
  if (!sectionFinancialCategory || !sectionFinancialCategoryTier)
    return {
      sectionFinancialCategoryTier: null,
      userFinancialCategoryTier: null,
    };

  const userFsData = await getFinancialData(market, session);

  const userFinancialCategoryTierForSectionCategory = userFsData?.[
    sectionFinancialCategory as keyof GetFinancialServicesResponse
  ] as string;

  return {
    sectionFinancialCategoryTier: sectionFinancialCategoryTier ?? null,
    userFinancialCategoryTier:
      userFinancialCategoryTierForSectionCategory ?? null,
  };
};

export const shouldShowFsBannerForCategory = async (
  market: Market,
  session: GetCustomSessionResponse,
  category: keyof GetFinancialServicesResponse,
  tier: string,
) => {
  const fsResponse = await getFinancialData(market, session);

  return Boolean(fsResponse && fsResponse[category] === tier);
};

export const formatSearchSection = (search: any, microcopy?: MicrocopyGPT) => {
  const {
    textBlock,
    searchButtonLabel,
    image,
    enableSearch,
    backgroundColour,
  } = search;
  const button = textBlock?.buttonsCollection?.items[0];

  return {
    searchButtonLabel: searchButtonLabel ?? '',
    image,
    pillLabel: textBlock?.pillLabel ?? null,
    heading: textBlock?.heading ?? '',
    body: textBlock?.body ?? null,
    button: {
      label: button?.label ?? '',
      url: button?.url ?? '',
      openLinksInNewTab: button?.openLinksInNewTab ?? null,
    },
    enableSearch: enableSearch ?? null,
    backgroundColour: backgroundColour ?? null,
    wasRateLabel: microcopy?.merchant_ratewas ?? null,
    closeButtonLabel: microcopy?.general_closelabel ?? null,
  };
};

export const hydrateSections = async (
  data: any,
  market: Market,
  b2bToken: B2BToken,
  session: Session | null | undefined,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Promise<any> => {
  if (data === null || data === undefined) {
    return data;
  }

  if (Array.isArray(data)) {
    const sections = await Promise.all(
      data.map(item => hydrateSections(item, market, b2bToken, session)),
    );

    const definedSections = sections.flat().filter(Boolean);

    if (sections.length > definedSections.length) {
      metrics.increment(
        FORMATTER_METRICS.UNDEFINED_SECTIONS,
        sections.length - definedSections.length,
      );
    }

    return definedSections;
  }

  if (typeof data === 'object') {
    const newObject = { ...data };

    if (newObject.contentType === 'Section') {
      newObject.isLoggedIn = Boolean(session?.user);

      newObject.financialSectionData = await getFinancialSectionData(
        market,
        session,
        newObject.fsCategory,
        newObject.fsCategoryTier,
      );
    }
    switch (newObject.contentType) {
      case 'DynamicVersatileCard': {
        const dynamicVersatileCard: DynamicVersatileCardContentfulResponse =
          newObject;

        return handleDynamicVersatileCard({
          session,
          b2bToken,
          market,
          dynamicVersatileCard,
        });
      }
      case 'MerchantList': {
        newObject.partners = await returnMerchantPromise(
          newObject.merchants,
          market,
          b2bToken,
        );
        break;
      }

      case 'TitleCopyCtaBlock': {
        if (newObject.merchant) {
          newObject.merchant = await returnMerchantPromise(
            newObject.merchant,
            market,
            b2bToken,
          );
        }
        break;
      }

      case 'ComponentCard':
      case 'VersatileCard': {
        const typedObject: VersatileCardContentfulResponse = newObject;
        if (typedObject.partner) {
          (newObject as HydratedVersatileCard).partner =
            await returnMerchantPromise(typedObject.partner, market, b2bToken);
        }

        break;
      }

      case 'FinancialServicesBanner': {
        const { fsCategory, fsCategoryTier } = newObject;
        newObject.showBanner = await shouldShowFsBannerForCategory(
          market,
          session,
          fsCategory,
          fsCategoryTier,
        );
        break;
      }

      case 'MemberGetMember': {
        const userFsData = await getFinancialData(market, session);

        if (!userFsData?.Next_best_retention_FS1 || userFsData?.hasError)
          return null;

        return {
          ...newObject,
          nextBestAquisition: userFsData?.Next_best_acquisition_FS1 ?? null,
          nextBestRetention: userFsData?.Next_best_retention_FS1 ?? null,
          amexMGM: userFsData?.widget ?? null,
          amexMGMHasError: userFsData?.hasError ?? null,
          terms: {
            termsLabel: data.termsLabel,
            termsContent: data.termsContent ?? null,
            termsExtraContent: data.termsExtraContent ?? null,
          },
        };
      }

      default: {
        // eslint-disable-next-line guard-for-in
        for (const key in newObject) {
          // eslint-disable-next-line no-await-in-loop
          newObject[key] = await hydrateSections(
            newObject[key],
            market,
            b2bToken,
            session,
          );
        }
      }
    }

    return newObject;
  }

  return data;
};
