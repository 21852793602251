import { NextApiRequestInjected, NextApiResponse } from 'next';
import { OPCO_ID_EXTERNAL_SERVICE } from '@/constants';
import { OPCO_ID } from '@/models/market/market.types';
import { getAccessTokenFromSession, getSession } from '../../providers/auth0';
import CollinsonProvider from '../../providers/collinson';
import extractUserData from '../extract-user-data';
import User from '../../models/user';
import { getDaysSince } from '../get-days-since';
import { NOTIFICATIONS } from '../../modules/notifications/notifications.types';
import { getCollinsonUser } from '../../providers/collinson/collinson.utils';

export async function getUserInfoHandler(
  request: NextApiRequestInjected,
  response: NextApiResponse,
) {
  const { market } = request;
  const session = await getSession({
    market,
    requestResponse: [request, response],
  });
  const accessTokenValue = getAccessTokenFromSession({
    session,
  });
  if (!session?.user || !accessTokenValue) return { isLoggedIn: false };

  const userDataObject = extractUserData(session);
  const collinson = new CollinsonProvider(market);
  const collinsonUser = await getCollinsonUser(session, market);
  const { membershipId } = userDataObject;

  const parameters = {
    auth0accessToken: String(accessTokenValue),
    membershipId: String(membershipId),
    programId: OPCO_ID_EXTERNAL_SERVICE[market.opCoId],
  };

  const lastPurchasesPromise = collinsonUser
    ? collinson.getPurchaseHistory(collinsonUser.id, { limit: '1' })
    : Promise.resolve({ data: [] });

  const userBalancePromise = User.getAviosBalance(parameters);

  const [lastPurchases, userBalance] = await Promise.all([
    lastPurchasesPromise,
    userBalancePromise,
  ]);

  const lastPurchaseDate = lastPurchases.data?.[0]?.date ?? '';
  const aviosBalance = userBalance?.accounts?.[0]?.balance?.amount ?? '-';

  // Invalid user
  switch (market.opCoId) {
    case OPCO_ID.BAEC: {
      if (membershipId?.startsWith('OWVE')) {
        response.setHeader(
          'Set-Cookie',
          `${NOTIFICATIONS.ERROR_INVALID_ACCOUNT}=TRUE;`,
        );
        response.redirect('/api/auth/logout');
      }
      break;
    }
    case OPCO_ID.EIC: {
      if (membershipId?.length === 24) {
        response.setHeader(
          'Set-Cookie',
          `${NOTIFICATIONS.ERROR_INVALID_ACCOUNT}=TRUE;`,
        );
        response.redirect('/api/auth/logout');
      }
      break;
    }
    default: {
      break;
    }
  }

  return {
    isLoggedIn: true,
    membershipId,
    balance: aviosBalance,
    daysSinceLastPurchase: getDaysSince(lastPurchaseDate),
  };
}
