import { SharedComponentsProviderTypes } from '@/context/shared-components/shared-components-provider.types';
import { OPCO_ID } from '@/models/market/market.types';
import { Environment } from '@channel/shared-header-footer';
import dynamic from 'next/dynamic';
import React from 'react';

const SharedComponentsProviderWrapper = dynamic(
  async () => {
    const shared = await import('@channel/shared-header-footer');
    return shared.SharedComponentsProvider;
  },
  {
    ssr: false,
  },
);

const SharedComponentsProvider = ({
  cms,
  children,
  opCoId,
}: SharedComponentsProviderTypes) =>
  opCoId === OPCO_ID.AVIOS ? (
    <SharedComponentsProviderWrapper
      cms={{
        accessToken: cms.aviosCMSToken,
        environment: cms.sharedHeaderFooterEnvironment as Environment,
      }}
    >
      {children}
    </SharedComponentsProviderWrapper>
  ) : (
    <div data-testid="data-testid">{children}</div>
  );

export default SharedComponentsProvider;
