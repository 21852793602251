import ContentfulProvider from '../../providers/contentful/contentful.provider';
import { maintenanceContentQuery } from './maintenance-content-page.query';
import { Market } from '../../models/market/market.types';

export const maintenanceContentPageHandler = async (market: Market) => {
  const contentfulResponse = await new ContentfulProvider(
    market,
  ).makeContentfulRequest({
    query: maintenanceContentQuery,
  });
  const {
    data: {
      pageMaintenanceCollection: { items },
    },
  } = contentfulResponse;

  return {
    title: items[0]?.title || null,
    description: items[0]?.description || null,
    pageLogo: items[0]?.pageLogo || null,
    isActive: items.length > 0,
  };
};
