export const collectionMechanicCBRQuery = `query CollectionMechanicQuery($collectionMechanics: [String!], $slugs: [String!], $opCoId: bpchar!, $countryCode: bpchar!) {
  merchant(
    order_by:{country_id: asc},
    where: {
    mechanics: {program: {name: {_eq: $opCoId}}},
    slug: {_in: $slugs},
    _or : [
      {country: {country_code: {_eq: $countryCode}}},
      {_not: {country:{}}}
    ]
  }) {
    slug
    mechanics(where: {mechanic_collection_methods: {collection_method: {name: {_in: $collectionMechanics}}}, program: {name: {_eq: $opCoId}}}) {
      mechanic_id
      mechanic_collection_methods(where: {is_active: {_eq: true}}, order_by: {collection_method_id:asc}) {
        url
        external_reference
        rate
        earn_rate
        earn_amount
        popular_order
        is_speedy_awarding
        purchase_conditions
        additional_purchase_conditions
        collection_method {
          name
		  		collection_method_locales {
            locale {
              name
            }
            cta
          }
        }
        mechanic_collection_method_tiers(order_by: {earn_rate:desc}) {
          tier_id
          name
          rate
        }
        offers(where: {is_active: {_eq: true}}) {
          start_date
          end_date
          rate
          was_rate
          special_offer_description
        }
        awarded_timeline_unit
       	tracked_timeline_unit
       	tracked_timeline_period
       	awarded_timeline_period
      }
    }
  }
}
`;

export const collectionMechanicContentfulQuery = `query CollectionMechanicQuery($countryId: String!, $contentfulOpCoId: String!, $locale: String!, $collectionMechanics: [String!]) {
  collectionMethodCollection(where: {countryId: $countryId, programId: $contentfulOpCoId, collectionMethodId_in: $collectionMechanics}, locale: $locale) {
    items {
      collectionMethodId
      termsAndConditions
      termsAndConditionsTitle
    }
  }
}
`;
