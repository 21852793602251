import { capitalizeFirstLetter } from '../capitalize-first-letter';

export const formatWasRate = (
  wasRate?: string | null,
  textToPrepend?: string | null,
) => {
  if (!wasRate || !textToPrepend) {
    return wasRate;
  }

  const wasRateString = wasRate.toLowerCase().replace(/avios/gi, 'Avios');

  return capitalizeFirstLetter(`${textToPrepend} ${wasRateString}`);
};
