import { NextApiRequestInjected, NextApiResponse } from 'next';
import {
  QrCodeModalProperties,
  QrCodeContentfulResponse,
} from './qr-code-modal.types';
import ContentfulProvider from '../../providers/contentful';
import { qrCodeQuery } from './qr-code-modal.query';
import { formatMicrocopy } from '../../utils/format-microcopy';
import { getAccessTokenFromSession, getSession } from '../../providers/auth0';
import { getMembershipId } from '../../utils/extract-user-data';
import { featureTogglesHandler } from '../../utils/shared-handler/feature-toggle-handler';
import {
  FEATURE_TOGGLE_LIST,
  FeatureTogglesType,
} from '../../context/feature-toggles/feature-toggles.types';
import QRCodeProvider from '../../providers/qrcode/qrcode.provider';
import {
  AUDIENCES,
  GetCustomSessionResponse,
} from '../../providers/auth0/auth0.types';
import logger from '../../utils/logger';

export const isQrCodeEnabled = (
  session: GetCustomSessionResponse,
  featureToggleList: FeatureTogglesType,
) =>
  featureToggleList.includes(FEATURE_TOGGLE_LIST.SHOW_QR_CODE) &&
  Boolean(session?.user && AUDIENCES.ACT in (session.audiences ?? {}));

export const qrCodeModalHandler = async (
  request: NextApiRequestInjected,
  response: NextApiResponse,
): Promise<QrCodeModalProperties | null> => {
  logger.info('Start QR code handler.');
  const { market } = request;

  const featureToggleList = await featureTogglesHandler(market);

  const session = await getSession({
    market,
    requestResponse: [request, response],
  });
  const accessToken = getAccessTokenFromSession({
    audience: AUDIENCES.ACT,
    session,
  });

  if (!session || !accessToken || !isQrCodeEnabled(session, featureToggleList))
    return null;
  const membershipID = getMembershipId(session.user);

  const [contentfulResponse, qrSvgResponse] = await Promise.all([
    new ContentfulProvider(
      market,
    ).makeContentfulRequest<QrCodeContentfulResponse>({
      query: qrCodeQuery,
    }),
    new QRCodeProvider(market).getCode(accessToken, membershipID),
  ]);

  logger.info('Got QR response', {
    hasErrors: qrSvgResponse.hasError,
    dataLength: qrSvgResponse.data?.length,
  });

  if (qrSvgResponse.hasError) {
    logger.error(
      'Could not return QR code props due to errors with QR service.',
    );
  }

  const { programLogoSmallScreen: smallScreenLogo } =
    contentfulResponse.data.headerCollection.items[0];

  const microcopy = formatMicrocopy(
    contentfulResponse.data.microcopyCollection.items,
  );

  return {
    logo: {
      src: smallScreenLogo?.url ?? null,
      alt: smallScreenLogo?.title ?? '',
    },
    qrSvgData: {
      hasErrors: qrSvgResponse.hasError,
      svg: qrSvgResponse.data,
    },
    membershipID,
    microcopy: {
      showQrCodeTitle: microcopy.showqrcode_title ?? '',
      membershipNoLabel: microcopy.myestore_header_membershipnumber ?? '',
      errorTitle: microcopy.qrcode_error_title ?? '',
      errorDetails: microcopy.qrcode_error_details ?? '',
      closeLabel: microcopy.general_closelabel ?? '',
    },
  };
};
