import { AVIOS_PREFIX } from '@/constants';
import isEmpty from '../is-empty';

export const fetcher = async (
  url: string,
  method = 'GET',
  data = {},
  headers = {},
) => {
  if (url.startsWith('/api')) {
    const pathName = new URL(`${window.location.origin}${url}`).pathname;
    const uriWithoutPathName = url.replace(pathName, '');

    // eslint-disable-next-line no-param-reassign
    url = `${AVIOS_PREFIX}${pathName}${
      pathName.endsWith('/') ? '' : '/'
    }${uriWithoutPathName}`;
  }

  const response = await fetch(url, {
    method,
    headers,
    body: isEmpty(data) ? null : JSON.stringify(data),
  });
  return response.json();
};

export default fetcher;
