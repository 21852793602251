import { capitalizeFirstLetter } from '../capitalize-first-letter';

export const formatHigherRate = (
  rate?: string | null,
  textToPrepend?: string | null,
  rateFromText?: string | null,
  noOfCollectionMethods?: number,
) => {
  const hasMultipleRates = noOfCollectionMethods
    ? noOfCollectionMethods > 1
    : false;

  if (!rate || !textToPrepend || !rateFromText) {
    return rate;
  }
  if (!hasMultipleRates) {
    return rate;
  }

  const rateString = rate.toLowerCase();

  if (
    rateString.startsWith(rateFromText.toLowerCase()) ||
    rateString.startsWith(textToPrepend.toLowerCase())
  ) {
    return rate;
  }

  return capitalizeFirstLetter(`${textToPrepend} ${rate}`);
};
