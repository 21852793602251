import styles from './loader.module.scss';
import { LoaderProperties } from './loader.types';

const Loader = ({ className = '', ...properties }: LoaderProperties) => (
  <span
    className={`${styles.loader} ${className}`}
    aria-label="loading content"
    aria-live="polite"
    aria-busy="true"
    role="progressbar"
    {...properties}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="#a9a8a9"
      className={`${styles.loader__svg}`}
    >
      <path
        opacity=".25"
        d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
      />
      <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z" />
    </svg>
  </span>
);

export default Loader;
