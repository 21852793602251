import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import Button from '@/components/inputs/button';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import Icon from '@/components/data-display/icon';
import { VARIANT as IconVariant } from '@/components/data-display/icon/icon.types';
import { DATA_IDS } from '@/types';
import { RETAILER_NOT_FOUND_VALUE } from '@/modules/missing-avios-page/missing-avios-page-module.types';
import ErrorMessage from '@/components/integrated/error-message';
import type { SelectorProperties } from './selector.types';
import { VARIANT } from './selector.types';
import styles from './selector.module.scss';

const Selector = ({
  values,
  onValueChange: onChange = () => null,
  buttonClassName = '',
  listClassName = '',
  listItemClassName = '',
  iconItemClassName = '',
  prefixLabel,
  label,
  variantSelect = VARIANT.Primary,
  showError,
  errorMessage,
  errorLinkLabel,
  errorLinkHref,
  ...properties
}: SelectorProperties) => {
  const reference = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setValue] = useState(
    values.find(({ isDefault }) => !!isDefault)?.value,
  );

  useEffect(() => {
    setValue(values.find(({ isDefault }) => !!isDefault)?.value);
  }, [values]);

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
    setIsOpen(false);
  };

  useClickAway(reference, () => setIsOpen(false));

  const buttonLabel = currentValue
    ? values.find(({ value }) => value === currentValue)?.label
    : properties.placeholder;

  const buttonLabelTitle =
    typeof buttonLabel === 'string'
      ? buttonLabel
      : (buttonLabel as JSX.Element)?.props?.language;

  return (
    <div className={styles.selector} ref={reference}>
      {label && (
        <label htmlFor={properties.id} className={styles.selector__label}>
          {label}
        </label>
      )}
      <Button
        className={classNames(
          styles.selector__button,
          {
            [styles['selector__button-secondary']]:
              variantSelect === VARIANT.Secondary,
            [styles['selector__button-is-open']]:
              variantSelect === VARIANT.Secondary && isOpen,
            [styles['selector__button-secondary--error']]:
              variantSelect === VARIANT.Secondary && showError,
            [styles['selector__button-secondary--placeholder']]:
              variantSelect === VARIANT.Secondary && !currentValue,
          },
          buttonClassName,
        )}
        variant={BUTTON_VARIANT.Contained}
        onClick={() => setIsOpen(!isOpen)}
        title={buttonLabelTitle}
        {...properties}
      >
        {prefixLabel && typeof buttonLabel === 'string'
          ? `${prefixLabel} ${buttonLabel.toLowerCase()}`
          : buttonLabel}

        <Icon
          className={classNames(
            styles['selector__button-icon'],
            iconItemClassName,
          )}
          variant={IconVariant.ChevronDown}
        />
      </Button>

      {isOpen && (
        <ul
          className={classNames(
            styles.selector__list,
            {
              [styles['selector__list-secondary']]:
                variantSelect === VARIANT.Secondary,
            },
            listClassName,
          )}
          data-id={`${properties.dataId}-list`}
        >
          {values.map(({ label: valueLabel, value }) => {
            const labelTitle =
              typeof valueLabel === 'string'
                ? valueLabel
                : (valueLabel as JSX.Element)?.props?.language;

            return (
              <li key={value}>
                <Button
                  className={classNames(
                    styles['selector__list-item'],
                    listItemClassName,
                    {
                      [styles['selector__list-item-secondary']]:
                        variantSelect === VARIANT.Secondary,
                      [styles['selector__list-item--selected']]:
                        value === currentValue,
                      [styles['selector__list-item-secondary--selected']]:
                        value === currentValue &&
                        variantSelect === VARIANT.Secondary,
                    },
                  )}
                  title={labelTitle}
                  onClick={() => handleChange(value)}
                  data-id={`${
                    value === RETAILER_NOT_FOUND_VALUE
                      ? DATA_IDS.RETAILER_NOT_LISTED
                      : `${properties.dataId}-${valueLabel}`
                  }`}
                >
                  {valueLabel}
                </Button>
              </li>
            );
          })}
        </ul>
      )}
      {showError && (
        <ErrorMessage
          showIcon
          errorMessage={errorMessage}
          extra={
            errorLinkHref &&
            errorLinkLabel && (
              <a
                className={styles['selector__error-link']}
                href={errorLinkHref}
                target="_blank"
                rel="noreferrer"
              >
                {errorLinkLabel}
              </a>
            )
          }
        />
      )}
    </div>
  );
};

export default Selector;
