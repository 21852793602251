export enum NOTIFICATIONS {
  ERROR_INVALID_ACCOUNT = 'error.invalid.account',
}

export type NotificationsProperties = {
  closeLabel: string;
  notificationMicrocopy: Array<{
    id: NOTIFICATIONS;
    title: string;
    message: string;
  }>;
};

export type NotificationsResponse = {
  microcopyCollection: {
    items: Array<{
      key: string;
      value: string;
    }>;
  };
};
