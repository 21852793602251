import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import Button from '@/components/inputs/button';
import {
  VARIANT as BUTTON_VARIANT,
  SIZE,
} from '@/components/inputs/button/button.types';
import type { CookieBannerProperties } from './cookie-banner.types';
import styles from './cookie-banner.module.scss';
import { VARIANT as ICON_VARIANT } from '../../components/data-display/icon/icon.types';
import { COOKIES, DATA_IDS } from '../../types';

const CookieBanner = ({
  cookieCopy,
  cookieAcceptLabel,
  cookieCancelLabel,
}: CookieBannerProperties) => {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const theCookieBanner = Cookies.get(COOKIES.AVIOS_SETTINGS) || '';
  const { pathname } = useRouter();

  const handleCookieBanner = (accepted: boolean): void => {
    setShowBanner(!accepted);
    Cookies.set(
      COOKIES.AVIOS_SETTINGS,
      JSON.stringify({
        acceptedCookies: accepted,
      }),
    );
  };

  useEffect(() => {
    if (theCookieBanner) {
      const parsedCookie = JSON.parse(theCookieBanner);
      if (parsedCookie.acceptedCookies) {
        setShowBanner(false);
      }
    } else {
      setShowBanner(true);
    }
  }, [theCookieBanner, pathname]);
  if (!showBanner) return null;
  return (
    <div className={styles['cookie-banner']}>
      <p className={styles['cookie-banner__copy']}>{cookieCopy}</p>
      <div className={styles['cookie-banner__button-group']}>
        <Button
          className={styles['cookie-banner__button-action']}
          size={SIZE.Medium}
          title={cookieAcceptLabel}
          trailingIcon={ICON_VARIANT.Check}
          onClick={() => handleCookieBanner(true)}
          data-id={DATA_IDS.COOKIE_BANNER_ACCEPT}
        >
          {cookieAcceptLabel}
        </Button>
        <Button
          className={styles['cookie-banner__button-action']}
          size={SIZE.Medium}
          title={cookieCancelLabel}
          trailingIcon={ICON_VARIANT.X}
          onClick={() => setShowBanner(false)}
          variant={BUTTON_VARIANT.TextBlock}
          data-id={DATA_IDS.COOKIE_BANNER_REJECT}
        >
          {cookieCancelLabel}
        </Button>
      </div>
    </div>
  );
};

export default CookieBanner;
