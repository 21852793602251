import { FOOTER_COLLECTION } from './footer.types';

export const footerQuery = `query FooterQuery($contentfulOpCoId: String!, $countryId: String!) {
  ${FOOTER_COLLECTION.WithProgramAndCountry}: footerCollection(
    where: {programId: $contentfulOpCoId, countryId: $countryId}
    limit: 1
  ) {
    ...footerCollectionFields
  }
  ${FOOTER_COLLECTION.WithProgram}: footerCollection(
    where: {programId: $contentfulOpCoId, countryId_exists: false}
    limit: 1
  ) {
    ...footerCollectionFields
  }
  ${FOOTER_COLLECTION.Default}: footerCollection(where: {programId_exists: false}, limit: 1) {
   ...footerCollectionFields
  }
  microcopyCollection (where: {OR: [{key: "footer.label.en_gb"}, {key: "footer.label.en_us"}, {key: "footer.label.en_ie"}, {key: "footer.label.es_es"}]}){
    items {
      key
      value
    }
  }
}

fragment footerCollectionFields on FooterCollection {
  items {
    name
    programId
    countryId
    aviosLogo {
      url
      title
    }
    navigationCollection {
      items {
        label
        urlPath
      }
    }
    copyright
    aviosText
    aviosLink
  }
}`;
