import { versatileCardFields } from '../../providers/contentful';

export const downloadRewardsAppQuery = `query DownloadRewardsAppQuery($contentfulOpCoId: String!, $countryId: String!){
  versatileCardCollection(where: { htmlId: "download-rewards-app", countryId: $countryId, programId: $contentfulOpCoId }){
    items {
      ...versatileCardFields
    }
  }
}
${versatileCardFields}`;
