import { Context, createContext, useContext } from 'react';
import type { ClientSideMarket } from './client-side-market.types';

const ClientSideMarketContext = createContext<ClientSideMarket | undefined>(
  undefined,
);

export const useClientSideMarketContext = () => {
  const context = useContext(ClientSideMarketContext);
  if (!context) {
    throw new Error('Client side context used without initialization');
  }

  return context;
};

export default ClientSideMarketContext as Context<ClientSideMarket>;
