export const getDaysSince = (date: string) => {
  const DEFAULT = 365;
  const MILLISECONDS_IN_A_DAY = 86_400_000;
  let daysSince = DEFAULT;

  if (!Number.isNaN(Number(new Date(date)))) {
    daysSince = Math.floor(
      (Date.now() - new Date(date).getTime()) / MILLISECONDS_IN_A_DAY,
    );
  }

  return Math.min(DEFAULT, daysSince);
};
