import Redis from 'ioredis';
import logger, { formatErrorForLogging } from '../../utils/logger';

class RedisProvider {
  private host = process.env.REDIS_HOST;

  private port = Number(process.env.REDIS_PORT) || 6379;

  private authToken = process.env.REDIS_AUTH_TOKEN;

  private tlsEnabled = process.env.REDIS_TLS_ENABLED === 'true';

  private redis: Redis;

  constructor() {
    this.redis = this.createInstance();
    this.setEventListeners();
  }

  private setEventListeners(): void {
    this.redis.on('connect', () => {
      logger.info({
        message: `Redis connection is established. Host: ${this.host}`,
      });
    });

    this.redis.on('ready', () => {
      logger.info('Redis is ready');
    });

    this.redis.on('error', (error: any) => {
      // Disabling the no-console eslint error because wistin logger emits a setIntermediate error when used

      logger.log('Redis error occurred while trying to connect Redis server', {
        error: formatErrorForLogging(error),
      });
      this.redis.disconnect();
    });

    this.redis.on('close', () => {
      logger.info('Redis server connection has closed');
    });

    this.redis.on('end', () => {
      // Disabling the no-console eslint error because wistin logger emits a setIntermediate error when used

      logger.info('Redis connection end - connection is failed to establish');
    });
  }

  private createInstance(): Redis {
    return new Redis({
      host: this.host,
      port: this.port,
      lazyConnect: true,
      enableReadyCheck: true,
      family: 4,
      showFriendlyErrorStack: true,
      maxRetriesPerRequest: 3,
      connectTimeout: 2000,
      reconnectOnError(error: unknown) {
        logger.error({
          message:
            'reconnectOnError - Redis error occurred while trying to connect Redis server',
          error: formatErrorForLogging(error),
        });
        return true;
      },
      ...(this.authToken ? { password: this.authToken } : {}),
      ...(this.tlsEnabled ? { tls: {} } : {}),
    });
  }

  getInstance(): Redis {
    return this.redis;
  }
}

const redisProviderInstance = new RedisProvider();
export default redisProviderInstance;
