import Tippy from '@tippyjs/react/headless';
import { TooltipProperties } from './tooltip.types';
import styles from './tooltip.module.scss';

export const Tooltip = ({
  children,
  title,
  description,
}: TooltipProperties) => {
  return (
    <Tippy
      render={attributes => (
        <div className={styles.tooltip} tabIndex={-1} {...attributes}>
          <div className={styles['tooltip-content']}>
            {title && <p className={styles['tooltip-title']}>{title}</p>}
            {description && (
              <p className={styles['tooltip-description']}>{description}</p>
            )}
          </div>
          <div data-popper-arrow className={styles.arrow} />
        </div>
      )}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
