import { AfterCallbackPageRoute } from '@auth0/nextjs-auth0';
import { Handler } from '@auth0/nextjs-auth0/src/handlers/router-helpers';
import { NextApiRequest, NextApiResponse } from 'next';
import { StatusCodes } from 'http-status-codes';
import { COOKIES } from '@/types';
import { AVIOS_HOME, AVIOS_PREFIX, HEADERS } from '@/constants';
import { isAviosOpco } from '@/utils/opco-utils';
import { AudienceTokenData, GetCustomSessionResponse } from './auth0.types';
import { Market } from '../../models/market/market.types';
import logger, { formatErrorForLogging } from '../../utils/logger';
import {
  audienceUrls,
  audienceScopes,
  LAST_AUDIENCE,
  FIRST_AUDIENCE,
} from './auth0.constants';
import {
  DecodeJwtError,
  attachCollinsonUserData,
  getAudienceUrlFromJwt,
  getFirstMissingAudience,
  getSanitizedReturnTo,
  isSilentLogin,
  getFormattedSilentLoginCookieFlag,
  getFormattedReturnToCookie,
  getFormattedClearedAppSessionCookies,
} from './auth0.utils';
import { getSession } from './auth0.service';
import { getAuth0Server } from './auth0.server-provider';
import { GENERIC_ERROR_CODES } from '../../modules/generic-error-section/generic-error-section.types';
import { getMembershipId } from '../../utils/extract-user-data';
import { getAuth0BaseConfig } from './auth0.config';

const mapErrorCode = (error: unknown) =>
  error instanceof DecodeJwtError
    ? GENERIC_ERROR_CODES.INVALID_SESSION
    : GENERIC_ERROR_CODES.UNKNOWN_ERROR;

const buildAfterCallbackHandler = (
  market: Market,
  auth0Server: ReturnType<typeof getAuth0Server>,
) =>
  (async (request, response, newSession, state) => {
    const { opCoId } = market;
    const { baseURL } = getAuth0BaseConfig(market);
    const returnTo = getSanitizedReturnTo(btoa(state?.returnTo), baseURL);
    const existingSession: GetCustomSessionResponse = await getSession({
      market,
      requestResponse: [request, response],
    });

    const aviosPrefix = isAviosOpco(opCoId) ? AVIOS_PREFIX : '';
    const { accessToken, refreshToken, accessTokenExpiresAt, user } =
      newSession;

    if (existingSession?.user && !existingSession.user.collinson) {
      // eslint-disable-next-line no-param-reassign
      newSession = await attachCollinsonUserData(newSession, market);
    }

    try {
      const newSessionAudience = getAudienceUrlFromJwt(newSession);
      const audiences = {
        ...existingSession?.audiences,
        [newSessionAudience]:
          refreshToken && accessTokenExpiresAt
            ? ({
                accessToken,
                refreshToken,
                expiresAtInMilliseconds: accessTokenExpiresAt * 1000,
              } as AudienceTokenData)
            : accessToken,
      };

      let firstMissingAudience = getFirstMissingAudience(audiences);

      logger.info('Auth0 callback info', {
        userId: getMembershipId(user) ?? 'missing user id',
        newSessionAudience:
          newSessionAudience ?? 'missing new session audience',
        audiences: Object.keys(audiences),
        firstMissingAudience: firstMissingAudience ?? 'no missing audience',
        hasRefreshToken: Boolean(refreshToken),
        hasExpirationDate: Boolean(accessTokenExpiresAt),
      });

      if (!firstMissingAudience && newSessionAudience !== LAST_AUDIENCE) {
        delete audiences[LAST_AUDIENCE];
        firstMissingAudience = LAST_AUDIENCE;
      }

      if (firstMissingAudience)
        response.setHeader(
          'Location',
          `${aviosPrefix}/api/auth/get-next-audience/${
            returnTo ? `?nextAudienceReturnTo=${btoa(returnTo)}` : ''
          }`,
        );
      else
        logger.info('All audiences have been fetched', {
          audiences: Object.keys(audiences),
        });

      return {
        ...newSession,
        audiences,
      };
    } catch (error) {
      logger.error(
        'Auth error: failed to decode session for multi-audience setup.',
        { error: formatErrorForLogging(error) },
      );

      return auth0Server.handleLogout(request, response, {
        returnTo: `${aviosPrefix}/error/?errorCode=${mapErrorCode(error)}`,
      });
    }
  }) as AfterCallbackPageRoute; // TODO: switch to `satisfies` instead of `as` when typescript is upgraded to 4.9+

const handleCallback = (
  market: Market,
  auth0Server: ReturnType<typeof getAuth0Server>,
) =>
  ((request: NextApiRequest, response: NextApiResponse) => {
    const userNotLoggedInError = request.query.error === 'login_required';
    const { opCoId } = market;
    const { baseURL } = getAuth0BaseConfig(market);

    const aviosHome = isAviosOpco(opCoId) ? AVIOS_HOME : '';
    if (isSilentLogin(request) && userNotLoggedInError) {
      const returnTo =
        getSanitizedReturnTo(
          request.cookies[COOKIES.SILENT_LOGIN_RETURN_TO_BASE64],
          baseURL,
        ) ?? `${aviosHome}/`;

      return response
        .setHeader(HEADERS.SET_COOKIE, [
          getFormattedReturnToCookie(btoa(returnTo), false),
          ...getFormattedClearedAppSessionCookies(request),
        ])
        .redirect(returnTo);
    }

    return auth0Server.handleCallback({
      afterCallback: buildAfterCallbackHandler(market, auth0Server),
    })(request, response);
  }) as Handler;

const handleLogout = (auth0Server: ReturnType<typeof getAuth0Server>) =>
  ((request: NextApiRequest, response: NextApiResponse) => {
    response.setHeader(
      HEADERS.SET_COOKIE,
      getFormattedClearedAppSessionCookies(request),
    );

    return auth0Server.handleLogout(request, response);
  }) as Handler;

const buildGetNextAudienceHandler = (
  market: Market,
  auth0Server: ReturnType<typeof getAuth0Server>,
) =>
  (async (request: NextApiRequest, response: NextApiResponse) => {
    const { baseURL } = getAuth0BaseConfig(market);
    const returnTo =
      typeof request.query.nextAudienceReturnTo === 'string'
        ? getSanitizedReturnTo(request.query.nextAudienceReturnTo, baseURL)
        : undefined;

    const { opCoId } = market;

    const aviosPrefix = isAviosOpco(opCoId) ? AVIOS_PREFIX : '';
    const aviosHome = isAviosOpco(opCoId) ? AVIOS_HOME : '';
    const currentSession = await getSession({
      market,
      requestResponse: [request, response],
    });

    if (!currentSession) {
      logger.error(
        'Auth error: attempting to get the next audience without an active session',
      );
      return response
        .setHeader(
          'Location',
          `${aviosPrefix}/error/?errorCode=${GENERIC_ERROR_CODES.NO_ACTIVE_SESSION}`,
        )
        .status(StatusCodes.MOVED_TEMPORARILY)
        .send(null);
    }

    const firstMissingAudience = getFirstMissingAudience(
      currentSession.audiences ?? {},
    );

    if (!firstMissingAudience) {
      const validReturnTo = returnTo ?? `${aviosHome}/`;
      logger.info('All audiences have been fetched', {
        audiences: Object.keys(currentSession.audiences ?? {}),
        validReturnTo,
      });

      return response
        .setHeader(HEADERS.SET_COOKIE, [
          getFormattedSilentLoginCookieFlag(false),
          getFormattedReturnToCookie(validReturnTo, false),
        ])
        .redirect(validReturnTo);
    }

    logger.info('Getting next audience', {
      firstMissingAudience,
      user: currentSession.user.sub ?? 'unknown',
    });

    return auth0Server.handleLogin({
      authorizationParams: {
        audience: audienceUrls[firstMissingAudience],
        scope: audienceScopes[firstMissingAudience],
        prompt: 'none',
      },
      returnTo,
    })(request, response);
  }) as Handler;

const silentLoginHandler =
  (market: Market, auth0Server: ReturnType<typeof getAuth0Server>) =>
  (request: NextApiRequest, response: NextApiResponse) => {
    const { opCoId } = market;
    const { baseURL } = getAuth0BaseConfig(market);

    const aviosHome = isAviosOpco(opCoId) ? AVIOS_HOME : '';
    const validReturnTo =
      (typeof request.query.silentLoginReturnTo === 'string' &&
        getSanitizedReturnTo(request.query.silentLoginReturnTo, baseURL)) ||
      `${aviosHome}/`;

    response.setHeader(HEADERS.SET_COOKIE, [
      getFormattedSilentLoginCookieFlag(true),
      getFormattedReturnToCookie(btoa(validReturnTo), true),
    ]);

    return auth0Server.handleLogin({
      authorizationParams: {
        audience: audienceUrls[FIRST_AUDIENCE],
        scope: audienceScopes[FIRST_AUDIENCE],
        prompt: 'none',
      },
      returnTo: validReturnTo,
    })(request, response);
  };

export const getAuth0Handler = (market: Market) => {
  const auth0Server = getAuth0Server(market);

  return auth0Server.handleAuth({
    logout: handleLogout(auth0Server),
    callback: handleCallback(market, auth0Server),
    'get-next-audience': buildGetNextAudienceHandler(market, auth0Server),
    'silent-login': silentLoginHandler(market, auth0Server),
  });
};
