import { Market } from '../../models/market/market.types';
import getEnvironmentVariable from '../../utils/get-environment-variable';
import { mapMarket } from './recommended-retailers.utils';

const getRecommendedRetailersConfig = (market: Market) => ({
  RECOMMENDED_RETAILERS_ACCESS_TOKEN_URL: getEnvironmentVariable(
    'RECOMMENDED_RETAILERS_ACCESS_TOKEN_URL',
  ),
  RECOMMENDED_RETAILERS_ACCESS_TOKEN_KEY: getEnvironmentVariable(
    'RECOMMENDED_RETAILERS_ACCESS_TOKEN_KEY',
  ),
  RECOMMENDED_RETAILERS_BASE_URL: getEnvironmentVariable(
    'RECOMMENDED_RETAILERS_BASE_URL',
  ),
  RECOMMENDED_RETAILERS_API_KEY: getEnvironmentVariable(
    'RECOMMENDED_RETAILERS_API_KEY',
  ),
  programmeInfo: mapMarket(market),
  ENV: getEnvironmentVariable('ENV'),
});

export default getRecommendedRetailersConfig;
