import ContentfulProvider from '../../providers/contentful/contentful.provider';
import { downloadAppPromptQuery } from './download-app-prompt.query';
import {
  DownloadAppPromptProperties,
  DownloadAppPromptResponse,
} from './download-app-prompt.types';
import { Market } from '../../models/market/market.types';

export const downloadAppPromptHandler = async (
  market: Market,
): Promise<DownloadAppPromptProperties | null> => {
  const contentfulResponse = await new ContentfulProvider(
    market,
  ).makeContentfulRequest<DownloadAppPromptResponse>({
    query: downloadAppPromptQuery,
  });

  const result =
    contentfulResponse.data?.downloadAppPromptCollection?.items[0] ?? {};

  return {
    title: result.title ?? null,
    description: result.description ?? null,
    appStoreLink: result.appStoreLink ?? null,
    googlePlayLink: result.googlePlayLink ?? null,
    primaryCtaLabel: result.primaryCtaLabel ?? null,
    secondaryCtaLabel: result.secondaryCtaLabel ?? null,
    iconUrl: result.appIcon?.url ?? null,
    iconAlt: result.appIcon?.title ?? null,
  };
};
