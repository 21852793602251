import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { isAndroid, isIOS } from 'react-device-detect';
import Modal, { toggleModal, closeModal } from '@/components/utilities/modal';
import VersatileCard from '@/components/integrated/versatile-card/versatile-card';
import { VersatileCardProperties } from '@/components/integrated/versatile-card/versatile-card.types';
import { COOKIES } from '@/types';

const DownloadRewardsAppModal = ({
  ...properties
}: VersatileCardProperties) => {
  let VCProperties = { ...properties };
  const updateModalCookie = () => {
    Cookies.set(COOKIES.DOWNLOAD_REWARDS_APP, 'modalUsed');
    closeModal(properties.id);
  };

  const modalCookie = Cookies.get(COOKIES.DOWNLOAD_REWARDS_APP) ?? '';

  useEffect(() => {
    if (!modalCookie) {
      toggleModal(properties.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAndroid) {
    VCProperties = {
      ...VCProperties,
      ctas: {
        ...VCProperties.ctas,
        imageButtonSecondary: undefined,
      },
    };
  } else if (isIOS) {
    VCProperties = {
      ...VCProperties,
      ctas: {
        ...VCProperties.ctas,
        imageButtonPrimary: undefined,
      },
    };
  }

  return (
    <Modal label={properties.id} onCloseFunction={updateModalCookie}>
      <VersatileCard
        {...VCProperties}
        ctas={{
          ...VCProperties.ctas,
          imageButtonPrimary: {
            url: VCProperties.ctas?.imageButtonPrimary?.url ?? '',
            src: VCProperties.ctas?.imageButtonPrimary?.src ?? '',
            alt: VCProperties.ctas?.imageButtonPrimary?.alt ?? '',
            width: VCProperties.ctas?.imageButtonPrimary?.width,
            height: VCProperties.ctas?.imageButtonPrimary?.height,
            handleOnClick: updateModalCookie,
          },
          imageButtonSecondary: {
            url: VCProperties.ctas?.imageButtonSecondary?.url ?? '',
            src: VCProperties.ctas?.imageButtonSecondary?.src ?? '',
            alt: VCProperties.ctas?.imageButtonSecondary?.alt ?? '',
            width: VCProperties.ctas?.imageButtonSecondary?.width,
            height: VCProperties.ctas?.imageButtonSecondary?.height,
            handleOnClick: updateModalCookie,
          },
        }}
      />
    </Modal>
  );
};

export default DownloadRewardsAppModal;
