import { CustomLink as Link } from '@/components/custom-link';
import Icon from '@/components/data-display/icon';
import trackEvent from '@/utils/track-event/track-event';
import { EVENT_NAME, NAV_ELEMENT } from '@/utils/track-event/track-event.types';
import type {
  NavLinkProperties,
  NavProperties,
  NavScrollableProperties,
  NavStaticProperties,
} from './nav.types';
import styles from './nav.module.scss';

export const Nav = ({ children, className = '' }: NavProperties) => (
  <nav className={`${styles.nav} ${className}`}>{children}</nav>
);

const NavLink = ({
  destination,
  children,
  icon,
  isActive = false,
  isHighlighted = false,
  title,
}: NavLinkProperties) => {
  return (
    <Link
      onClick={() =>
        trackEvent({
          event: EVENT_NAME.NAVIGATION,
          nav_element: NAV_ELEMENT.MAIN_NAV,
        })
      }
      title={title}
      aria-label={icon ? title : undefined}
      href={destination}
      className={`${styles.nav__link} ${
        isActive ? styles['nav__link--active'] : ''
      } ${isHighlighted ? styles['nav__link--highlighted'] : ''}`}
    >
      <>
        {children}

        {icon && (
          <Icon
            className={styles['nav__link-icon']}
            variant={icon}
            data-id={icon}
          />
        )}
      </>
    </Link>
  );
};

const NavScrollable = ({ children }: NavScrollableProperties) => (
  <div className={styles.nav__scrollable}>{children}</div>
);

const NavStatic = ({ children }: NavStaticProperties) => (
  <div className={styles.nav__static}>{children}</div>
);

Nav.Link = NavLink;
Nav.Scrollable = NavScrollable;
Nav.Static = NavStatic;

export default Nav;
