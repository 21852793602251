import {
  FinancialServicesWidgetResponse,
  FormattedAmexMGMWidget,
} from './financial-services.types';

export const formatAmexMGM = (
  data?: FinancialServicesWidgetResponse,
): FormattedAmexMGMWidget => {
  if (!data) return null;

  const [
    {
      header_content,
      short_url,
      mgmer_offer_content,
      mgmee_offer_content,
      terms_and_conditions,
    },
  ] = data.external_rafaas_responses;

  return {
    title: mgmer_offer_content.extra_copy,
    toggleText: 'Refer now',
    closeLabel: 'Close',
    card: {
      cardImage: header_content.card_art_url,
    },
    offers: [
      {
        title: "You'll collect",
        value: mgmer_offer_content.bonus_amount,
        extraDescription: mgmer_offer_content.addnl_offer_copy,
        description: mgmer_offer_content.extra_copy,
      },
      {
        title: mgmee_offer_content.offer_copy,
        value: mgmee_offer_content.bonus_amount,
        description: mgmee_offer_content.addnl_offer_copy,
      },
    ],
    share: {
      title:
        'Invite a friend and collect bonus Avios if your friend is approved',
      referralLink: `${short_url}`,
      copyText: 'Copy link',
      copiedText: 'Copied',
      copiedDialogText: 'Share your referral link with friends and family!',
    },
    terms: {
      termsCta: terms_and_conditions.terms_and_condition_link,
      termsLabel: 'Terms & Conditions apply.',
      termsContent: 'Use your unique referral link now.',
      termsExtraContent:
        'This offer cannot be combined with the limited time promotion for new Cardmembers which is currently available to Executive Club Members through other British Airways channels.',
    },
  };
};
