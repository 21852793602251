import { createLogger, format, transports } from 'winston';
import { APPLICATION_ENVIRONMENT } from '../../middleware/application-environment/application-environment.types';
import { FormattedError } from './logger.types';

const logger = createLogger({
  level: 'info',
  format: format.combine(
    format.timestamp({
      format: 'YYYY-MM-DD HH:mm:ss',
    }),
    format.errors({ stack: true }),
    format.splat(),
    format.json(),
  ),
  defaultMeta: { service: process.env.DD_SERVICE },
  exceptionHandlers: [new transports.Console()],
});

export const formatErrorForLogging = <T>(error: T) =>
  (error instanceof Error
    ? {
        ...error,
        message: error.message ?? 'unknown error message',
        name: error.name ?? 'unknown error name',
        stack: error.stack ?? 'missing stack',
      }
    : error) as T extends Error ? FormattedError : T;

if (process.env.NODE_ENV === 'production') {
  logger.add(new transports.Console());
} else {
  const environment = process.env.ENV || 'local';
  logger.add(
    new transports.Console({
      format: format.combine(format.colorize({ all: true }), format.simple()),
      silent: ['test', 'local'].includes(environment),
    }),
  );
}

if (
  // winston is not available for the browser
  //  https://github.com/winstonjs/winston/issues/287
  // directly checking for `window` causes the build step to fail,
  //  hence why we are using `globalThis` here
  !('window' in globalThis) &&
  (process.env.NODE_ENV as APPLICATION_ENVIRONMENT) ===
    APPLICATION_ENVIRONMENT.Production
) {
  // We are overwriting console.error because nextjs uses it
  //  to log errors that it has captured on its side.
  // If nextjs uses the native logger, logs with multiple lines will
  //  be split into one log entry per line in datadog.
  // Overwriting console.error will make it so that errors logged
  //  by nextjs will appear properly formatted with our own custom
  //  logger
  // eslint-disable-next-line no-console
  console.error = logger.error.bind(logger);
}

export default logger;
