import { ErrorResponse } from '../../utils/fetch/fetch.types';

export type GetAcessTokenResponseType = {
  access_token: string;
  token_type: string;
  expires_in: number;
  jti: string;
};

export type RecommendedRetailersResponseType = {
  MembershipId: string;
  Schema: string;
  CountryCode: string;
  merchants: string[];
};

export type RelatedRetailersResponseType = {
  merchantId: string;
  countryCode: string;
  merchants: number[];
};

export type RecommendedRetailersResponse = RecommendedRetailersResponseType &
  ErrorResponse;

export type RelatedRetailersResponse = RelatedRetailersResponseType &
  ErrorResponse;

export enum MAPPED_OPCO {
  BAEC = 'BAEC',
  VUEL = 'VUEL',
  IBPLU = 'IBPLU',
  EIAC = 'EIAC',
  ATRP = 'ATRP',
}

export enum MAPPED_COUNTRY {
  GB = 'GB',
  US = 'US',
  IE = 'IE',
  ES = 'ES',
  FR = 'FR',
  IT = 'IT',
}
