import CBRProvider from '../../providers/cbr';
import { B2BToken } from '../../types';
import MarketDataLoader from '../../utils/market-data-loader';
import { Market } from '../market/market.types';
import { collectionMechanicCBRQuery } from './collection-mechanic.query';
import {
  CBRCollectionMechanic,
  CBRCollectionMechanicResponse,
  COLLECTION_MECHANIC,
} from './collection-mechanic.types';

type CollectionMechanicCBRLoaderKey = {
  slug: string;
  collectionMechanic: COLLECTION_MECHANIC;
};

const fetchData = (
  market: Market,
  b2bToken: B2BToken,
  keys: readonly CollectionMechanicCBRLoaderKey[],
) =>
  new CBRProvider(
    market,
    b2bToken,
  ).makeCBRRequest<CBRCollectionMechanicResponse>({
    query: collectionMechanicCBRQuery,
    variables: {
      slugs: keys.map(({ slug }) => slug),
      collectionMechanics: keys.map(
        ({ collectionMechanic }) => collectionMechanic,
      ),
    },
  });

const mapResponse = (
  keys: readonly CollectionMechanicCBRLoaderKey[],
  data: CBRCollectionMechanicResponse,
) => {
  return keys.map(({ slug, collectionMechanic }) =>
    Object.freeze(
      data.merchant
        .find(merchant => merchant.slug === slug)
        ?.mechanics[0].mechanic_collection_methods.find(
          ({ collection_method: { name } }) => name === collectionMechanic,
        ),
    ),
  );
};

const batcher =
  (market: Market, b2bToken: B2BToken) =>
  async (keys: readonly CollectionMechanicCBRLoaderKey[]) => {
    const response = await fetchData(market, b2bToken, keys);

    return mapResponse(keys, response.data);
  };

class CollectionMechanicCbrLoaderSingleton extends MarketDataLoader<
  CBRCollectionMechanic,
  CollectionMechanicCBRLoaderKey
> {
  private static instance: CollectionMechanicCbrLoaderSingleton =
    new CollectionMechanicCbrLoaderSingleton();

  constructor() {
    if (CollectionMechanicCbrLoaderSingleton.instance) {
      throw new Error(
        'Error: Instantiation failed: Use CollectionMechanicCbrLoaderSingleton.getInstance() instead of new.',
      );
    }

    super(batcher);

    CollectionMechanicCbrLoaderSingleton.instance = this;
  }

  public static getInstance() {
    return CollectionMechanicCbrLoaderSingleton.instance;
  }
}

const CollectionMechanicCbrLoader =
  CollectionMechanicCbrLoaderSingleton.getInstance();

export default CollectionMechanicCbrLoader;
