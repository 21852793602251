export type CBRCollectionMechanic = Readonly<{
  external_reference: string;
  url: string | null;
  rate: string;
  is_speedy_awarding: boolean;
  purchase_conditions?: string;
  additional_purchase_conditions?: string;
  earn_rate?: number;
  earn_amount?: number;
  popular_order?: number;
  collection_method_locale_overrides: Array<{
    cta: string;
  }>;
  collection_method: {
    name: COLLECTION_MECHANIC;
    collection_method_locales: Array<{
      locale: {
        name: string;
      };
      cta: string;
    }>;
  };
  mechanic_collection_method_tiers: Array<{
    tier_id: number;
    name: string;
    rate: string;
  }>;
  offers: Array<{
    start_date: string;
    end_date: string;
    rate: string;
    was_rate: string;
    special_offer_description: string;
  }>;
  awarded_timeline_unit: string;
  tracked_timeline_unit: string;
  tracked_timeline_period: number;
  awarded_timeline_period: number;
}>;

export type CBRCollectionMechanicResponse = {
  merchant: Array<{
    slug: string;
    mechanics: Array<{ mechanic_collection_methods: CBRCollectionMechanic[] }>;
  }>;
};

export type ContentfulTAndCsResponse = {
  collectionMethodCollection: {
    items: ContentfulTAndC[];
  };
};

export type ContentfulTAndC = {
  collectionMethodId?: string;
  termsAndConditions?: string;
  termsAndConditionsTitle?: string;
};

export type CollectionMechanic = {
  key: COLLECTION_MECHANIC;
  externalReference: string;
  rate: string | null;
  wasRate: string | null;
  earnValue: number | undefined;
  popularOrder?: number;
  isSpeedyAwarding: boolean;
  isSpecialOffer: boolean;
  purchaseConditions?: string;
  additionalPurchaseConditions?: string;
  url: string;
  ctaText: string;
  tiers: Tier[];
  termsAndConditions?: string;
  termsAndConditionsTitle?: string;
  ctaLink: {
    url: string;
    target: string;
  };
  trackedTimelineUnit: string;
  trackedTimelinePeriod: number;
  awardedTimelineUnit: string;
  awardedTimelinePeriod: number;
};

export enum COLLECTION_MECHANIC {
  EStore = 'eStore',
  Manual = 'Manual',
  CLO = 'CLO',
  Convert = 'Convert',
  Flying = 'Flying',
  Cobrand = 'Cobrand',
  QR = 'QR',
  Purchase = 'Purchase',
  Voucher = 'Voucher',
}

export type Tier = {
  id: number;
  name: string;
  rate: string;
};

export type Offer = {
  rate: string;
  wasRate: string;
};

export type ExternalReferences = {
  [key in COLLECTION_MECHANIC]: string;
};
