export function getHeightClassName(videoHeight: string | null) {
  let className = '';
  switch (videoHeight) {
    case '300px': {
      className = 'iframe-body__300h';
      break;
    }
    case '400px': {
      className = 'iframe-body__400h';
      break;
    }
    case '500px': {
      className = 'iframe-body__500h';
      break;
    }
    case '600px': {
      className = 'iframe-body__600h';
      break;
    }
    default: {
      break;
    }
  }
  return className;
}
