import { versatileCardFields } from '../../providers/contentful';

export const promotionalModalQuery = `query PromotionalModalQuery($contentfulOpCoId: String!, $countryId: String!, $locale: String){
    promotionalModalCollection(limit: 1 where: {countryId: $countryId, programId: $contentfulOpCoId}) {
      items {
        modalContent {
            ...versatileCardFields
        }
        cookieValue
        showForAllDevices
        showForAndroid
        showForIphone
        showDelay
      }
    }
    microcopyCollection(locale: $locale, where: {
      key_in: [ "general.closeLabel" ]
      }) {
      items {
        key
        value
      }
    }
}
${versatileCardFields}`;
