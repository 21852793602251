export const searchBarQuery = `query SearchBarQuery($locale: String, $contentfulOpCoId: String, $countryId: String) {
  headerCollection(where: {programId: $contentfulOpCoId, countryId: $countryId}) {
    items {
      searchPlaceholderText
    }
  }
  microcopyCollection(locale: $locale, where: {
    key_in: [
      "general.closeLabel"
      "search.toggleSearch"
      "search.label"
    ]
  }) {
    items {
      key
      value
    }
  }
}`;
