import getEnvironmentVariable from '../../utils/get-environment-variable';
import { Config } from './cbr.types';

const getCBRConfig = (): Config => ({
  API_URL: getEnvironmentVariable('CBR_GRAPHQL_URL'),
  PROGRAM: 'ATRP',
  API_KEY: getEnvironmentVariable('CBR_API_KEY'),
});

export default getCBRConfig;
