import { OPCO_ID } from '../../models/market/market.types';
import Fetch from '../../utils/fetch';
import getGlpConfig from './glp.config';
import {
  Config,
  AccountDetailsResponse,
  PROGRAMME_IDENTIFIER_GLP,
} from './glp.types';

export default class GlpProvider extends Fetch {
  private config: Config;

  constructor() {
    const selectedConfig = getGlpConfig();

    super(selectedConfig.BASE_URL);

    this.config = selectedConfig;

    this.setHeaders();
  }

  private setHeaders() {
    this.headers = {
      api_key: this.config.API_KEY,
      'Content-Type': 'application/json',
    };
  }

  async getAccountDetails(
    auth0accessToken: string,
    membershipId: string,
    programId: OPCO_ID,
  ) {
    const programmeIdentifier = PROGRAMME_IDENTIFIER_GLP[programId];
    const accountType = [OPCO_ID.IBP, OPCO_ID.VYC].includes(programId)
      ? 'TOTAL_REWARD'
      : 'REWARD';

    return this.get<AccountDetailsResponse>(
      `/members/${membershipId}/programmes/${programmeIdentifier}/schemes/accounts?accountType=${accountType}`,
      { headers: { Authorization: auth0accessToken }, cache: 'no-store' },
    );
  }
}
