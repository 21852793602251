import { useClientSideMarketContext } from '@/context/client-side-market/market-context';
import trackEvent from '@/utils/track-event';
import { EVENT_NAME } from '@/utils/track-event/track-event.types';
import { AVIOS_HOME, AVIOS_PREFIX } from '@/constants';
import { isAviosOpco } from '@/utils/opco-utils';

const useBasePath = () => {
  const { opCoId, locale } = useClientSideMarketContext();
  const isAvios = isAviosOpco(opCoId);

  let basePath = '';
  let pathPrefix = '';
  let homePath = `/${locale}`;
  if (isAvios) {
    basePath = `/${locale}${AVIOS_PREFIX}`;
    pathPrefix = AVIOS_PREFIX;
    homePath = `${homePath}${AVIOS_HOME}`;
  }

  const setLocale = (
    localeToUse: string,
    localeChangeMetric = EVENT_NAME.ISO_UPDATE,
  ) => {
    let href = `/${localeToUse}/`;

    trackEvent({
      event: localeChangeMetric,
    });

    if (isAvios) {
      href = `/${locale}${AVIOS_HOME}/`;
    }

    window.location.href = href;
  };

  return { basePath, pathPrefix, homePath, setLocale };
};

export default useBasePath;
