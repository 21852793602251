export enum EVENT_NAME {
  ONLINE_SHOP_NOW = 'online_shopnow',
  CARD_LINKED = 'card_registration',
  PARTNER_TILE_CLICK = 'MerchantTileClick',
  MERCHANT_FILTER = 'merchant_filter',
  MGM_WIDGET = 'mgm_widget',
  SITE_SEARCH = 'site_search',
  MERCHANT_SORT = 'merchant_sort',
  ONLINE_LOGINBUTTON = 'online_loginbutton',
  ISO_UPDATE = 'iso_update',
  NAVIGATION = 'navigation',
  MERCHANT_RESET = 'merchant_reset',
  SETUP_LOCALE = 'setup_locale',
  PROMOTIONAL_MODAL_DISPLAY = 'promotional_modal_display',
  PROMOTIONAL_MODAL_FOLLOW = 'promotional_modal_follow',
  PROMOTIONAL_MODAL_CLOSE = 'promotional_modal_close',
  PROMOTIONAL_MODAL_UNKNOWN = 'promotional_modal_unknown',
  CTA_GLOBAL = 'ctaGlobal',
}

export enum PROMOTIONAL_MODAL_CLOSE_VARIANT {
  OUTSIDE = 'outside',
  BUTTON = 'button',
}

export type EventObjectProperties = {
  event?: EVENT_NAME;
  custom_timestamp?: number;
} & { [key: string]: string | number | undefined | boolean | null };

export enum USER_ACTION {
  LOG_IN = 'log in',
  LOG_OUT = 'log out',
  RETAILER_LOG_IN = 'retailer log in',
  MGM_LINK_COPY = 'link copy',
  MGM_WIDGET_CLOSE = 'widget_close',
  MGM_WIDGET_OPEN = 'widget_open',
  MGM_TERMS_CLICK = 'terms_click',
  MGM_WHATSAPP_CLICK = 'whatsapp_click',
  MGM_TOGGLE_BUTTON = 'toggle_button',
  MGM_X_BUTTON = 'x_button',
  ADD_NEW_CARD = 'add new card',
  RETAILER_REGISTER_CARD = 'retailer register a card',
  LINK_CARD = 'link card',
  REMOVE_CARD_1 = 'remove card step 1',
  REMOVE_CARD_2 = 'remove card step 2',
}

export enum NAV_ELEMENT {
  MAIN_NAV = 'main navigation',
  FOOTER_NAV = 'footer navigation',
  FOOTER_LINK = 'footer link',
  VISIT_HISTORY = 'visit history view all',
  PURCHASE_HISTORY = 'purchase history view all',
  MERCHANT_NUTSHELL_ACCORDION = 'merchant nutshell accordion',
  MERCHANT_DESCRIPTION_ACCORDION = 'merchant description accordion',
  TERMS_LINK = 'terms link',
  BACK_TO_MY_ACCOUNT = 'back to my account',
  CTA = 'cta',
}
