import ContentfulProvider from '@/providers/contentful/contentful.provider';
import { Market } from '@/models/market/market.types';
import { VersatileCardProperties } from '@/components/integrated/versatile-card/versatile-card.types';
import { formatVersatileCard } from '@/utils/contentful-data-formatters';
import { formatMicrocopy } from '@/utils/format-microcopy';
import { promotionalModalQuery } from './promotional-modal.query';
import {
  PromotionalModalProperties,
  PromotionalModalResponse,
} from './promotional-modal.types';

export const promotionalModalHandler = async (
  market: Market,
): Promise<PromotionalModalProperties | null> => {
  const contentfulResponse = await new ContentfulProvider(
    market,
  ).makeContentfulRequest<PromotionalModalResponse>({
    query: promotionalModalQuery,
  });

  const { items: promotionalModals } =
    contentfulResponse.data.promotionalModalCollection;

  const {
    modalContent,
    cookieValue,
    showForAllDevices,
    showForAndroid,
    showForIphone,
    showDelay,
  } = promotionalModals[0] ?? {};

  const modalContentFormatted = formatVersatileCard(modalContent);

  const microcopy = formatMicrocopy(
    contentfulResponse.data.microcopyCollection.items,
  );

  return {
    id: 'promotional-modal',
    cookie: cookieValue,
    versatileCardContent: modalContentFormatted as VersatileCardProperties,
    showDelay,
    showForAllDevices,
    showForAndroid,
    showForIphone,
    closeLabel: microcopy.general_closelabel ?? '',
  };
};
