import { FeatureTogglesType } from '../../context/feature-toggles/feature-toggles.types';
import Market from '../../models/market';
import ContentfulProvider from '../../providers/contentful';
import { featureTogglesContentfulQuery } from '../../pages/_app/_app.query';

export async function featureTogglesHandler(
  market: Market,
): Promise<FeatureTogglesType> {
  const contentfulRequest = new ContentfulProvider(
    market,
  ).makeContentfulRequest({
    query: featureTogglesContentfulQuery,
  });

  const contentfulResponse = await contentfulRequest;

  const {
    featureTogglesCollection: {
      items: [
        {
          featuresCollection: { items },
        },
      ],
    },
  } = contentfulResponse.data;

  return items?.map((feature: { id: string }) => feature.id);
}
