import { NOTIFICATIONS } from './notifications.types';

const outputString = Object.values(NOTIFICATIONS)
  .map(value => `"${value}.title", "${value}.message"`)
  .join(', ');

export const notificationsQuery = `query NotificationsQuery($locale: String!) {
    microcopyCollection(locale: $locale, where: {
      key_in: [
		"general.closeLabel",
        ${outputString}
      ]
    }) {
      items {
        key
        value
      }
    }
  }`;
