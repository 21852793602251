import { LOCALE_ID, PROGRAM_ID } from '../../models/market/market.types';
import { CardResponse } from '../../providers/fidel/fidel.types';

export type MissingAviosPageModuleProperties = {
  pageTitle: string;
  title: string;
  instructions: MissingAviosInstructionsFields;
  formLabels: MissingAviosSubmitFormLabels;
  formData?: MissingAviosSubmitFormData;
  userRegisteredCards?: CardResponse[];
  programID: PROGRAM_ID;
  defaultLocale: LOCALE_ID;
  noAutoClaimMerchants: TrimmedMerchant[];
  statusKeys: { [key: string]: string };
};

export type TrimmedMerchant = Pick<NoAutoClaimMerchant, 'id' | 'name'>;

export type NoAutoClaimMerchant = {
  app_awarding: string;
  banner_extra_large: string;
  banner_large: string;
  category: string;
  category_id: string;
  closure_scheduled: string;
  country_id: string;
  currency_code: string;
  currency_symbol: string;
  description: string;
  earning_type: string;
  flagged: string;
  id: string;
  keywords: string;
  logo: string;
  messaging: string;
  name: string;
  no_auto_claims: string;
  purchase_timeline_stats: {
    awarded: { unit: string; time: number };
    tracked: { unit: string; time: number };
  };
  rate: string;
  rate_fixed: string;
  rate_numeric: string;
  special_offer: string;
  speedy_rewarding: string;
  terms: string;
};

export type MissingAviosSubmitFormData = {
  'transaction-amount'?: string;
  'retailer-name'?: string;
  'retailer-order'?: string;
  'transaction-date'?: string;
  'store-location'?: string;
  'card-used'?: string;
  'issue-description'?: string;
  files?: FileList | null;
};

export enum ISSUE_TYPE {
  Online = 'online',
  Instore = 'instore',
  Other = 'other',
}

export type MissingAviosSubmitFormLabels = {
  backLabel: string;
  requiredLabel: string;
  retailersNameLabel: string;
  retailersNamePlaceholder?: string;
  retailersOrderLabel: string;
  retailersOrderPlaceholder?: string;
  transactionDateLabel: string;
  transactionDateErrorMessage: string;
  transactionAmountLabel: string;
  transactionAmountPlaceholder?: string;
  invalidRetailerErrorMessage?: string;
  issueDescriptionLabel: string;
  issueDescriptionPlaceholder?: string;
  filesInfoLabel: string;
  removeFileLabel: string;
  submitButtonLabel: string;
  uploadDocumentLabel: string;
  selectDateLabel: string;
  storeLocationInputTitle: string;
  instoreRetailerNameInputTitle: string;
  instoreRetailerNameInputLabel: string;
  cardUsedInputTitle: string;
  cardUsedInputLabel: string;
  cardLastNumbersPrefix: string;
  instoreReceiptLabel: string;
  successTitle: string;
  backToHomepage: string;
  retailerLabel: string;
  dateIsntListed: string;
  myRetailerIsntListed: string;
  retailerNotValidMessage: string;
  findOutMoreLabel: string;
  myRetailerIsntListedError: string;
  claimUnsuccessfulTitle: string;
  claimUnsuccessfulRetailerDescription: string;
  claimUnsuccessfulDateDescription: string;
  submitScreenText: string;
  goToMyAccountLabel: string;
  uploadFilesText: string;
  maxFilesReached: string;
  clickToAddFile: string;
  uploadLabel: string;
  fileTooLargeErrorMessage: string;
  formSubmitError: string;
  requiredFieldError: string;
  retailerOrderError: string;
  tryAgain: string;
};

export interface MissingAviosClaimReasonOption {
  id: string;
  label: string;
  isAutoClaimEnabled: boolean;
  showVisitHistory: boolean;
  isAviosDeclinedOption: boolean;
}

export type MissingAviosInstructionsFields = {
  formOptionsHeading: string;
  claimReasonHeading: string;
  selectOptionOnline: string;
  selectOptionInStore: string;
  conditionsText: string;
  conditionsConfirmText: string;
  conditionsErrorText: string;
  retailerDetailsText: string;
  goBackLabel: string;
  goToMyAccountLabel: string;
  nextRetailerDetailsLabel: string;
  issueErrorMessage: string;
  optionErrorMessage: string;
  claimReasonOptions: MissingAviosClaimReasonOption[];
};

export const RETAILER_NOT_FOUND_VALUE = '-1';
export const DATE_NOT_FOUND_VALUE = '-1';

export type PageMissingMissingAviosQueryResponse = {
  pageMissingAviosV3Collection: {
    items: PageMissingAviosContentType[];
  };
  microcopyCollection: {
    items: Array<{
      key: string;
      value: string;
    }>;
  };
};

export type PageMissingAviosContentType = {
  pageTitle: string;
  conditionsText: string | null;
  conditionsConfirmText: string | null;
  conditionsErrorText: string | null;
  name: string;
  claimReasonOptions: string[] | null;
  formOptionsHeading: string;
  formOptionOnline: string;
  formOptionInStore: string;
  successTitle: string;
  successMainText: string;
  backToHomepage: string;
  submitFormGenericErrorMessage: string;
  fileTooLargeErrorMessage: string;
  dateErrorMessage: string;
  retailerNameInputTitle: string;
  storeLocationInputTitle: string;
  instoreRetailerNameInputTitle: string;
  instoreRetailerNameInputLabel: string | null;
  cardUsedInputTitle: string;
  cardUsedInputLabel: string;
  cardLastNumbersPrefix: string;
  transactionDateInputTitle: string;
  transactionAmountInputTitle: string;
  transactionAmountPlaceholder: string;
  retailerReferenceInputTitle: string;
  fileTypesAccepted: string;
  receiptLabel: string;
  instoreReceiptLabel: string;
  receiptButtonLabel: string;
  userEmailTitle: string;
  issueDescriptionTitle: string;
  emailSubject: string;
  removeFileLabel: string;
  requiredLabel: string;
  issueDescriptionPlaceholder: string;
  selectDateLabel: string;
  submitButtonLabel: string;
  additionalQueriesText: string | null;
  goToMyAccountLabel: string | null;
  maxFilesReached: string | null;
  clickToAddFile: string | null;
  uploadLabel: string | null;
};

export type MissingAviosFormProperties = {
  issueType: ISSUE_TYPE;
  toggleContent: () => void;
  hideFormStep: () => void;
  updateCurrentPageTitle: (input: string) => void;
  formData?: MissingAviosSubmitFormData;
  claimReason: MissingAviosClaimReasonOption;
} & Pick<
  MissingAviosPageModuleProperties,
  | 'formLabels'
  | 'programID'
  | 'userRegisteredCards'
  | 'noAutoClaimMerchants'
  | 'defaultLocale'
  | 'statusKeys'
>;

export const AVIOS_MISSING_AVIOS = '/manage-avios/activity';
