import { PartnerQueryArgument } from './partner.types';

const isNotEmpty = (array?: readonly unknown[]) => array && array.length > 0;

const buildMechanicsCondition = (
  collectionMechanics?: string[],
  isSpecialOffers?: boolean,
) => {
  return `mechanics: {
    ${isSpecialOffers ? 'is_special: { _eq: true },' : ''}
    program: { name: {_eq: $opCoId} },
    mechanic_collection_methods: {
      is_active: {_eq: true},
      ${
        isNotEmpty(collectionMechanics)
          ? `collection_method: { name: {_in: $collectionMechanics}}`
          : ''
      }
    }
  }`;
};

const buildCountryCondition = () => {
  return '_or : [ {country: {country_code: {_eq: $countryCode}}}, {_not: { country:{} }} ]';
};

const buildCategoryCondition = (categories?: string[]): string => {
  if (!isNotEmpty(categories)) return '';
  return 'merchant_categories: { category: { slug: {_in: $categories }}}';
};

export default function buildPartnerQuery({
  slugs,
  collectionMechanics,
  categories,
  externalReferences,
  merchantName,
  merchantIds,
  isSpecialOffers,
}: PartnerQueryArgument) {
  const whereConditions = [];

  whereConditions.push(
    buildMechanicsCondition(collectionMechanics, isSpecialOffers),
    buildCountryCondition(),
    buildCategoryCondition(categories),
  );

  if (isNotEmpty(externalReferences))
    whereConditions.push(`external_reference: {_in: $externalReferences}`);

  if (isNotEmpty(merchantIds))
    whereConditions.push(`merchant_id: {_in: $merchantIds}`);

  if (isNotEmpty(slugs)) whereConditions.push(`slug: {_in: $slugs} `);

  if (merchantName) whereConditions.push(`name: {_ilike: $merchantName}`);

  return `query PartnerQuery(
      $opCoId: bpchar!, 
      $countryCode: bpchar!,
      ${isNotEmpty(slugs) ? '$slugs: [String!],' : ''}
      ${
        isNotEmpty(collectionMechanics)
          ? '$collectionMechanics: [String!],'
          : ''
      }
      ${isNotEmpty(categories) ? '$categories: [String!],' : ''}
      ${isNotEmpty(externalReferences) ? '$externalReferences: [String!],' : ''}
      ${merchantName ? '$merchantName: String,' : ''}
      ${isNotEmpty(merchantIds) ? '$merchantIds: [Int!]' : ''}
      ) {
      merchant( where: { ${whereConditions.join(',\n')} } ) {
        merchant_id
        mechanics(where: {
          program: {name: {_eq: $opCoId}},
          ${
            isNotEmpty(collectionMechanics)
              ? 'mechanic_collection_methods: {collection_method: {name: {_in: $collectionMechanics}}},'
              : ''
          }
        }) {
          mechanic_id
          mechanic_collection_methods(order_by: {earn_rate:asc}, where: {
            is_active: {_eq: true},
            ${
              isNotEmpty(collectionMechanics)
                ? `collection_method: {name: {_in: $collectionMechanics}},`
                : ''
            }
          }) {
            rate
            earn_rate
            popular_order
            collection_method_locale_overrides {
                cta
              }
            collection_method {
              name
              collection_method_locales {
                cta
                locale {
                  name
                }
              }
            }
            offers(where: {is_active: {_eq: true}}) {
              start_date
              end_date
              rate
              was_rate
              special_offer_description
            }
          }
        }
        ${
          isNotEmpty(categories)
            ? 'merchant_categories(where: {category: {slug: {_in: $categories}}})'
            : 'merchant_categories'
        } {
          category {
            slug
            name
            category_locales {
						locale { name }
	          name
	          }
            merchant_categories(limit: 12, where: {merchant: {
              _or : [
                {country: {country_code: {_eq: $countryCode}}},
                {_not: {country:{}}}
              ],
              mechanics: {program: {name: {_eq: $opCoId}},
              mechanic_collection_methods:{is_active: {_eq: true}}}}}) {
              merchant {
                slug
              }
            }
          }
        }
        merchant_primary_categories {
          primary_category {
            slug
            merchant_primary_categories(limit: 12, where: {merchant: {
              _or : [
                {country: {country_code: {_eq: $countryCode}}},
                {_not: {country:{}}}
              ],
              mechanics: {program: {name: {_eq: $opCoId}},
              mechanic_collection_methods:{is_active: {_eq: true}}}}}) {
              merchant {
                slug
              }
            }
          }
        }
        name
        slug
        description
        external_reference
        merchant_images {
          image_url
          image_type {
            name
          }
        }
      }
    }`;
}
