import { useEffect } from 'react';
import trackEvent from '@/utils/track-event';
import { trackUserClick } from './track-clicks.utils';

const TrackClicks = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (event: any) => {
      const trackableClick = trackUserClick(event);

      if (trackableClick) trackEvent(trackableClick);
    };

    // Add event listener to document for click events
    document.addEventListener('click', handleClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return null; // This component doesn't render anything to the DOM
};

export default TrackClicks;
