import { LOCALE_CODES, OPCO_CODES } from '@channel/shared-components';
import { LOCALE_ID, OPCO_ID } from './models/market/market.types';
import { APPLICATION_ENVIRONMENT } from './middleware/application-environment/application-environment.types';

export const MEMBERSHIP_ID_KEYS = [
  'https://avios.com/membership_id',
  'https://avios.com/customer_identifier',
  'sub',
];

export const sharedComponentsOpcoMap: { [key in OPCO_ID]: OPCO_CODES } = {
  [OPCO_ID.AVIOS]: OPCO_CODES.BAEC,
  [OPCO_ID.BAEC]: OPCO_CODES.BAEC,
  [OPCO_ID.VYC]: OPCO_CODES.VYC,
  [OPCO_ID.IBP]: OPCO_CODES.IBP,
  [OPCO_ID.EIC]: OPCO_CODES.EIC,
};

export const sharedComponentsLocalesMap: { [key in LOCALE_ID]: LOCALE_CODES } =
  {
    [LOCALE_ID.EN_GB]: LOCALE_CODES['en-GB'],
    [LOCALE_ID.EN_US]: LOCALE_CODES['en-US'],
    [LOCALE_ID.EN_IE]: LOCALE_CODES['en-IE'],
    [LOCALE_ID.ES_ES]: LOCALE_CODES['es-ES'],
  };

export const HOTJAR_ID = {
  [APPLICATION_ENVIRONMENT.Production]: '1111164',
  [APPLICATION_ENVIRONMENT.Staging]: '3807850',
  [APPLICATION_ENVIRONMENT.Testing]: '3818882',
  [APPLICATION_ENVIRONMENT.Development]: '',
  [APPLICATION_ENVIRONMENT.Local]: '',
};

export const OPCO_ID_EXTERNAL_SERVICE = {
  [OPCO_ID.BAEC]: OPCO_ID.BAEC,
  [OPCO_ID.EIC]: OPCO_ID.EIC,
  [OPCO_ID.VYC]: OPCO_ID.VYC,
  [OPCO_ID.IBP]: OPCO_ID.IBP,
  [OPCO_ID.AVIOS]: OPCO_ID.BAEC,
};

// TODO: this const should be removed once all providers start working with
// the new AVIOS values instead of BAEC
export const TEMPORARY_OPCO_ID_ENV_VAR_PREFIX_MAP = {
  ...OPCO_ID_EXTERNAL_SERVICE,
  [OPCO_ID.AVIOS]: OPCO_ID.AVIOS,
};

// This needs to be also updated in the next.config.js file
export const AVIOS_PREFIX = '/collect-avios/shopping';
export const AVIOS_HOME = '/collect-avios';

export enum HEADERS {
  SET_COOKIE = 'Set-Cookie',
}
