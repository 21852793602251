import classNames from 'classnames';
import Button from '@/components/inputs/button';
import {
  SIZE as BUTTON_SIZE,
  VARIANT as BUTTON_VARIANT,
} from '@/components/inputs/button/button.types';
import Section from '@/components/integrated/section';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import { DATA_IDS } from '@/types';
import type { ErrorBannerProperties } from './error-banner.types';
import styles from './error-banner.module.scss';

const ErrorBanner = ({
  className = '',
  title,
  message,
  closeLabel,
  setShowError,
}: ErrorBannerProperties) => {
  return (
    <div
      className={classNames(styles['error-banner'], className)}
      data-id={DATA_IDS.ERROR_BANNER}
      role="alert"
    >
      <div className={styles['error-banner__container']}>
        <Section>
          <h3 className={styles['error-banner__title']}>{title}</h3>
          <p className={styles['error-banner__text']}>{message}</p>
        </Section>

        <Button
          className={styles['error-banner__close']}
          onClick={() => setShowError(false)}
          icon={ICON_VARIANT.X}
          size={BUTTON_SIZE.Large}
          variant={BUTTON_VARIANT.Text}
          aria-label={closeLabel}
          title={closeLabel}
        />
      </div>
    </div>
  );
};

export default ErrorBanner;
