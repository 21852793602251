import { CustomImage as Image } from '@/components/data-display/image';
import styles from './maintenance-content-page.module.scss';

type MaintenanceContentProperties = {
  title: string;
  description: string;
  pageLogo: {
    url: string;
    title: string;
  };
};

const MaintenanceContent = ({
  title,
  description,
  pageLogo,
}: MaintenanceContentProperties) => {
  if (!title && !description && !pageLogo) {
    return null;
  }

  return (
    <div className={styles['maintenance-page']}>
      <div className={styles['maintenance-content']}>
        <div className={styles['logo-container']}>
          {pageLogo && <Image src={pageLogo.url} alt={pageLogo.title} fill />}
        </div>
        {title && <h1>{title}</h1>}
        {description && <p>{description}</p>}
      </div>
    </div>
  );
};

export default MaintenanceContent;
