import { AVIOS_PREFIX } from '@/constants';
import {
  FeatureTogglesType,
  FEATURE_TOGGLE_LIST,
} from '@/context/feature-toggles/feature-toggles.types';
import { COOKIES } from '@/types';
import { isAviosOpco } from '@/utils/opco-utils';
import {
  AccessTokenError,
  AccessTokenErrorCode,
  AccessTokenRequest,
  Session,
} from '@auth0/nextjs-auth0';
import { StatusCodes } from 'http-status-codes';
import { decode } from 'jsonwebtoken';
import { NextApiRequest, NextApiRequestInjected } from 'next';
import { Market } from '../../models/market/market.types';
import logger, { formatErrorForLogging } from '../../utils/logger';
import { getCollinsonUser } from '../collinson/collinson.utils';
import { getAuth0AuthorizationParametersForAudience } from './auth0.config';
import {
  SILENT_LOGIN_FLAG_EXPIRY_TIME_IN_SECONDS,
  sortedAudiencesWithApiAviosLast,
  urlsToAudience,
} from './auth0.constants';
import { getAuth0Server } from './auth0.server-provider';
import {
  AudienceData,
  AudienceTokenData,
  CustomSession,
  GetCustomSessionResponse,
  HandleNewRefreshedTokenParameters,
  HandleRefreshErrorParameters,
  RefreshAudienceTokenParameters,
} from './auth0.types';

export class DecodeJwtError extends Error {}

export const getFirstMissingAudience = (
  existingAudiencesNames: Partial<NonNullable<CustomSession['audiences']>>,
) =>
  sortedAudiencesWithApiAviosLast.find(neededAudienceName => {
    const audienceData = existingAudiencesNames[neededAudienceName];
    if (!audienceData) return true;

    const expiryDate =
      formatAudienceTokenData(audienceData).expiresAtInMilliseconds;

    return expiryDate && expiryDate < Date.now();
  });

export const getAudienceUrlFromJwt = (newSession: CustomSession) => {
  if (newSession.accessToken) {
    const decodedJwt = decode(newSession.accessToken);

    if (
      decodedJwt &&
      typeof decodedJwt === 'object' &&
      decodedJwt.aud?.[0] &&
      urlsToAudience[decodedJwt.aud?.[0]]
    )
      return urlsToAudience[decodedJwt.aud?.[0]];

    logger.error('Invalid decoded jwt', {
      type: typeof decodedJwt,
      audiences: typeof decodedJwt === 'object' ? decodedJwt?.aud : 'missing',
    });
  } else {
    logger.error('Missing access token from session');
  }

  throw new DecodeJwtError(
    'Invalid session. Could not get audiences from jwt.',
  );
};

export const getSanitizedReturnTo = (
  returnTo?: string,
  url?: string,
): string | undefined => {
  try {
    // eslint-disable-next-line no-param-reassign
    returnTo = returnTo && atob(returnTo);
  } catch (error) {
    logger.info(
      'There was an error while trying to decode the returnTo parameter. Will continue as usual.',
      error,
    );
  }

  if (returnTo?.startsWith('//')) return undefined;

  if (!returnTo || returnTo.startsWith('/')) return returnTo;

  if (!url?.length) return undefined;

  if (!returnTo.startsWith(url)) return undefined;

  const sanitizedReturnTo = returnTo.slice(url.length);
  return sanitizedReturnTo.startsWith('/')
    ? sanitizedReturnTo
    : `/${sanitizedReturnTo}`;
};

export const attachCollinsonUserData = async (
  newSession: Session,
  market: Market,
): Promise<Session> => {
  let collinsonData = null;

  try {
    collinsonData = await getCollinsonUser(newSession, market);
  } catch (error) {
    logger.error('Failed to attach collinson user info to the user object.', {
      error: formatErrorForLogging(error),
    });
  }

  return {
    ...newSession,
    user: {
      ...newSession.user,
      collinson: collinsonData,
    },
  };
};

export const formatAudienceTokenData = (
  audienceData: AudienceData,
): AudienceTokenData => {
  if (typeof audienceData === 'string') {
    logger.warn(
      'Legacy session active, mapping to new format without refresh data.',
    );

    return {
      accessToken: audienceData,
    };
  }
  return audienceData;
};

const handleNewRefreshedSession = ({
  newRefreshedSession,
  previousOutdatedTokenData: previousTokenData,
  isMainToken,
  previousMainTokenData,
  refreshedAudience,
  previousSession,
}: HandleNewRefreshedTokenParameters) => {
  logger.info('Processing new refreshed token data.');
  const invalidRefreshData =
    !newRefreshedSession.accessToken ||
    !newRefreshedSession.refreshToken ||
    typeof newRefreshedSession.accessTokenExpiresAt !== 'number';
  if (invalidRefreshData)
    logger.error(
      'Refreshed token data is missing necessary properties. Contact the identity provider for support.',
      {
        hasAccessToken: Boolean(newRefreshedSession.accessToken),
        hasRefreshToken: Boolean(newRefreshedSession.refreshToken),
        hasAccessTokenExpiresAt: Boolean(
          newRefreshedSession.accessTokenExpiresAt,
        ),
      },
    );

  const refreshedTokenDataForAudienceOrDefault: AudienceTokenData =
    invalidRefreshData
      ? previousTokenData
      : {
          accessToken: newRefreshedSession.accessToken as string,
          refreshToken: newRefreshedSession.refreshToken as string,
          expiresAtInMilliseconds:
            (newRefreshedSession.accessTokenExpiresAt as number) * 1000,
        };

  return {
    ...newRefreshedSession,
    ...((!isMainToken || invalidRefreshData) && previousMainTokenData),
    accessTokenScope:
      getAuth0AuthorizationParametersForAudience(refreshedAudience).scope,
    audiences: {
      ...previousSession.audiences,
      [refreshedAudience]: refreshedTokenDataForAudienceOrDefault,
    },
  };
};

const handleRefreshError = async ({
  error,
  audienceToRefresh,
  isMainToken,
  authServer,
  requestResponse,
  session,
}: HandleRefreshErrorParameters) => {
  logger.error(
    'There was an error while refreshing the access token. Defaulting to the current token value.',
    {
      error: formatErrorForLogging(error),
      audience: audienceToRefresh,
    },
  );
  if (!isMainToken) await authServer.updateSession(...requestResponse, session);

  if (
    error instanceof AccessTokenError &&
    error.code === AccessTokenErrorCode.FAILED_REFRESH_GRANT
  ) {
    const response = requestResponse[1];
    if ('setHeader' in response) {
      response.setHeader('Location', `${AVIOS_PREFIX}/api/auth/logout`);
      response.statusCode = StatusCodes.MOVED_TEMPORARILY;
    }
  }
};

export const refreshAudienceToken = async ({
  audienceToRefresh,
  session,
  market,
  requestResponse,
}: RefreshAudienceTokenParameters): Promise<string | undefined> => {
  if (!session) return undefined;

  const unformattedTokenDataForAudience =
    session.audiences?.[audienceToRefresh];
  const formattedTokenDataToRefresh = unformattedTokenDataForAudience
    ? formatAudienceTokenData(unformattedTokenDataForAudience)
    : undefined;

  if (
    !formattedTokenDataToRefresh?.expiresAtInMilliseconds ||
    !formattedTokenDataToRefresh.refreshToken
  ) {
    logger.warn(
      'Trying to refresh a token without refresh data. Falling back to unrefreshed token or undefined.',
      {
        audienceToRefresh,
        audiences: Object.values(session.audiences ?? {}),
      },
    );

    return formattedTokenDataToRefresh?.accessToken;
  }

  const authServer = getAuth0Server(market);

  const originalSessionTokenData = {
    accessToken: session.accessToken,
    refreshToken: session.refreshToken,
    accessTokenExpiresAt: session.accessTokenExpiresAt,
  };
  const isMainToken =
    originalSessionTokenData.accessToken ===
    formattedTokenDataToRefresh.accessToken;

  if (!isMainToken)
    await authServer.updateSession(...requestResponse, {
      ...session,
      accessToken: formattedTokenDataToRefresh.accessToken,
      refreshToken: formattedTokenDataToRefresh.refreshToken,
      accessTokenExpiresAt: Math.floor(
        formattedTokenDataToRefresh.expiresAtInMilliseconds / 1000,
      ),
    });

  try {
    logger.info('Trying to refresh token', { audienceToRefresh });

    const { accessToken: refreshedAccessToken } =
      await authServer.getAccessToken(...requestResponse, {
        refresh: true,
        authorizationParams:
          getAuth0AuthorizationParametersForAudience(audienceToRefresh),
        afterRefresh: (request, response, newRefreshedSession) =>
          handleNewRefreshedSession({
            newRefreshedSession,
            previousOutdatedTokenData: formattedTokenDataToRefresh,
            isMainToken,
            previousMainTokenData: originalSessionTokenData,
            refreshedAudience: audienceToRefresh,
            previousSession: session,
          }),
      } as AccessTokenRequest);

    if (!refreshedAccessToken) {
      throw new Error('Data from token refresh is empty.');
    }

    logger.info('Token refreshed successfully', { audienceToRefresh });

    return refreshedAccessToken;
  } catch (error) {
    await handleRefreshError({
      error,
      audienceToRefresh,
      isMainToken,
      authServer,
      requestResponse,
      session,
    });
  }

  return formattedTokenDataToRefresh.accessToken;
};

export const getFormattedSilentLoginCookieFlag = (toggle: boolean) =>
  `${COOKIES.SH_SILENT_AUTH}=true;Path=/;Max-Age=${
    toggle ? SILENT_LOGIN_FLAG_EXPIRY_TIME_IN_SECONDS : 0
  }`;

export const isSilentLogin = (request: NextApiRequest) =>
  request.cookies[COOKIES.SH_SILENT_AUTH] === 'true';

export const getFormattedReturnToCookie = (returnTo: string, toggle: boolean) =>
  `${COOKIES.SILENT_LOGIN_RETURN_TO_BASE64}=${returnTo};Path=/${
    toggle ? '' : ';Max-Age=0'
  }`;

export const appSessionCookiesInRequest = (request: NextApiRequestInjected) =>
  Object.keys(request.cookies).some(cookieName =>
    cookieName.startsWith('appSession'),
  );

export const getFormattedClearedAppSessionCookies = (
  request: NextApiRequest,
) => {
  const appSessionCookieNames = Object.keys(request.cookies).filter(
    cookieName => cookieName.startsWith('appSession'),
  );

  return appSessionCookieNames.map(
    cookieName => `${cookieName}=;Path=/;Max-Age=0`,
  );
};

export const shouldRunSilentLoginForPath = (path?: string) =>
  !path ||
  !['sitemap', '_next', 'themes', 'fonts'].some(string =>
    path.includes(string),
  );

export const shouldRunSilentLoginForAviosOpco = (
  request: NextApiRequestInjected,
  session?: GetCustomSessionResponse,
) =>
  isAviosOpco(request.market.opCoId) &&
  !session &&
  appSessionCookiesInRequest(request);

export const shouldRunSilentLoginForNonAviosOpco = (
  request: NextApiRequestInjected,
  featureToggles: FeatureTogglesType,
  session?: GetCustomSessionResponse,
) =>
  !isAviosOpco(request.market.opCoId) &&
  !session &&
  featureToggles.includes(FEATURE_TOGGLE_LIST.TEMP_USE_SSO_ON_PAGE_LOAD) &&
  !isSilentLogin(request);
