export const localeModalQuery = `query LocaleModalQuery($contentfulOpCoId: String!, $countryId: String!){
  localeModalCollection(limit: 1 where: {countryId: $countryId, programId: $contentfulOpCoId}) {
    items {
      title
      description
    }
  }
  microcopyCollection (where: {OR: [{key: "footer.label.en_gb"}, {key: "footer.label.en_us"}, {key: "footer.label.en_ie"}, {key: "footer.label.es_es"}]}){
    items {
      key
      value
    }
  }
}`;
