import ContentfulProvider from '@/providers/contentful/contentful.provider';
import { VersatileCardProperties } from '@/components/integrated/versatile-card/versatile-card.types';
import { Market } from '@/models/market/market.types';
import { formatImageOptimised } from '@/utils/contentful-data-formatters/contentful-data-formatters';
import { downloadRewardsAppQuery } from './download-rewards-app.query';
import { DownloadAppResponse } from './download-rewards-app.types';

export const downloadRewardsAppHandler = async (
  market: Market,
): Promise<VersatileCardProperties> => {
  const contentfulResponse = await new ContentfulProvider(
    market,
  ).makeContentfulRequest<DownloadAppResponse>({
    query: downloadRewardsAppQuery,
  });

  const result =
    contentfulResponse.data?.versatileCardCollection?.items[0] ?? {};

  return {
    id: result?.htmlId,
    backgroundColor: result?.backgroundColor,
    content: {
      headingOne: result?.headingOne,
      headingThree: result?.headingThree,
      headingFive: result?.headingFive,
      body: result?.bodyContent,
    },
    image: {
      src: result?.image?.url,
      alt: result?.imageAlt,
      position: result?.imagePosition,
      height: result?.imageHeight,
      width: result?.imageWidth,
      contained: result?.imageContained,
      optimised: formatImageOptimised(result?.imageOptimised),
    },
    ctas: {
      ctaFullWidth: result?.ctaFullWidth,
      imageButtonPrimary: {
        src: result?.imageButtonPrimary?.url ?? '',
        alt: result?.imageButtonPrimary?.description ?? '',
        height: result?.imageButtonPrimary?.height,
        width: result?.imageButtonPrimary?.width,
        url: result?.imageButtonPrimaryUrl ?? '',
      },
      imageButtonSecondary: {
        src: result?.imageButtonSecondary?.url ?? '',
        alt: result?.imageButtonSecondary?.description ?? '',
        height: result?.imageButtonSecondary?.height,
        width: result?.imageButtonSecondary?.width,
        url: result?.imageButtonSecondaryUrl ?? '',
      },
    },
  };
};
