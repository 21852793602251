import { ReactNode } from 'react';

export type ModalProperties = {
  label?: string;
  closeLabel?: string;
  children?: ReactNode;
  onCloseFunction?: (closeType: MODAL_CLOSE_TYPE) => void;
  closeOnOutsideClick?: boolean;
  disableCloseButton?: boolean;
  className?: string;
};

export enum MODAL_CLOSE_TYPE {
  BUTTON = 'BUTTON',
  OUTSIDE = 'OUTSIDE',
}
