export const maintenanceContentQuery = `query MaintenanceContentfulQuery($contentfulOpCoId: String!, $countryId: String!) {
    pageMaintenanceCollection (where: { programId: $contentfulOpCoId, countryId: $countryId }) {
        items {
            title
            description
            pageLogo {
                url
                title
            }
        }
    }
}`;
