export const qrCodeQuery = `
query QrCodeQuery($contentfulOpCoId: String, $countryId: String, $locale: String) {
    headerCollection(limit: 1, where: {programId: $contentfulOpCoId, countryId: $countryId}) {
        items {
          programLogoSmallScreen {
            url
            title
          }
        }
      }
      microcopyCollection(locale: $locale, where: {
        key_in: [ "myestore.header.membershipnumber", "showQrCode.title", "qrCode.error.title", "qrcode.error.details", "general.closeLabel" ]
        }) {
        items {
          key
          value
        }
      }
}`;
