export enum VARIANT {
  ArrowDown = 'icon--arrow-down',
  ArrowLeft = 'icon--arrow-left',
  ArrowRight = 'icon--arrow-right',
  ArrowUp = 'icon--arrow-up',
  ArrowUpRight = 'icon--arrow-up-right',
  Award = 'icon--award',
  Bell = 'icon--bell',
  BellOff = 'icon--bell-off',
  Bookmark = 'icon--bookmark',
  Calendar = 'icon--calendar',
  Camera = 'icon--camera',
  CameraOff = 'icon--camera-off',
  Check = 'icon--check',
  CheckCircle = 'icon--check-circle',
  ChevronDown = 'icon--chevron-down',
  ChevronLeft = 'icon--chevron-left',
  ChevronRight = 'icon--chevron-right',
  ChevronUp = 'icon--chevron-up',
  Compass = 'icon--compass',
  Copy = 'icon--copy',
  CreditCard = 'icon--credit-card',
  ExternalLink = 'icon--external-link',
  Eye = 'icon--eye',
  EyeOff = 'icon--eye-off',
  Filter = 'icon--filter',
  Gift = 'icon--gift',
  Heart = 'icon--heart',
  HeartFilled = 'icon--heart-filled',
  Home = 'icon--home',
  Image = 'icon--image',
  Info = 'icon--info',
  Lock = 'icon--lock',
  Logout = 'icon--logout',
  Mail = 'icon--mail',
  Map = 'icon--map',
  MapPin = 'icon--map-pin',
  Menu = 'icon--menu',
  MessageCircle = 'icon--message-circle',
  MoreHorizontal = 'icon--more-horizontal',
  Navigation = 'icon--navigation',
  Plus = 'icon--plus',
  Printer = 'icon--printer',
  RefreshCcw = 'icon--refresh-ccw',
  Search = 'icon--search',
  Send = 'icon--send',
  Settings = 'icon--settings',
  Sliders = 'icon--sliders',
  Speedy = 'icon--speedy',
  Star = 'icon--star',
  Tag = 'icon--tag',
  ThumbsDown = 'icon--thumbs-down',
  ThumbsUp = 'icon--thumbs-up',
  Trash2 = 'icon--trash-2',
  User = 'icon--user',
  X = 'icon--x',
  XCircle = 'icon--x-circle',
  ZoomIn = 'icon--zoom-in',
  ZoomOut = 'icon--zoom-out',
  Store = 'icon--store',
  Desktop = 'icon--desktop',
  WhatsApp = 'icon--whatsapp',
  Link = 'icon--link',
  AviosPlectrum = 'icon--avios-plectrum',
  Clock = 'icon--clock',
  History = 'icon--history',
  AviosMissing = 'icon--avios-missing',
  Qrcode = 'icon--qrcode',
  Exclamation = 'icon--exclamation',
  Frown = 'icon--frown',
  Success = 'icon--success',
  Warning = 'icon--warning',
  Error = 'icon--error',
  Pause = 'icon--pause',
  Play = 'icon--play',
  Upload = 'icon--upload',
  BagShopping = 'icon--bag-shopping',
  CcAmex = 'icon--cc-amex',
  MoneyBill = 'icon--money-bill',
  PiggyBank = 'icon--piggy-bank',
  Gifts = 'icon--gifts',
  Hotel = 'icon--hotel',
  PlaneDeparture = 'icon--plane-departure',
  ShieldCheck = 'icon--shield-check',
  ArrowRightArrowLeft = 'icon--arrow-right-arrow-left',
  CarBuilding = 'icon--car-building',
  CircleUp = 'icon--circle-up',
  Laptop = 'icon--laptop',
  BurgerSoda = 'icon--burger-soda',
  CarSide = 'icon--car-side',
  CircleArrowDownSolid = 'icon--circle-arrow-down-solid',
  ClothesHanger = 'icon--clothes-hanger',
  Snowman = 'icon--snowman',
  Suitcase = 'icon--suitcase',
  WineGlass = 'icon--wine-glass',
  AviosPlectrumSolid = 'icon--avios-plectrum-solid',
  AviosMissingSolid = 'icon--avios-missing-solid',
  TaxiBus = 'icon--taxi-bus',
  SimCard = 'icon--sim-card',
}

export type IconProperties = {
  variant: VARIANT;
  withBackground?: boolean;
} & JSX.IntrinsicElements['span'];
