// eslint-disable-next-line unicorn/prefer-node-protocol
import crypto from 'crypto';
import { Session } from '@auth0/nextjs-auth0';
import { Market, OPCO_ID } from '../../models/market/market.types';
// eslint-disable-next-line import/no-cycle
import CollinsonProvider from './collinson.provider';
import { GetUserResponse } from './collinson.types';
import extractUserData from '../../utils/extract-user-data';

export const serializedData = (
  data:
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams
    | undefined,
) => new URLSearchParams(data).toString();

export const generateSHA1 = (string: string): string =>
  crypto.createHash('sha1').update(string).digest('hex');

export const generateHMAC = (
  now: number,
  method: string,
  path: string,
  key: string,
  data: Record<string, string>,
): string => {
  const theData = serializedData(data);
  let string = `${now}\n${method}\n${path}`;
  if (theData && theData.length > 0) {
    string += `\n${generateSHA1(theData)}`;
  }
  return crypto.createHmac('sha256', key).update(string).digest('hex');
};

export const generateXAuthorizeToken = (
  PARTNER_ID: string,
  API_KEY: string,
): string => {
  const dateNow = new Date();
  const month = `0${dateNow.getMonth() + 1}`.slice(-2);
  const day = `0${dateNow.getDate()}`.slice(-2);
  const data = `${PARTNER_ID}-${dateNow.getFullYear()}${month}${day}-${API_KEY}`;

  const result = crypto.createHash('sha256').update(data).digest('hex');
  return Buffer.from(`${PARTNER_ID}:${result}`).toString('base64');
};

export const getCollinsonExternalIdFromMembershipNumber = (
  membershipId: string,
  market: Market,
): string => {
  if (!membershipId) return '';
  /*
   * IB changed the membership number to have 14 digits (prefixing with 0s)
   * if we blindly use the POST endpoint we'll be creating new Collinson profiles
   * for users already existing.
   * That's why we use first the GET endpoint with the old number.
   */
  if (market.opCoId === OPCO_ID.IBP) {
    return membershipId.replace(/^0+/, '');
  }
  // Avios now only uses 9 digits from within the membership number to create a unique user
  if (
    [OPCO_ID.VYC, OPCO_ID.EIC].includes(market.opCoId) &&
    membershipId.length >= 15
  ) {
    return membershipId.slice(6, 15);
  }
  // BA users
  return membershipId;
};

export const addOpcoPrefixOnUserId = (userId: string, market: Market) => {
  switch (market.opCoId) {
    case OPCO_ID.AVIOS:
    case OPCO_ID.BAEC: {
      return `BA${userId}`;
    }
    case OPCO_ID.EIC:
    case OPCO_ID.VYC: {
      return `AV${userId}`;
    }
    case OPCO_ID.IBP: {
      return `PB${userId}`;
    }
    default: {
      return userId;
    }
  }
};

export const getCollinsonUser = async (
  session: Session,
  market: Market,
): Promise<GetUserResponse['data']> => {
  if (session.user.collinson) {
    return session.user.collinson;
  }
  const userDataObject = extractUserData(session);
  const collinsonUser = await new CollinsonProvider(market).getUser(
    userDataObject,
  );
  return collinsonUser.data;
};
