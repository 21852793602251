import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Loader from '@/components/feedback/loader';
import styles from './loading-screen.module.scss';

const LoadingScreen = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  let targetURL = '/';

  if (router.defaultLocale === router.locale) {
    targetURL = router.asPath;
  } else {
    targetURL =
      router.asPath === '/'
        ? `/${router.locale}`
        : `/${router.locale}${router.asPath}`;
  }

  const handleRouteChangeStart = (url: string) => {
    const formattedUrl = url.split('?')[0];
    const formattedTargetUrl = targetURL.split('?')[0];

    return formattedUrl !== formattedTargetUrl && setLoading(true);
  };

  const handleRouteChangeComplete = () => setLoading(false);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return loading ? (
    <div className={styles[`loading-screen__page`]}>
      <Loader className={styles[`loading-screen__loader`]} />
    </div>
  ) : null;
};

export default LoadingScreen;
