import { APPLICATION_ENVIRONMENT } from '@/middleware/application-environment/application-environment.types';
import { PROGRAM_ID } from '@/models/market/market.types';
import { GENERIC_VALUES } from '@/modules/track-clicks/track-clicks.types';
import { ProgramDataType } from '@/pages/_app/_app.types';

export enum GA_PROGRAM_ID {
  AVIOS = 'british_airways',
  BAEC = 'british_airways',
  VYC = 'vueling',
  IBP = 'iberia',
  EIC = 'aer_lingus',
}

export type GlobalEventObject = {
  isLoggedIn: boolean;
  Iso: string;
  product: string;
  programmeCode: GA_PROGRAM_ID;
  memberID: string | null;
  balance: string | null;
  domain_environment: GENERIC_VALUES;
  channel: null;
  partner_name: null;
  booking_currency: null;
  avios_from_booster: null;
  avios_from_subscription: null;
};

export interface GlobalEventObjectParamters {
  programData: ProgramDataType;
  isLoggedIn: boolean;
  membershipId?: string;
  balance?: string;
  applicationEnvironment: APPLICATION_ENVIRONMENT;
}

export interface UseMetricsParameters extends GlobalEventObjectParamters {
  daysSinceLastPurchase?: number;
}

export type ProgrammeIdGaMap = {
  [key in PROGRAM_ID]: GA_PROGRAM_ID;
};
