import NextImage from '@/components/data-display/image';
import Button from '@/components/inputs/button';
import { ButtonCollectionProperties } from '@/components/integrated/versatile-card/versatile-card.types';
import styles from './button-image.module.scss';

const ButtonImage = (component: ButtonCollectionProperties) => {
  const { image, id } = component;
  if (image) {
    return (
      <Button key={id} {...component} className={styles.button}>
        <NextImage
          src={image.url}
          alt={image.description ?? ''}
          height={image.height}
          width={image.width}
        />
      </Button>
    );
  }
  return <Button key={id} {...component} />;
};

export default ButtonImage;
