import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ErrorBanner from '@/components/feedback/error-banner';
import { NotificationsProperties, NOTIFICATIONS } from './notifications.types';

const Notifications = ({
  closeLabel,
  notificationMicrocopy,
}: NotificationsProperties) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [error, setError] = useState({
    title: '',
    message: '',
  });

  useEffect(() => {
    const invalidUserLoginCookie = Cookies.get(
      NOTIFICATIONS.ERROR_INVALID_ACCOUNT,
    );

    if (invalidUserLoginCookie) {
      const notification = notificationMicrocopy?.find(value => {
        return value.id === NOTIFICATIONS.ERROR_INVALID_ACCOUNT;
      });

      setError({
        title: notification?.title ?? '',
        message: notification?.message ?? '',
      });
      setShowError(true);
      Cookies.remove(NOTIFICATIONS.ERROR_INVALID_ACCOUNT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showError ? (
    <ErrorBanner
      setShowError={setShowError}
      closeLabel={closeLabel}
      title={error?.title}
      message={error?.message}
    />
  ) : null;
};

export default Notifications;
