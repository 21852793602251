import getEnvironmentVariable from '../../utils/get-environment-variable';
import { Config } from './contentful.types';
import { OPCO_ID } from '../../models/market/market.types';

const getContentfulConfig = (): Config => {
  const SPACE_ID = getEnvironmentVariable('CONTENTFUL_SPACE_ID');
  const ENVIRONMENT = getEnvironmentVariable('CONTENTFUL_ENVIRONMENT');
  const RAW_BASE_URL = getEnvironmentVariable('CONTENTFUL_URL');

  const BASE_URL = RAW_BASE_URL.replace('$SPACE_ID', SPACE_ID).replace(
    '$CONTENTFUL_ENVIRONMENT',
    ENVIRONMENT,
  );

  return {
    BASE_URL,
    ACCESS_TOKEN: getEnvironmentVariable('CONTENTFUL_ACCESS_TOKEN'),
  };
};

export const CONTENTFUL_OPCO_ID: Record<OPCO_ID, string> = {
  [OPCO_ID.AVIOS]: 'avios',
  [OPCO_ID.BAEC]: 'ba',
  [OPCO_ID.EIC]: 'aerclub',
  [OPCO_ID.VYC]: 'vueling',
  [OPCO_ID.IBP]: 'iberia',
};

export default getContentfulConfig;
