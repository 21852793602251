import { GetServerSidePropsContext } from 'next';
import {
  SearchBarProperties,
  SearchBarQueryResponse,
} from './search-bar.types';
import ContentfulProvider from '../../providers/contentful';
import { searchBarQuery } from './search-bar.query';
import { formatMicrocopy } from '../../utils/format-microcopy';

export const searchBarHandler = async ({
  req: { market },
}: GetServerSidePropsContext): Promise<SearchBarProperties> => {
  const contentfulResponse = await new ContentfulProvider(
    market,
  ).makeContentfulRequest<SearchBarQueryResponse>({
    query: searchBarQuery,
  });

  const { searchPlaceholderText } =
    contentfulResponse.data.headerCollection.items[0];

  const microcopy = formatMicrocopy(
    contentfulResponse.data.microcopyCollection.items,
  );

  return {
    searchPlaceholderText,
    closeLabel: microcopy?.general_closelabel || null,
    searchLabel: microcopy?.search_togglesearch || null,
    toggleSearchLabel: microcopy?.search_label || null,
  };
};
