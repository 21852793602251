import type { EventObjectProperties } from './track-event.types';

export const removeNullValuesFromEvent = (event: EventObjectProperties) =>
  Object.fromEntries(
    Object.entries(event).filter(([, value]) => value !== null),
  ) as Record<string, string | number | boolean>;

const trackEvent = (object?: EventObjectProperties) => {
  if (!window || !object) return false;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(removeNullValuesFromEvent(object));
  return true;
};

export default trackEvent;
