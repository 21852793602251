export const toggleModal = (label?: string) => {
  if (!window) return;
  const body = window.document.querySelectorAll('body')[0];
  const modalElement = window.document.querySelector(
    `dialog#${label}`,
  ) as HTMLDialogElement;
  const modalOpen = modalElement?.open;

  if (!modalOpen) {
    modalElement?.showModal();
    if (body) body.classList.add('hideOverflow');
  } else if (modalOpen) {
    modalElement?.close();
    if (body) body.classList.remove('hideOverflow');
  }
};

export const closeModal = (label?: string) => {
  const body = window.document.querySelectorAll('body')[0];
  const modalElement = window.document.querySelector(
    `dialog#${label}`,
  ) as HTMLDialogElement;

  if (body) body.classList.remove('hideOverflow');
  modalElement.close();
};
