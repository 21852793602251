export const cookieBannerQuery = `query CookieBannerQuery($locale: String!) {
  microcopyCollection(locale: $locale, where: {
    key_in: [
      "cookienotice.close",
      "cookienotice.accept",
      "cookienotice.message"
    ]
  }) {
    items {
      key
      value
    }
  }
}`;
