import { CustomImage as Image } from '@/components/data-display/image';
import classNames from 'classnames';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import Tooltip from '@/components/utilities/tooltip';
import type { PartnerTileProperties } from './partner-tile.types';
import { VARIANT, TEXT_SIZE, LOGO_SIZE } from './partner-tile.types';
import styles from './partner-tile.module.scss';

export const findUpToOrFrom = (text: string) => {
  const pattern = /\b(up to|from|hasta|desde)\b/i;
  const match = pattern.exec(text);
  return match ? match[0] : null;
};

const PartnerTile = ({
  partnerId,
  mechanicId,
  className = '',
  speedyClassName = '',
  rate,
  isSpeedyAwarding,
  speedyAwardText,
  wasRate,
  subField1,
  subField2,
  logo,
  name,
  textSize = TEXT_SIZE.medium,
  separator,
  variant = VARIANT.Default,
  showWasRate = true,
  ...properties
}: PartnerTileProperties) => {
  const { logoSize = LOGO_SIZE.extraSmall, src, alt } = logo;
  const prefix = findUpToOrFrom(rate ?? '');
  const rateWithoutPrefix = prefix && rate?.split(prefix)[1];

  return (
    <div
      className={`${styles['partner-tile']} ${styles[variant]} ${className}`}
      {...properties}
    >
      {isSpeedyAwarding && (
        <Tooltip description={speedyAwardText}>
          <p
            className={classNames(
              styles['partner-tile__speedy'],
              speedyClassName,
            )}
          >
            <Icon
              variant={ICON_VARIANT.Speedy}
              aria-label={speedyAwardText}
              aria-hidden={false}
            />
          </p>
        </Tooltip>
      )}
      <div
        className={classNames({
          [styles['partner-tile__image-container-border']]: separator,
        })}
      >
        {src && (
          <div
            className={classNames(styles['partner-tile__image'], {
              [styles[`partner-tile__image${logoSize}`]]: logoSize,
            })}
          >
            <Image crossOrigin="anonymous" src={src} alt={alt} fill />
          </div>
        )}
      </div>
      <div
        className={classNames(styles['partner-tile__content'], {
          [styles[`partner-tile__content${logoSize}`]]: logoSize,
        })}
      >
        {name && (
          <p
            className={classNames(
              styles['partner-tile__content-name'],
              styles[`partner-tile__content-name${textSize}`],
            )}
          >
            {name}
          </p>
        )}

        <div
          className={classNames(styles['partner-tile__content-rate'], {
            [styles[`partner-tile__content-rate--inline`]]:
              variant === VARIANT.Inline,
          })}
        >
          {rate && (
            <div
              className={classNames(
                styles['partner-tile__content-rate'],
                styles[`partner-tile__content-rate${textSize}`],
              )}
            >
              <p
                className={classNames(
                  styles['partner-tile__content-rate'],
                  wasRate && styles['partner-tile__content-rate--highlighted'],
                )}
              >
                {wasRate && (
                  <Icon
                    className={
                      styles['partner-tile__content-rate--highlighted-icon']
                    }
                    variant={ICON_VARIANT.ArrowUp}
                  />
                )}
                {prefix ? (
                  <span>
                    <span
                      className={styles['partner-tile__content-rate-prefix']}
                    >
                      {prefix}
                    </span>
                    <span
                      className={
                        styles['partner-tile__content-rate-highlighted-rate']
                      }
                    >
                      {rateWithoutPrefix}
                    </span>
                  </span>
                ) : (
                  rate
                )}
              </p>
            </div>
          )}
        </div>

        {(showWasRate || wasRate) && (
          <p
            className={classNames(
              styles['partner-tile__content-was-rate'],
              styles[`partner-tile__content-was-rate${textSize}`],
            )}
          >
            {rate && wasRate}
          </p>
        )}

        {subField1 && subField2 && (
          <p
            className={classNames(
              styles['partner-tile__content-sub-fields'],
              styles[`partner-tile__content-sub-fields${textSize}`],
            )}
          >
            <span>{subField1}</span>
            <span
              className={styles['partner-tile__content-sub-fields__separator']}
            >
              •
            </span>
            <span>{subField2}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default PartnerTile;
