import { Auth0Server, GetSession, Session } from '@auth0/nextjs-auth0';
import { Market } from '../../models/market/market.types';

export type Config = {
  secret: string;
  baseURL: string;
  issuerBaseURL: string;
  clientID: string;
  clientSecret: string;
};

export enum AUDIENCES {
  API_AVIOS = 'API_AVIOS',
  ACT = 'ACT',
  FS = 'FS',
}

export interface AudienceTokenData {
  accessToken: string;
  refreshToken?: string;
  expiresAtInMilliseconds?: number;
}

export type AudienceData = string | AudienceTokenData;

export type CustomSession = Session & {
  audiences?: Record<AUDIENCES, AudienceData>;
};

export type GetCustomSessionResponse =
  | Exclude<Awaited<ReturnType<GetSession>>, Session>
  | CustomSession;

export interface GetSessionParameters {
  market: Market;
  requestResponse: Exclude<Parameters<GetSession>, []>;
}

export type GetAccessTokenParameters = Omit<
  GetAccessTokenFromSessionParameters,
  'session'
> &
  GetSessionParameters;

export interface GetAccessTokenFromSessionParameters {
  session: GetCustomSessionResponse;
  audience?: AUDIENCES;
}

export interface RefreshAudienceTokenParameters {
  audienceToRefresh: AUDIENCES;
  session: GetCustomSessionResponse;
  market: Market;
  requestResponse: Exclude<Parameters<GetSession>, []>;
}

export interface HandleRefreshErrorParameters {
  error: unknown;
  audienceToRefresh: AUDIENCES;
  isMainToken: boolean;
  authServer: Omit<Auth0Server, 'withMiddlewareAuthRequired'>;
  requestResponse: Exclude<Parameters<GetSession>, []>;
  session: CustomSession;
}

export interface HandleNewRefreshedTokenParameters {
  refreshedAudience: AUDIENCES;
  newRefreshedSession: Session;
  previousOutdatedTokenData: AudienceTokenData;
  isMainToken: boolean;
  previousMainTokenData: {
    accessToken?: string;
    refreshToken?: string;
    accessTokenExpiresAt?: number;
  };
  previousSession: CustomSession;
}
