import { B2BToken, OrderTypes, SortTypes } from '../../types';
import type {
  CBRCollectionMechanic,
  COLLECTION_MECHANIC,
} from '../collection-mechanic/collection-mechanic.types';
import Market from '../market';
import { OPCO_ID } from '../market/market.types';

export type CBRPartner = Readonly<{
  merchant_id: number | null;
  mechanics: Array<{
    mechanic_id: number;
    mechanic_collection_methods: CBRCollectionMechanic[];
  }>;
  merchant_categories: Array<{
    category: {
      slug: string;
      name: string;
      category_locales: Array<{ locale: { name: string }; name: string }>;
      merchant_categories: Array<{
        merchant: { slug: string };
      }>;
    };
  }>;
  merchant_primary_categories: Array<{
    primary_category: {
      slug: string;
      merchant_primary_categories: Array<{
        merchant: { slug: string };
      }>;
    };
  }>;
  name: string;
  slug: string;
  external_reference: string;
  description: string;
  merchant_images: Array<{
    image_url: string;
    image_type: {
      name: string;
    };
  }>;
}>;

export type CBRMechanicPartner = {
  merchant: CBRPartner;
};

export type CBRMechanicPartnerResponse = {
  mechanic: CBRMechanicPartner[];
};

export type CBRMechanicCollectionMethodPartner = {
  mechanic: CBRMechanicPartner;
};

export type CBRMechanicCollectionMethodPartnerResponse = {
  mechanic_collection_method: CBRMechanicCollectionMethodPartner[];
};

export type CBRPartnerResponse = {
  merchant: CBRPartner[];
};

export enum IMAGE_TYPE {
  Logo = 'logo',
  Image = 'image',
  Other = 'other',
}

export type PartnerFilterQuery = {
  market: Market;
  b2bToken: B2BToken;
  collectionMechanics?: COLLECTION_MECHANIC[];
  categories?: string[];
  externalReferences?: string[];
  merchantName?: string;
  merchantIds?: number[];
  isSpecialOffers?: boolean;
};

export type PartnerQueryFindOptions = {
  sort?: SortTypes;
  order?: OrderTypes;
  currentPage?: number;
  pageSize?: number;
};

export type PartnerQueryArgument = {
  slugs?: readonly string[];
  collectionMechanics?: string[];
  categories?: string[];
  opCoId?: OPCO_ID;
  countryCode?: string;
  externalReferences?: string[];
  merchantName?: string;
  merchantIds?: number[];
  isSpecialOffers?: boolean;
};

export enum PARTNER_METRICS {
  PARTNER_DID_NOT_COMPLETE_LOADING = 'partner_load.failed',
  PARTNER_GENERIC_ERROR = 'partner.generic_error',
}
