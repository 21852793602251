import { GetSession } from '@auth0/nextjs-auth0';
import {
  GetSessionParameters,
  GetCustomSessionResponse,
  AUDIENCES,
  GetAccessTokenFromSessionParameters,
  GetAccessTokenParameters,
} from './auth0.types';
import { getAuth0Server } from './auth0.server-provider';
import logger from '../../utils/logger';
import { formatAudienceTokenData, refreshAudienceToken } from './auth0.utils';
import Market from '../../models/market';

export const getSession = ({
  market,
  requestResponse,
}: GetSessionParameters): Promise<GetCustomSessionResponse> =>
  getAuth0Server(market).getSession(...requestResponse);

export const getAccessTokenFromSession = ({
  audience = AUDIENCES.API_AVIOS,
  session,
}: GetAccessTokenFromSessionParameters) => {
  if (!session?.audiences?.[audience]) {
    if (session)
      logger.warn(
        'Token for audience is missing, defaulting to current session.',
        {
          audience,
          audiencesInSession: Object.keys(session?.audiences ?? {}),
        },
      );

    return session?.accessToken;
  }

  return formatAudienceTokenData(session.audiences[audience]).accessToken;
};

export const getAccessToken = async (argument: GetAccessTokenParameters) =>
  getAccessTokenFromSession({
    session: await getSession(argument),
    ...argument,
  });

export const refreshOutdatedTokens = async (
  market: Market,
  requestResponse: Exclude<Parameters<GetSession>, []>,
  audiencesToRefresh = Object.values(AUDIENCES),
) => {
  for (const audience of audiencesToRefresh) {
    // see bellow for why this rule is disabled
    // eslint-disable-next-line no-await-in-loop
    const session = await getSession({ market, requestResponse });
    if (session?.audiences?.[audience]) {
      const formattedTokenData = formatAudienceTokenData(
        session.audiences[audience],
      );

      if (
        formattedTokenData.expiresAtInMilliseconds &&
        formattedTokenData.refreshToken &&
        formattedTokenData.expiresAtInMilliseconds - 60_000 < Date.now()
      ) {
        logger.info('Outdated session detected. Refreshing.', {
          audience,
        });

        // we want to do this operation in a sequence and not concurrently
        // each refresh will change the same redis document which hold the user session
        // if we modify it concurrently, we can introduce inconsistencies into the user's session
        // eslint-disable-next-line no-await-in-loop
        await refreshAudienceToken({
          audienceToRefresh: audience,
          session,
          market,
          requestResponse,
        });
      }
    } else {
      logger.info(
        'skipping refresh of token due to missing session or audience data',
        {
          session: Boolean(session),
          audienceData: Boolean(session?.audiences?.[audience]),
        },
      );
    }
  }

  return getSession({ market, requestResponse });
};
