import Button from '@/components/inputs/button';
import {
  SIZE,
  VARIANT as ButtonVariant,
} from '@/components/inputs/button/button.types';
import Icon from '@/components/data-display/icon';
import { useUser } from '@/providers/auth0';
import { VARIANT as IconVariant } from '@/components/data-display/icon/icon.types';
import trackEvent from '@/utils/track-event/track-event';
import { EVENT_NAME, USER_ACTION } from '@/utils/track-event/track-event.types';
import { getMembershipId } from '@/utils/extract-user-data';
import { formatNumber } from '@/utils/format-number';
import { toggleModal } from '@/components/utilities/modal';
import { DATA_IDS } from '@/types';
import { MemberDetailsProperties } from './member-details.types';
import styles from './member-details.module.scss';

const MemberDetails = ({
  isVisible,
  handleClose,
  membershipNumberLabel,
  totalAviosLabel,
  aviosBalance,
  missingAviosCtaLabel,
  missingAviosCtaUrl,
  myAccountCtaLabel,
  myAccountCtaUrl,
  logoutCtaUrl,
  logoutCtaLabel,
  closeDetailsButtonTitle,
  showQrCodeLabel,
  qrCodeEnabled,
}: MemberDetailsProperties) => {
  const { user } = useUser();
  const memberDetailsCtaClass = 'member-details__cta';

  if (!isVisible) return null;
  return (
    <section className={styles['member-details']}>
      <div className={styles['member-details__info']}>
        <div>
          <h2>{membershipNumberLabel}</h2>
          <p>{user && getMembershipId(user)}</p>
        </div>

        <div>
          <h2>{totalAviosLabel}</h2>
          <p>
            {formatNumber(aviosBalance)}
            <Icon
              variant={IconVariant.AviosPlectrum}
              className={styles['member-details__avios-icon']}
            />
          </p>
        </div>
      </div>
      <Button
        trailingIcon={IconVariant.X}
        size={SIZE.Large}
        variant={ButtonVariant.Text}
        title={closeDetailsButtonTitle}
        aria-label={closeDetailsButtonTitle}
        onClick={handleClose}
        className={styles['member-details__close-btn']}
      />
      <ul className={styles['member-details__list']}>
        <li>
          <Button
            as="link"
            href={myAccountCtaUrl}
            size={SIZE.Small}
            variant={ButtonVariant.Text}
            leadingIcon={IconVariant.User}
            className={styles[memberDetailsCtaClass]}
            title={myAccountCtaLabel}
            dataId={DATA_IDS.MEMBER_DETAILS_MY_ACCOUNT}
          >
            {myAccountCtaLabel}
          </Button>
        </li>
        <li>
          <Button
            as="link"
            href={missingAviosCtaUrl}
            size={SIZE.Small}
            variant={ButtonVariant.Text}
            leadingIcon={IconVariant.AviosMissing}
            className={styles[memberDetailsCtaClass]}
            title={missingAviosCtaLabel}
            dataId={DATA_IDS.MEMBER_DETAILS_MISSING_AVIOS}
            onClick={() =>
              trackEvent({
                event: EVENT_NAME.ONLINE_LOGINBUTTON,
                user_action: USER_ACTION.LOG_OUT,
              })
            }
          >
            {missingAviosCtaLabel}
          </Button>
        </li>
        {qrCodeEnabled && (
          <li>
            <Button
              as="link"
              size={SIZE.Small}
              variant={ButtonVariant.Text}
              leadingIcon={IconVariant.Qrcode}
              className={styles[memberDetailsCtaClass]}
              title={showQrCodeLabel}
              onClick={() => toggleModal('show-qr-code')}
              dataId={DATA_IDS.MEMBER_DETAILS_QR_CODE}
            >
              {showQrCodeLabel}
            </Button>
          </li>
        )}
        <li>
          <Button
            as="link"
            href={logoutCtaUrl}
            size={SIZE.Small}
            variant={ButtonVariant.Text}
            leadingIcon={IconVariant.Logout}
            className={styles[memberDetailsCtaClass]}
            title={logoutCtaLabel}
            onClick={() =>
              trackEvent({
                event: EVENT_NAME.ONLINE_LOGINBUTTON,
                user_action: USER_ACTION.LOG_OUT,
              })
            }
            dataId={DATA_IDS.HEADER_LOGO}
          >
            {logoutCtaLabel}
          </Button>
        </li>
      </ul>
    </section>
  );
};

export default MemberDetails;
