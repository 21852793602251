import { GetServerSidePropsContext } from 'next';
import { MODE_CODES } from '@channel/shared-components';
import getEnvironmentVariable from '@/utils/get-environment-variable';
import { featureTogglesHandler } from '@/utils/shared-handler/feature-toggle-handler';
import { VARIANT as IconVariant } from '../../components/data-display/icon/icon.types';
import {
  HeaderProperties,
  HeaderQueryResponse,
  MEMBER_DETAILS_CTAS,
} from './header.types';
import ContentfulProvider from '../../providers/contentful';
import { headerQuery } from './header.query';
import { buildUrlPath } from '../../utils/build-url-path';
import { formatMicrocopy } from '../../utils/format-microcopy';
import { getAccessTokenFromSession, getSession } from '../../providers/auth0';
import User from '../../models/user/user';
import { getMembershipId } from '../../utils/extract-user-data';
import { isQrCodeEnabled } from '../qr-code-modal/qr-code-modal.handler';
import {
  OPCO_ID_EXTERNAL_SERVICE,
  sharedComponentsLocalesMap,
  sharedComponentsOpcoMap,
} from '../../constants';
import { searchBarHandler } from '../search-bar';

export const headerHandler = async (
  context: GetServerSidePropsContext,
): Promise<HeaderProperties> => {
  const { req, res } = context;
  const { market } = req;

  const [session, featureToggleList] = await Promise.all([
    getSession({ market, requestResponse: [req, res] }),
    featureTogglesHandler(market),
  ]);

  let aviosBalance;
  if (session?.user) {
    try {
      const accessToken = getAccessTokenFromSession({
        session,
      });

      const membershipID = getMembershipId(session.user);
      const parameters = {
        auth0accessToken: String(accessToken),
        membershipId: String(membershipID),
        programId: OPCO_ID_EXTERNAL_SERVICE[market.opCoId],
      };
      const userBalance = await User.getAviosBalance(parameters);
      aviosBalance = userBalance?.accounts?.[0]?.balance?.amount ?? '-';
    } catch {
      aviosBalance = '-';
    }
  }

  const [contentfulResponse, searchBarProperties] = await Promise.all([
    new ContentfulProvider(market).makeContentfulRequest<HeaderQueryResponse>({
      query: headerQuery,
    }),
    searchBarHandler(context),
  ]);

  const {
    programLogo: logo,
    programLogoSmallScreen: smallScreenLogo,
    navigationCollection: { items: navItems },
    membershipDetails: {
      totalAviosLabel,
      membershipNumberLabel,
      missingAviosCta,
      myAccountCta,
      auth0LogoutCta,
      closeDetailsButtonTitle,
      ctaListCollection,
    },
  } = contentfulResponse.data.headerCollection.items[0];

  const qrCodeCta = ctaListCollection.items.find(
    cta => cta.developerRef === MEMBER_DETAILS_CTAS.SHOW_QR_CODE,
  );

  const microcopy = formatMicrocopy(
    contentfulResponse.data.microcopyCollection.items,
  );

  const channelsClubUrl = getEnvironmentVariable('CHANNELS_CLUB_BASE_URL');

  return {
    logo: {
      href: '/',
      src: logo?.url ?? null,
      alt: logo?.title ?? '',
    },
    smallLogo: {
      href: '/',
      src: smallScreenLogo?.url ?? null,
      alt: smallScreenLogo?.title ?? '',
    },
    loginLabel: microcopy?.login_login ?? null,
    membershipLabel: microcopy?.general_membershipdetailslabel ?? null,
    myAccount: {
      href: '/api/auth/login',
    },
    membershipDetails: {
      membershipNumberLabel,
      aviosBalance: String(aviosBalance),
      totalAviosLabel,
      missingAviosCtaLabel: missingAviosCta.label,
      missingAviosCtaUrl: missingAviosCta.url,
      myAccountCtaLabel: myAccountCta.label,
      myAccountCtaUrl: myAccountCta.url,
      logoutCtaLabel: auth0LogoutCta.label,
      logoutCtaUrl: auth0LogoutCta.url,
      closeDetailsButtonTitle,
      showQrCodeLabel: qrCodeCta?.label ?? '',
      qrCodeEnabled: isQrCodeEnabled(session, featureToggleList),
    },
    navigation: {
      static: [
        {
          destination: '/',
          icon: IconVariant.Home,
          title: microcopy?.general_homelabel ?? null,
        },
      ],
      scrollable: navItems.map(({ urlPath, label, isHighlighted }) => ({
        destination: buildUrlPath(urlPath),
        isHighlighted,
        children: label,
        title: label,
      })),
    },
    sharedHeaderProps: {
      mode: MODE_CODES.COLLECT,
      opco: sharedComponentsOpcoMap[market.opCoId],
      locale: sharedComponentsLocalesMap[market.locale],
      searchBar: searchBarProperties,
      channelsClubUrl,
    },
  };
};
