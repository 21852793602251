import { APPLICATION_ENVIRONMENT } from '@/middleware/application-environment/application-environment.types';
import { PROGRAM_ID } from '@/models/market/market.types';
import { GENERIC_VALUES } from '@/modules/track-clicks/track-clicks.types';
import {
  GA_PROGRAM_ID,
  GlobalEventObject,
  GlobalEventObjectParamters,
  ProgrammeIdGaMap,
} from './use-metrics.types';

const programmeIdGaMap: ProgrammeIdGaMap = {
  [PROGRAM_ID.AVIOS]: GA_PROGRAM_ID.AVIOS,
  [PROGRAM_ID.BAEC]: GA_PROGRAM_ID.BAEC,
  [PROGRAM_ID.VYC]: GA_PROGRAM_ID.VYC,
  [PROGRAM_ID.IBP]: GA_PROGRAM_ID.IBP,
  [PROGRAM_ID.EIC]: GA_PROGRAM_ID.EIC,
};

export const createGlobalEventObject = ({
  isLoggedIn,
  programData: { Iso: iso, programmeCode },
  membershipId,
  balance,
  applicationEnvironment,
}: GlobalEventObjectParamters): GlobalEventObject => ({
  isLoggedIn,
  Iso: iso,
  product: 'shopping', // we can't use process.env.DD_SERVICE because this code will run on the client side and DD_SERVICE is only available on server side
  programmeCode: programmeIdGaMap[programmeCode] ?? null,
  memberID: membershipId ?? null,
  balance: balance ?? null,
  domain_environment:
    applicationEnvironment === APPLICATION_ENVIRONMENT.Production
      ? GENERIC_VALUES.PROD
      : GENERIC_VALUES.NON_PROD,
  channel: null,
  partner_name: null,
  booking_currency: null,
  avios_from_booster: null,
  avios_from_subscription: null,
});
