import { Config } from './collinson.types';
import getEnvironmentVariable from '../../utils/get-environment-variable';
import { Market } from '../../models/market/market.types';

const getCollinsonConfig = (market: Market): Config => ({
  AUTH_HASH: getEnvironmentVariable('COLLINSON_AUTH_HASH', market),
  PARTNER_HASH: getEnvironmentVariable('COLLINSON_PARTNER_HASH', market),
  PARTNER_ID: getEnvironmentVariable('COLLINSON_PARTNER_ID', market),
  BASE_URL: getEnvironmentVariable('COLLINSON_BASE_URL', market),
  API_KEY: getEnvironmentVariable('COLLINSON_API_KEY', market),
  BASE_URL_AUTOCLAIM: getEnvironmentVariable(
    'COLLINSON_BASE_URL_AUTOCLAIM',
    market,
  ),
});

export default getCollinsonConfig;
