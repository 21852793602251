export const downloadAppPromptQuery = `query DownloadAppPromptQuery($contentfulOpCoId: String!, $countryId: String!){
    downloadAppPromptCollection(
      where: {programId: $contentfulOpCoId, countryId: $countryId}
    ) {
      items {
        title
        description
        appIcon {
          url
          title
        }
        googlePlayLink
        appStoreLink
        primaryCtaLabel
        secondaryCtaLabel
      }
    }
  }`;
