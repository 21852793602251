import Button from '@/components/inputs/button';
import { VARIANT } from '@/components/inputs/button/button.types';
import { EVENT_NAME } from '@/utils/track-event/track-event.types';
import { useMemo } from 'react';
import Modal, { closeModal } from '@/components/utilities/modal';
import useBasePath from '@/hooks/use-base-path';
import { footerLocaleConfig } from '../footer/footer.utils';
import styles from './locale-modal.module.scss';
import { LocaleModalProperties } from './locale-modal.types';

const LocaleModal = ({
  label,
  onCloseFunction,
  title,
  description,
  availableLocales,
  currentLocale,
  footerLabelsCollection,
}: LocaleModalProperties) => {
  const { setLocale } = useBasePath();
  const handleCloseModal = (locale: string) => {
    closeModal(label);
    setLocale(locale, EVENT_NAME.SETUP_LOCALE);

    onCloseFunction();
  };

  const selectorValues = useMemo(
    () =>
      availableLocales.map(locale => {
        const { value, label: labelLocale } = footerLocaleConfig(
          footerLabelsCollection,
          locale,
        );

        return {
          isDefault: currentLocale === value,
          value,
          label: labelLocale,
        };
      }),
    [availableLocales, currentLocale, footerLabelsCollection],
  );

  return (
    <Modal label={label} disableCloseButton onCloseFunction={onCloseFunction}>
      <div className={styles['locale-modal__wrapper']}>
        <h3 className={styles['locale-modal__title']}>{title}</h3>
        <p className={styles['locale-modal__description']}>{description}</p>

        <ul className={styles['locale-modal__locales-list']}>
          {selectorValues.map(({ label: valueLabel, value }) => {
            const labelTitle =
              typeof valueLabel === 'string'
                ? valueLabel
                : (valueLabel as JSX.Element)?.props?.language;

            return (
              <li key={value} className={styles['locale-modal__locale']}>
                <Button
                  variant={VARIANT.Outlined}
                  title={labelTitle}
                  onClick={() => handleCloseModal(value)}
                  className={styles['locale-modal__locale__button']}
                  data-id={`button-locale-${value}`}
                >
                  {valueLabel}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </Modal>
  );
};

export default LocaleModal;
