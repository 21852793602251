import { createServerSideContext } from './server-side-context';
import { ServerSideContext } from './server-side-context.types';

let globalServerSideContextInstance: ServerSideContext | undefined;

export const globalServerSideContext = () => {
  if (!globalServerSideContextInstance) {
    globalServerSideContextInstance = createServerSideContext();
  }

  return globalServerSideContextInstance;
};
