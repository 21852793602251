export const getUserFavouritesQuery = `query GetUserFavourites(
	$memberId: bigint!
	$opCoId: bpchar!
	$countryCode: bpchar!
) {
	member(
		where: {
			program_member_reference: { _eq: $memberId }
			program: {
				name: { _eq: $opCoId }
				mechanics: {
					merchant: { country: { country_code: { _eq: $countryCode } } }
				}
			}
		}
	) {
		member_id
		program_member_reference
		favourites(
			order_by: { updated_at: desc }
			where: {
				mechanic: {
					program: { name: { _eq: $opCoId } }
					merchant: { country: { country_code: { _eq: $countryCode } } }
				}
			}
		) {
			favourite_id
			updated_at
			mechanic {
				mechanic_id
				mechanic_collection_methods(
					where: { is_active: { _eq: true } }
					order_by: { collection_method_id: asc }
				) {
					rate
					offers {
						rate
						was_rate
					}
				}
				merchant {
					name
					slug
					logo_url
				}
			}
		}
	}
}`;

export const getUserFavouriteQuery = `query GetUserFavorite($memberId: bigint!, $mechanicId: bigint!) {
  favourite(where: { member:{program_member_reference: {_eq: $memberId}}, mechanic_id: { _eq: $mechanicId } } ) {
    member_id
		favourite_id
  }
}`;

export const insertFavouriteQuery = `mutation InsertFavourite($memberId: bigint!, $mechanicId: bigint!,$programId: Int!,) {
  insert_member(objects: {
    favourites: { data: {mechanic_id: $mechanicId } },
    program_id: $programId,
    program_member_reference: $memberId
  }, on_conflict: {
    constraint: member_program_member_reference_key, update_columns: program_member_reference
  }) {
		returning {
			member_id
			program_member_reference
			favourites {
				mechanic_id
				favourite_id
			}
		}
  }
}
`;

export const deleteFavouriteQuery = `mutation DeleteFavourite($favouriteId: Int!, $memberId: bigint!) {
  delete_favourite( where: { favourite_id: { _eq: $favouriteId }, member: { program_member_reference: { _eq: $memberId } }
  }) {
		returning {
			favourite_id
		}
	}
}`;
