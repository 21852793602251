import DataLoader from 'dataloader';
// eslint-disable-next-line unicorn/prefer-node-protocol
import crypto from 'crypto';
import { Market } from '../../models/market/market.types';

class MarketDataLoader<T, U = string> {
  private dataLoaders: Map<string, DataLoader<U, T | undefined>> = new Map();

  constructor(
    private batcher: (
      market: Market,
      ...properties: string[]
    ) => (keys: readonly U[]) => Promise<Readonly<Array<T | undefined>>>,
  ) {}

  protected static createKey(market: Market, ...properties: string[]) {
    const keyString = [market.opCoId, market.countryCode, ...properties].join(
      '',
    );

    return crypto.createHash('md5').update(keyString).digest('hex');
  }

  public load(key: U, market: Market, ...properties: string[]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.getMarketDataLoader(market, ...properties)!.load(key);
  }

  public loadMany(keys: U[], market: Market, ...properties: string[]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.getMarketDataLoader(market, ...properties)!.loadMany(keys);
  }

  private getMarketDataLoader(market: Market, ...properties: string[]) {
    const key = MarketDataLoader.createKey(market, ...properties);
    if (!this.dataLoaders.get(key)) {
      this.dataLoaders.set(
        key,
        new DataLoader<U, T | undefined>(
          this.batcher(market, ...properties),
          // FIXME:  Ideally Redis would be used here for long lived caching and custom TTL
          { cache: false },
        ),
      );
    }

    return this.dataLoaders.get(key);
  }
}

export default MarketDataLoader;
