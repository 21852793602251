import {
  EventObjectProperties,
  EVENT_NAME,
} from '@/utils/track-event/track-event.types';
import { PROGRAM_ID } from '../../models/market/market.types';

export enum USER_CLICK_DOM_ELEMENT_LOOKUP {
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
  SECTION = 'SECTION',
  A = 'A',
  BUTTON = 'BUTTON',
}

export interface ClickObject extends EventObjectProperties {
  balance: GENERIC_VALUES | string | null;
  booking_currency?: string;
  currency?: string;
  custom_timestamp?: number;
  domain_environment?: GENERIC_VALUES;
  event: EVENT_NAME;
  isLoggedIn?: boolean;
  language?: string;
  memberID: GENERIC_VALUES | string | null;
  menu_level?: number;
  nav_action: string;
  nav_type: string;
  page_location?: string;
  page_referer?: GENERIC_VALUES;
  pagePath?: string;
  pageType?: string;
  programmeCode?: PROGRAM_ID;
  site_language?: string;
  urlSlug: string;
}

export enum GENERIC_VALUES {
  ERROR = 'error',
  NOT_APPLICABLE = 'not applicable',
  PROD = 'prod',
  NON_PROD = 'non-prod',
}

export enum COUNTRY_CURRENCY {
  'GB' = 'gbp',
  'US' = 'usd',
  'ES' = 'euro',
  'IE' = 'euro',
}

export enum ROUTES_DESCRIPTIONS {
  '/' = 'home',
  '/retailers' = 'retailers',
  '/collect-on-card' = 'collect in store',
  '/my-account/missing-avios' = 'missing avios',
  '/my-account' = 'my account',
  '/collection-reminder' = 'collection-reminder',
  '/cookies' = 'cookies',
  '/faq' = 'faq',
  '/offers' = 'offers',
  '/privacy' = 'privacy',
}
