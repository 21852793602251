import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import { ToogleFeatureType } from '@/hooks/use-handle-favourites/use-handle-favourites.types';
import { ReactNode } from 'react';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';

export type HeroCardProperties = {
  image?: { smallImage: HeroImage; largeImage?: HeroImage };
  description?: string;
  descriptionColor?: string;
  ctaLabel?: string;
  ctaUrl?: string;
  ctaVariant?: BUTTON_VARIANT;
  ctaTrailingIcon?: ICON_VARIANT;
  ctaClassName?: string;
  partner?: HeroCardPartner;
  partnerLink?: string;
  favourite?: HeroCardFavourite;
};

export type HeroCardFavourite = {
  favouriteId?: number;
  showFavourite?: boolean;
  isFavourite?: boolean;
  toggleFavourite?: (
    value: boolean,
    partnerId?: number,
    favouriteId?: number,
  ) => Promise<ToogleFeatureType> | void;
  favouritesLabels?: { addToFavourites: string; removeFromFavourites: string };
};

type HeroImage = {
  title: string;
  url: string;
  description: string;
  height: number;
  width: number;
};

export type HeroCardPartner = {
  id?: number;
  mechanicId?: number;
  name: string;
  imageSrc: string;
  imageAlt: string;
  rate: string;
  wasRate: string;
  logoSize: LOGO_SIZE;
  textSize: TEXT_SIZE;
  isSpeedyAwarding?: boolean;
  speedyAwardText?: string;
};

export enum LOGO_SIZE {
  small = '--small',
  medium = '--medium',
  large = '--large',
}

export enum TEXT_SIZE {
  small = '--small',
  medium = '--medium',
  large = '--large',
}

export type ContainerTypes = {
  children: ReactNode;
  link?: string;
  name?: string;
};
