export type Config = {
  BASE_URL: string;
  API_KEY: string;
};

type Links = {
  rel: string;
  href: string;
};

type AccountDetails = {
  accountType: string;
  balance: {
    amount: number;
    currency: string;
  };
  accountStatus?: string;
  outstandingLoan?: {
    amount: number;
    currency: string;
  };
  links?: Links[];
};

export type AccountDetailsResponse = {
  totalRecords: number;
  accounts: AccountDetails[];
  links: Links[];
};

export enum PROGRAMME_IDENTIFIER_GLP {
  baec = 'BAEC',
  eic = 'AERC',
  ibp = 'IBP',
  vyc = 'ATRP',
  avios = 'BAEC',
}
