import type { ButtonProperties } from '@/components/inputs/button/button.types';

export enum VARIANT {
  Primary = 'selector--primary',
  Secondary = 'selector--secondary',
}

export type SelectorValue = {
  label: string | JSX.Element;
  value: string;
  isDefault?: boolean;
};

export type SelectorProperties = {
  values: SelectorValue[];
  variantSelect?: VARIANT;
  onValueChange: (x: string) => unknown;
  buttonClassName?: string;
  listClassName?: string;
  listItemClassName?: string;
  iconItemClassName?: string;
  prefixLabel?: string;
  label?: string;
  errorMessage?: string;
  errorLinkLabel?: string;
  errorLinkHref?: string;
  showError?: boolean;
} & ButtonProperties;
