import type { ChannelConfiguration } from '../../channels/types';

export type Market = {
  opCoId: OPCO_ID;
  countryId: COUNTRY_ID;
  countryCode: string;
  locale: LOCALE_ID;
  hostUrl: string;
  baseUrl?: string;
  availableLocales: ChannelConfiguration['locales'];
  pageTitle: ChannelConfiguration['pageTitle'];
  gtmId: ChannelConfiguration['gtmId'];
  programId: ChannelConfiguration['programId'];
  oneTrustCookies: ChannelConfiguration['oneTrustCookies'];
  defaultLocale: ChannelConfiguration['defaultLocale'];
};

export enum OPCO_ID {
  BAEC = 'baec',
  VYC = 'vyc',
  IBP = 'ibp',
  EIC = 'eic',
  AVIOS = 'avios',
}

export enum PROGRAM_ID {
  AVIOS = 'avios',
  BAEC = 'ba',
  VYC = 'vueling',
  IBP = 'iberia',
  EIC = 'aerclub',
}

export enum COUNTRY_ID {
  US = '1',
  FR = '72',
  IE = '101',
  IT = '103',
  ES = '190',
  GB = '216',
}

export enum LOCALE_ID {
  EN_GB = 'en-GB',
  EN_US = 'en-US',
  EN_IE = 'en-IE',
  ES_ES = 'es-ES',
}

export const DESIGN_TOKENS_OPCO_ID = {
  [OPCO_ID.BAEC]: 'british-airways',
  [OPCO_ID.AVIOS]: 'avios',
  [OPCO_ID.VYC]: 'vueling',
  [OPCO_ID.IBP]: 'iberia',
  [OPCO_ID.EIC]: 'aer-lingus',
};

export const getCBRProgramID: Record<OPCO_ID, number> = {
  [OPCO_ID.BAEC]: 1,
  [OPCO_ID.EIC]: 2,
  [OPCO_ID.VYC]: 3,
  [OPCO_ID.IBP]: 4,
  [OPCO_ID.AVIOS]: 1,
};
