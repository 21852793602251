import { MutableRefObject } from 'react';
import Swiper from 'swiper';

export enum VARIANT {
  Multi = 'multi',
  MultiPartners = 'multi-partners',
  TwoComponents = 'two-components',
  ThreeComponents = 'three-components',
  FourComponents = 'four-components',
  Single = 'single',
  Banners = 'banners',
  Buttons = 'buttons',
}
export type CarouselProperties = {
  variant: VARIANT;
  withScrollbar?: boolean;
  autoPlay?: boolean;
} & JSX.IntrinsicElements['div'];

export type CarouselReference = MutableRefObject<any>;

export enum MAX_SCREEN_SIZE {
  VALUE = 1272,
}

export interface SwiperExtension extends Swiper {
  snapGrid: never[];
  snapIndex: number;
}
