import classNames from 'classnames';
import { SectionProperties } from './section.types';
import styles from './section.module.scss';

const Section = ({
  id,
  backgroundColor,
  backgroundImage,
  children,
  isFullBleed,
  paddingTop,
  paddingBottom,
  ariaLabel,
  className = '',
}: SectionProperties) => {
  const sectionWrapperClasses = `${styles.section} ${
    isFullBleed ? styles['section--full-bleed'] : ''
  } ${className}`;

  return (
    <section
      id={id}
      className={sectionWrapperClasses}
      style={{
        backgroundColor: backgroundColor ?? '',
        backgroundImage: backgroundImage?.url
          ? `url(${backgroundImage.url})`
          : '',
      }}
      aria-label={ariaLabel}
    >
      <div
        className={classNames(styles.section__inner)}
        style={{
          paddingTop: paddingTop ?? 'revert-layer',
          paddingBottom: paddingBottom ?? 'revert-layer',
        }}
      >
        {children}
      </div>
    </section>
  );
};

export default Section;
