import classNames from 'classnames';
import { YoutubeEmbedProperties } from './youtube-embed.types';
import { getHeightClassName } from './youtube-embed.utils';
import styles from './youtube-embed.module.scss';

const YoutubeEmbed = ({
  youtubeID,
  autoPlay,
  title,
  className = '',
  videoHeight = '',
  dataId,
}: YoutubeEmbedProperties) => {
  const videoHeightClassName = getHeightClassName(videoHeight);
  return (
    <iframe
      className={classNames(
        styles['iframe-body'],
        className,
        styles[videoHeightClassName],
      )}
      src={`https://www.youtube-nocookie.com/embed/${youtubeID}?rel=0&${
        autoPlay ? 'autoplay=1&mute=1' : ''
      }`}
      title={title ?? ''}
      allowFullScreen
      data-id={dataId}
    />
  );
};

export default YoutubeEmbed;
