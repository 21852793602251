import { CustomLink as Link } from '@/components/custom-link';
import { CustomImage as Image } from '@/components/data-display/image';
import Button from '@/components/inputs/button';
import { SIZE, VARIANT } from '@/components/inputs/button/button.types';
import Selector from '@/components/selector';
import { useClientSideMarketContext } from '@/context/client-side-market/market-context';
import { useFeatureTogglesContext } from '@/context/feature-toggles/feature-toggles-context';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import useBasePath from '@/hooks/use-base-path';
import { DATA_IDS } from '@/types';
import { isAviosOpco } from '@/utils/opco-utils';
import trackEvent from '@/utils/track-event/track-event';
import { EVENT_NAME, NAV_ELEMENT } from '@/utils/track-event/track-event.types';
import { PRODUCT_CODES } from '@channel/shared-components';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import styles from './footer.module.scss';
import type { FooterProperties } from './footer.types';
import { footerLocaleConfig } from './footer.utils';

const SharedFooter = dynamic(
  async () => {
    const sharedComponentsModules = await import('@channel/shared-components');
    return sharedComponentsModules.Footer;
  },
  {
    ssr: false,
  },
);

const AviosFooter = dynamic(
  async () => {
    const shared = await import('@channel/shared-header-footer');
    return shared.Footer;
  },
  {
    ssr: false,
  },
);

const Footer = ({
  navigation,
  aviosLogo,
  aviosText,
  copyright,
  aviosLink,
  availableLocales,
  currentLocale,
  footerLabelsCollection,
  sharedFooterProps,
}: FooterProperties) => {
  const router = useRouter();

  const { setLocale } = useBasePath();
  const featuresToggles = useFeatureTogglesContext();
  const { opCoId } = useClientSideMarketContext();
  const newFooterEnabled = featuresToggles.includes(
    FEATURE_TOGGLE_LIST.TEMP_SHARED_FOOTER,
  );
  const selectorValues = useMemo(
    () =>
      availableLocales.map(locale => {
        const { value, label } = footerLocaleConfig(
          footerLabelsCollection,
          locale,
        );

        return {
          isDefault: currentLocale === value,
          value,
          label,
        };
      }),
    [availableLocales, currentLocale, footerLabelsCollection],
  );

  if (isAviosOpco(opCoId)) return <AviosFooter />;

  if (newFooterEnabled) {
    return (
      <SharedFooter
        key={router.asPath}
        {...sharedFooterProps}
        currentPath={router.asPath}
        markets={{
          ...sharedFooterProps.markets,
          onChange: (value: string) => setLocale(value),
          value: currentLocale,
        }}
        routerOptions={{
          as: Link,
          usesTo: false,
          product: PRODUCT_CODES.SHOPPING,
        }}
        baseUrl={sharedFooterProps.channelsClubUrl}
      />
    );
  }

  return (
    <div className={styles.footer}>
      <section className={styles.footer__top}>
        <div className={styles['footer__top-inner']}>
          <div className={styles['footer__top-country-selector']}>
            <Selector
              values={selectorValues}
              onValueChange={value => setLocale(value)}
              buttonClassName={styles['footer__top-country-selector-button']}
              listClassName={styles['footer__top-country-selector-list']}
              iconItemClassName={styles['footer__top-country-selector-icon']}
              listItemClassName={
                styles['footer__top-country-selector-list-item']
              }
              data-id={DATA_IDS.COUNTRY_SELECTOR}
            />
          </div>

          <div
            className={styles['footer__top-navigation']}
            data-id={DATA_IDS.FOOTER_LINKS}
          >
            {navigation.map(({ destination, children }) => (
              <Button
                as="link"
                variant={VARIANT.TextBlock}
                size={SIZE.Small}
                href={destination}
                key={destination}
                className={styles['footer__top-navigation-link']}
                title={children}
                onClick={() =>
                  trackEvent({
                    event: EVENT_NAME.NAVIGATION,
                    nav_element: NAV_ELEMENT.FOOTER_NAV,
                  })
                }
              >
                {children}
              </Button>
            ))}
          </div>
        </div>
      </section>

      <hr className={styles.footer__divider} />

      <div className={styles['footer__sub-container']}>
        <section className={styles.footer__bottom}>
          <Link
            href={aviosLink}
            className={styles['footer__bottom-link']}
            onClick={() =>
              trackEvent({
                event: EVENT_NAME.NAVIGATION,
                nav_element: NAV_ELEMENT.FOOTER_LINK,
              })
            }
          >
            <div className={styles['footer__bottom-link-inner']}>
              <Image
                width={40}
                height={40}
                src={aviosLogo.src}
                alt={aviosLogo.alt}
                className={styles['footer__bottom-link-image']}
              />

              <span className={styles['footer__bottom-link-text']}>
                {aviosText}
              </span>
            </div>
          </Link>
          <h3 className={styles['footer__bottom-copyright']}>{copyright}</h3>
        </section>
      </div>
    </div>
  );
};

export default Footer;
