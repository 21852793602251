import { TEMPORARY_OPCO_ID_ENV_VAR_PREFIX_MAP } from '../../constants';
import EnvironmentVariableError from '../../errors/environment-variable-error';
import { Market } from '../../models/market/market.types';

function getEnvironmentVariable<T = string>(key: string, market?: Market): T {
  const opCoIdPrefix = market?.opCoId
    ? `${TEMPORARY_OPCO_ID_ENV_VAR_PREFIX_MAP[
        market.opCoId
      ].toLocaleUpperCase()}_`
    : '';
  const variableName = `${opCoIdPrefix}${key}`;
  const value = process.env[variableName];

  if (!value) {
    throw new EnvironmentVariableError(variableName);
  }

  if (['true', 'false'].includes(value.toLowerCase()))
    return (value.toLowerCase() === 'true') as boolean as T;

  return value as unknown as T;
}

export default getEnvironmentVariable;
