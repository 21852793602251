import { Market } from '../../models/market/market.types';
import Fetch from '../../utils/fetch';
import { Response } from '../../utils/fetch/fetch.types';
import getContentfulConfig, { CONTENTFUL_OPCO_ID } from './contentful.config';
import { Query, Config } from './contentful.types';

class ContentfulProvider extends Fetch {
  private config: Config;

  constructor(private market: Market) {
    const contentfulConfig = getContentfulConfig();
    super(contentfulConfig.BASE_URL);

    this.config = contentfulConfig;
    this.setHeaders();
  }

  private setHeaders() {
    this.headers = {
      Authorization: `Bearer ${this.config.ACCESS_TOKEN}`,
      'content-type': 'application/json',
    };
  }

  async makeContentfulRequest<T = Record<string, any>>({
    query,
    variables,
  }: Query) {
    return this.post<Response<T>>('', {
      bodyJSON: {
        query,
        variables: {
          ...variables,
          contentfulOpCoId: CONTENTFUL_OPCO_ID[this.market.opCoId],
          countryId: this.market.countryId,
          locale: this.market.locale,
        },
      },
    });
  }
}

export default ContentfulProvider;
