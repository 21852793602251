/**
 * Returns internal or external path url
 *
 * @param {String} path url path
 * @returns {String} Returns a path url
 */

export const buildUrlPath = (path: string): string => {
  // add https if the address has only www.
  if (!/^(http|https)/.test(path) && /^(www)/.test(path)) {
    return `https://${path}`;
  }
  if (/^(http|https)/.test(path) || /\//.test(path)) {
    return path;
  }
  return `/${path}`;
};
