import {
  NotificationsProperties,
  NOTIFICATIONS,
  NotificationsResponse,
} from './notifications.types';
import { Market } from '../../models/market/market.types';
import ContentfulProvider from '../../providers/contentful';
import { notificationsQuery } from './notifications.query';
import { formatMicrocopy } from '../../utils/format-microcopy';

export const notificationsHandler = async (
  market: Market,
): Promise<NotificationsProperties | null> => {
  const contentfulRequest = new ContentfulProvider(
    market,
  ).makeContentfulRequest<NotificationsResponse>({
    query: notificationsQuery,
  });

  const contentfulResponse = await contentfulRequest;

  const microcopies = formatMicrocopy(
    contentfulResponse.data.microcopyCollection.items,
  );

  const result = Object.entries(NOTIFICATIONS).map(([id, value]) => {
    const lowercaseId = id.toLowerCase();
    return {
      id: value,
      title: microcopies[`${lowercaseId}_title`] || '',
      message: microcopies[`${lowercaseId}_message`] || '',
    };
  });

  return {
    closeLabel: microcopies?.general_closelabel ?? '',
    notificationMicrocopy: result,
  };
};
