import { ImageLoader, ImageLoaderProps } from 'next/image';
import { AVIOS_PREFIX } from '@/constants';

const contentfulLoader: ImageLoader = ({
  src,
  width,
  quality,
}: ImageLoaderProps) => {
  return `${AVIOS_PREFIX}/_next/image/?url=${encodeURI(src)}&w=${width}&q=${
    quality || 70
  }`;
};

export default contentfulLoader;
