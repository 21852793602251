import NextImage from '@/components/data-display/image';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import Modal from '@/components/utilities/modal';
import styles from './qr-code-modal.module.scss';
import { QrCodeModalProperties } from './qr-code-modal.types';

const QrCodeModal = ({
  logo,
  qrSvgData: { hasErrors, svg },
  membershipID,
  microcopy: {
    showQrCodeTitle,
    membershipNoLabel,
    errorTitle,
    errorDetails,
    closeLabel,
  },
}: QrCodeModalProperties) => {
  return (
    <Modal label="show-qr-code" closeOnOutsideClick closeLabel={closeLabel}>
      <div className={styles['qrcode-section']}>
        {logo.src && (
          <NextImage
            src={logo.src}
            alt={logo.alt}
            width={200}
            height={48}
            className={styles['qrcode-section__logo']}
          />
        )}
        <section>
          {hasErrors || !svg ? (
            <>
              <h2 className={styles.error__title}>{errorTitle}</h2>
              <Icon
                className={styles.error__icon}
                variant={ICON_VARIANT.Frown}
              />
              <p className={styles.error__description}>{errorDetails}</p>
            </>
          ) : (
            <>
              <div>
                {showQrCodeTitle && <p>{showQrCodeTitle}</p>}
                <div
                  className={styles['qrcode-section__qrcode']}
                  dangerouslySetInnerHTML={{ __html: svg }}
                />
              </div>
              <div>
                {membershipNoLabel && <p>{membershipNoLabel}</p>}
                <h2 className={styles['qrcode-section__membership-id']}>
                  {membershipID}
                </h2>
              </div>
            </>
          )}
        </section>
      </div>
    </Modal>
  );
};

export default QrCodeModal;
