export type PartnerTileProperties = {
  partnerId?: number;
  mechanicId?: number;
  className?: string;
  speedyClassName?: string;
  isSpeedyAwarding?: boolean;
  speedyAwardText?: string;
  rate?: string | null;
  name?: string;
  wasRate?: string | null;
  subField1?: string;
  subField2?: string;
  logo: LOGO;
  slug?: string;
  destinationUrl?: string;
  variant?: VARIANT;
  textSize?: TEXT_SIZE;
  separator?: boolean;
  showWasRate?: boolean;
};

export enum VARIANT {
  Default = 'partner-tile--default',
  Inline = 'partner-tile--inline',
}

export type LOGO = {
  src: string;
  alt: string;
  logoSize?: LOGO_SIZE;
};

export enum LOGO_SIZE {
  extraSmall = '--extra-small',
  small = '--small',
  medium = '--medium',
  large = '--large',
}

export enum TEXT_SIZE {
  extraSmall = '--extra-small',
  small = '--small',
  medium = '--medium',
  large = '--large',
}
