import { Market } from '../../models/market/market.types';
import logger from '../../utils/logger';
import {
  generateHMAC,
  getCollinsonExternalIdFromMembershipNumber,
  serializedData,
} from './collinson.utils';
import Fetch from '../../utils/fetch';
import getCollinsonConfig from './collinson.config';
import {
  Config,
  CreateNewClickRequest,
  CreateNewClickResponse,
  GetClicksResponse,
  GetUserResponse,
  GetPurchaseHistoryResponse,
  GetParamatersRequest,
  SHOP_NOW_STATUS,
  GetNoAutoClaimMerchantsResponse,
} from './collinson.types';
import metrics from 'datadog-metrics';
import { BannersCarouselCollinsonResponse } from '@/utils/contentful-data-formatters/contentful-data-formatters.types';

class CollinsonProvider extends Fetch {
  private config: Config;

  constructor(private market: Market) {
    const selectedConfig = getCollinsonConfig(market);

    super(selectedConfig.BASE_URL);

    this.config = selectedConfig;
  }

  private getHeaders(method: string, path: string, data?: any) {
    const { AUTH_HASH, PARTNER_HASH, PARTNER_ID } = this.config;
    const now = Math.floor(Date.now() / 1000);
    const hmac = generateHMAC(now, method, path, PARTNER_HASH, data);
    const signature = Buffer.from(`${PARTNER_ID}:${hmac}:${now}`).toString(
      'base64',
    );

    return {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-HMAC': signature,
      Authorization: `Basic ${AUTH_HASH}`,
    };
  }

  async getUser({
    membershipId,
    first_name,
    last_name,
    email,
  }: {
    membershipId: string;
    first_name: string;
    last_name: string;
    email: string;
  }) {
    const external_id = getCollinsonExternalIdFromMembershipNumber(
      membershipId,
      this.market,
    );

    const copartner = this.market.programId;
    const payload = { external_id, first_name, last_name, email, copartner };
    this.headers = this.getHeaders('POST', '/users', payload);

    const response = await this.post<GetUserResponse>(`/users`, {
      plainBody: payload,
    });

    return response;
  }

  async getBanners<T = Record<string, any>>() {
    this.headers = this.getHeaders('GET', `/banners`);
    const lang = this.market.locale.split('-')[0];
    const parameters = {
      country_id: this.market.countryId,
      page_id: '0',
      lang,
    };

    const response = await this.get<BannersCarouselCollinsonResponse>(
      `/banners`,
      {
        params: parameters,
      },
    );

    return response;
  }

  async createNewClick(
    collinsonUserId: string,
    payload: CreateNewClickRequest,
  ) {
    const { programId } = this.market;
    const thePayload = serializedData(payload);
    const theUrl = `/users/${collinsonUserId}/clicks`;
    this.headers = this.getHeaders('POST', theUrl, thePayload);

    const requestWithRetry = async () => {
      const response = await this.post<CreateNewClickResponse>(theUrl, {
        plainBody: thePayload,
      });

      if (!response.hasError) {
        metrics.increment(SHOP_NOW_STATUS.SUCCESS, 1, [
          `programId:${programId}`,
        ]);
        logger.info(SHOP_NOW_STATUS.SUCCESS, { programId });
        return response;
      }
      metrics.increment(SHOP_NOW_STATUS.FAILED, 1, [`programId:${programId}`]);
      logger.error(SHOP_NOW_STATUS.FAILED, {
        programId,
        error: { message: response.message },
      });
      await new Promise(r => setTimeout(r, 4000));

      const retryResponse = await this.post<CreateNewClickResponse>(theUrl, {
        plainBody: thePayload,
      });

      if (!retryResponse.hasError) {
        metrics.increment(SHOP_NOW_STATUS.RETRY_SUCCESS, 1, [
          `programId:${programId}`,
        ]);
        logger.info(SHOP_NOW_STATUS.RETRY_SUCCESS, { programId });
        return retryResponse;
      }

      metrics.increment(SHOP_NOW_STATUS.RETRY_FAILED, 1, [
        `programId:${programId}`,
      ]);
      logger.error(SHOP_NOW_STATUS.RETRY_FAILED, {
        programId,
        error: { message: retryResponse.message },
      });
      return retryResponse;
    };

    return requestWithRetry();
  }

  async getClicks(
    collinsonUserId: string,
    parameters: GetParamatersRequest = {},
  ) {
    const URL = `/users/${collinsonUserId}/clicks`;
    this.headers = this.getHeaders('GET', URL);
    const visitHistory = await this.get<GetClicksResponse>(URL, {
      params: parameters,
    });

    return {
      data: visitHistory.hasError ? [] : visitHistory.data,
      hasError: visitHistory.hasError,
      message: visitHistory?.message ?? '',
    };
  }

  async getTotalClicksCount(collinsonUserId: string) {
    const URL = `/users/${collinsonUserId}/clicks`;
    this.headers = this.getHeaders('GET', URL);
    const visitHistory = await this.get<GetClicksResponse>(URL);

    return visitHistory?.stats?.total;
  }

  async getPurchaseHistory(
    collinsonUserId: string,
    parameters: GetParamatersRequest = {},
  ) {
    const URL = `/users/${collinsonUserId}/purchases`;
    this.headers = this.getHeaders('GET', URL);
    const response = await this.get<GetPurchaseHistoryResponse>(URL, {
      params: parameters,
    });

    return response;
  }

  async getTotalPurchases(collinsonUserId: string) {
    const URL = `/users/${collinsonUserId}/purchases`;
    this.headers = this.getHeaders('GET', URL);
    const response = await this.get<GetPurchaseHistoryResponse>(URL);

    return response?.stats?.total;
  }

  async getNoAutoClaimMerchants() {
    const URL = `/merchants`;
    this.headers = this.getHeaders('GET', URL);
    const lang = this.market.locale.split('-')[0];
    const parameters = {
      no_auto_claims: '1',
      lang,
    };

    const response = await this.get<GetNoAutoClaimMerchantsResponse>(URL, {
      params: parameters,
    });

    return {
      data: response.hasError ? [] : response.data,
    };
  }
}

export default CollinsonProvider;
