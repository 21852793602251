import classNames from 'classnames';
import styles from './icon.module.scss';
import type { IconProperties } from './icon.types';

const Icon = ({
  variant,
  className = '',
  withBackground = false,
  ...properties
}: IconProperties) => (
  <span
    aria-hidden
    aria-label="icon"
    className={classNames(styles.icon, styles[variant], className, {
      [styles.icon__background]: withBackground,
    })}
    role="img"
    {...properties}
  />
);

export default Icon;
