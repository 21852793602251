// TODO: remove this once the node: protocol is supported: https://github.com/webpack/webpack/issues/14166
// eslint-disable-next-line unicorn/prefer-node-protocol
import { createHash } from 'crypto';
import { tracer } from 'dd-trace';
// eslint-disable-next-line unicorn/prefer-node-protocol
import { AsyncLocalStorage } from 'async_hooks';
import {
  BaseServerSideContextStore,
  ServerSideContext,
} from './server-side-context.types';

export const buildBaseServerSideContextStore = <T>(
  initialData: T,
): T & BaseServerSideContextStore => ({
  methodCache: {},
  ...initialData,
});

const defaultCacheKeyGenerator = (...arguments_: unknown[]) =>
  createHash('sha256')
    .update(JSON.stringify(arguments_))
    .digest()
    .toString('hex');

export const createServerSideContext = <
  T extends BaseServerSideContextStore,
>(): ServerSideContext<T> => {
  let serverSideContext: AsyncLocalStorage<T> | undefined;

  // there's no async local storage on client side
  if (globalThis.window === undefined) {
    serverSideContext = new AsyncLocalStorage<T>();
  }

  const getStore = (): T =>
    serverSideContext?.getStore() ?? (buildBaseServerSideContextStore({}) as T);

  return {
    getStore,
    useContext: (function_, context) =>
      serverSideContext
        ? serverSideContext.run(
            context ?? (buildBaseServerSideContextStore({}) as T),
            function_,
          )
        : function_(),
    cacheMethod:
      (cacheKeyGenerator = defaultCacheKeyGenerator) =>
      (target, methodName, originalMethodProperties) => {
        const originalMethodBody = originalMethodProperties.value;

        // the `value` prop holds the actualy method body, we are replacing
        // the `value` prop with our own function that introduces caching
        // eslint-disable-next-line no-param-reassign
        originalMethodProperties.value = function wrappedMethod(
          ...arguments_: any[]
        ) {
          return tracer.trace(methodName, () => {
            const localStorage = getStore();

            const cacheKey = cacheKeyGenerator(arguments_);
            if (!localStorage.methodCache[methodName]?.[cacheKey])
              localStorage.methodCache[methodName] = {
                ...localStorage.methodCache[methodName],
                [cacheKey]: originalMethodBody.apply(this, arguments_),
              };

            return localStorage.methodCache[methodName][cacheKey];
          });
        };
      },
  };
};
