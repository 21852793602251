import { initAuth0 } from '@auth0/nextjs-auth0';
import { Market } from '../../models/market/market.types';
import { FIRST_AUDIENCE } from './auth0.constants';
import {
  getAuth0AuthorizationParametersForAudience,
  getAuth0BaseConfig,
} from './auth0.config';

export const getAuth0Server = (market: Market) =>
  initAuth0({
    ...getAuth0BaseConfig(market),
    authorizationParams:
      getAuth0AuthorizationParametersForAudience(FIRST_AUDIENCE),
  });
