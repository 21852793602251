import { useRouter } from 'next/router';
import { FormEvent, useState } from 'react';
import { useLockBodyScroll, useWindowSize } from 'react-use';
import { CustomLink as Link } from '@/components/custom-link';
import Button from '@/components/inputs/button';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import useBasePath from '@/hooks/use-base-path';
import useSearch from '../../hooks/use-search';
import type { SearchBarProperties } from './search-bar.types';
import { VARIANT as ICON_VARIANT } from '../../components/data-display/icon/icon.types';
import styles from './search-bar.module.scss';
import { DATA_IDS, WINDOW_BREAKPOINT_WIDTH } from '../../types';
import { EVENT_NAME } from '../../utils/track-event/track-event.types';
import trackEvent from '../../utils/track-event';
import { useFeatureTogglesContext } from '../../context/feature-toggles/feature-toggles-context';
import { FEATURE_TOGGLE_LIST } from '../../context/feature-toggles/feature-toggles.types';

const SearchBar = ({
  searchPlaceholderText,
  closeLabel,
  toggleSearchLabel,
  searchLabel,
}: SearchBarProperties) => {
  const featuresToggles = useFeatureTogglesContext();

  const disabledCLO = !featuresToggles.includes(FEATURE_TOGGLE_LIST.CLO);

  const { push, query } = useRouter();
  const { basePath } = useBasePath();
  const { searchResults, searchValue, setSearchValue } = useSearch(
    null,
    query?.search as string,
    disabledCLO,
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [mobileSearch, setMobileSearch] = useState<boolean>(false);
  const [isMenuHovered, setIsMenuHovered] = useState<boolean>(false);
  const { width: innerWidth } = useWindowSize();

  const goToSearch = () => {
    const retailersPathname = `${basePath}/retailers/`;
    const newQuery = {
      ...(searchValue && { search: encodeURIComponent(searchValue) }),
    };
    push(
      {
        query: newQuery,
      },
      { pathname: retailersPathname, query: newQuery },
      undefined,
    );
  };
  const handleOnChange = ({ currentTarget }: FormEvent<HTMLInputElement>) =>
    setSearchValue(currentTarget.value);
  const handleOnKeyUp = ({ key }: React.KeyboardEvent<HTMLInputElement>) =>
    key === 'Enter' && goToSearch();
  const handleOnFocus = () => setIsFocused(true);
  const handleOnBlur = () => setIsFocused(false);
  const handleMenuEnter = () => setIsMenuHovered(true);
  const handleMenuLeave = () => setIsMenuHovered(false);

  const toggleMobileSearch = () => {
    setMobileSearch(!mobileSearch);
  };

  useLockBodyScroll(
    mobileSearch && innerWidth < WINDOW_BREAKPOINT_WIDTH.Medium,
  );

  const handleClick = (partnerName: string) => {
    trackEvent({
      event: EVENT_NAME.SITE_SEARCH,
      search_term: searchValue,
      search_result_count: partnerName,
    });
  };

  return (
    <div className={styles['search-bar']}>
      <Button
        variant={BUTTON_VARIANT.Unstyled}
        className={styles['search-bar__mobile-toggle']}
        onClick={toggleMobileSearch}
        aria-label={toggleSearchLabel}
        title={toggleSearchLabel}
        type="button"
        icon={ICON_VARIANT.Search}
        data-id={ICON_VARIANT.Search}
      />
      <div
        className={`${styles['search-bar__underlay']} ${
          mobileSearch ? styles['search-bar__mobile-visible'] : ''
        }`}
        onMouseEnter={handleMenuEnter}
        onMouseLeave={handleMenuLeave}
      >
        <div className={styles['search-bar__container']}>
          <input
            className={styles['search-bar__text-box']}
            type="text"
            placeholder={searchPlaceholderText}
            value={searchValue}
            onChange={handleOnChange}
            onKeyUp={handleOnKeyUp}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            data-id={DATA_IDS.SEARCH_TEXT}
          />
          <Button
            variant={BUTTON_VARIANT.Unstyled}
            className={styles['search-bar__search-btn']}
            onClick={goToSearch}
            aria-label={searchLabel}
            title={searchLabel}
            type="button"
            data-id={DATA_IDS.SEARCH_BUTTON}
            icon={ICON_VARIANT.Search}
          />

          {(isMenuHovered || isFocused) && searchResults.length > 0 && (
            <div
              className={styles['search-bar__result']}
              onMouseEnter={handleMenuEnter}
              onMouseLeave={handleMenuLeave}
            >
              {searchResults
                .filter(({ url }) => url)
                .map(({ name, url }) => (
                  <Link
                    key={name}
                    href={url as string}
                    className={styles['search-bar__result-item']}
                    title={name}
                    onClick={() => handleClick(name)}
                  >
                    {name}
                  </Link>
                ))}
            </div>
          )}
        </div>

        <Button
          variant={BUTTON_VARIANT.Unstyled}
          className={`button-icon ${styles['search-bar__mobile-close']}`}
          onClick={toggleMobileSearch}
          aria-label={closeLabel}
          title={closeLabel}
          type="button"
          icon={ICON_VARIANT.X}
        />
      </div>
    </div>
  );
};

export default SearchBar;
