import classNames from 'classnames';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import type { ErrorMessageProperties } from './error-message.types';
import styles from './error-message.module.scss';

const ErrorMessage = ({
  className = '',
  errorMessage,
  showIcon,
  extra,
}: ErrorMessageProperties) => {
  return (
    <div className={classNames(styles['error-message'], className)}>
      {showIcon && (
        <Icon
          variant={ICON_VARIANT.Exclamation}
          className={styles['error-message__icon']}
        />
      )}
      <p className={styles['error-message__message']}>{errorMessage}</p>
      {extra}
    </div>
  );
};

export default ErrorMessage;
