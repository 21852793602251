const CtaFieldsLabel = 'ctaFields';

const CtaFieldsFragment = `
fragment ${CtaFieldsLabel} on Cta {
  developerRef
  label
  url
}
`;

const MembershipDetailsFieldsLabel = 'membershipDetailsFields';

const MembershipDetailsFieldsFragment = `
fragment ${MembershipDetailsFieldsLabel} on MembershipDetails {
  totalAviosLabel
  closeDetailsButtonTitle
  myAccountCta {
    ...${CtaFieldsLabel}
  }
  missingAviosCta {
    ...${CtaFieldsLabel}
  }
  auth0LogoutCta {
    ...${CtaFieldsLabel}
  }
  membershipNumberLabel
  ctaListCollection {
    items {
      ...${CtaFieldsLabel}
    }
  }
}
`;

export const headerQuery = `
${MembershipDetailsFieldsFragment}
${CtaFieldsFragment}
query HeaderQuery($contentfulOpCoId: String, $countryId: String, $locale: String) {
  headerCollection(limit: 1, where: {programId: $contentfulOpCoId, countryId: $countryId}) {
    items {
      programLogo {
        url
        title
      }
      programLogoSmallScreen {
        url
        title
      }
      navigationCollection {
        items {
          label
          urlPath
          isHighlighted
        }
      }
      membershipDetails {
        ...${MembershipDetailsFieldsLabel}
      }
    }
  }
  microcopyCollection(locale: $locale, where: {
		key_in: [ "login.login", "general.membershipDetailsLabel", "general.homeLabel"]
	  }) {
		items {
		  key
		  value
		}
	}
}`;
