export const featureTogglesContentfulQuery = `query FeatureTogglesQuery($contentfulOpCoId: String!, $countryId: String!){
    featureTogglesCollection (where: {programId: $contentfulOpCoId, countryId: $countryId}){
    items{
      featuresCollection{
        items{
          id
        }
      }
    }
  }
}`;
