import { useEffect } from 'react';
import dataDogRum from '@/utils/data-dog-rum';
import { useRouter } from 'next/router';
import trackEvent from '../track-event';
import { createGlobalEventObject } from './use-metrics.utils';
import { UseMetricsParameters } from './use-metrics.types';

export function useMetrics({
  programData,
  isLoggedIn,
  membershipId,
  balance,
  daysSinceLastPurchase,
  applicationEnvironment,
}: UseMetricsParameters) {
  const { asPath } = useRouter();

  useEffect(() => {
    dataDogRum.setGlobalContextProperty('program', {
      id: programData.programmeCode,
      countryCode: programData.Iso,
    });

    dataDogRum.setGlobalContextProperty('isLoggedIn', isLoggedIn);

    if (isLoggedIn) {
      dataDogRum.setUser({
        id: membershipId,
        balance,
        daysSinceLastPurchase,
      });
    }
  });

  useEffect(() => {
    trackEvent(
      createGlobalEventObject({
        isLoggedIn,
        programData,
        membershipId,
        balance,
        applicationEnvironment,
      }),
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);
}
