import Image from 'next/image';
import classNames from 'classnames';
import contentfulLoader from '@/utils/loader';
import styles from './image.module.scss';
import type { NextImageProperties } from './image.types';

export const CustomImage = ({ isStorybook, ...rest }: NextImageProperties) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <Image loader={isStorybook ? undefined : contentfulLoader} {...rest} />
);

const NextImage = ({
  isLocal,
  src,
  className,
  overideHeight,
  isStorybook = false,
  ...rest
}: NextImageProperties) => {
  if (!src) return null;
  const imageSource =
    isLocal || /\bhttps?:\/\//.test(src) ? src : `https:${src}`;

  return (
    <div
      className={classNames(styles['image-container'], className)}
      style={overideHeight ? { height: overideHeight } : {}}
    >
      <CustomImage src={imageSource} isStorybook={isStorybook} {...rest} />
    </div>
  );
};

export default NextImage;
