import ContentfulProvider from '../../providers/contentful';
import type {
  CookieBannerProperties,
  CookieBannerContentfulResponse,
} from './cookie-banner.types';
import Market from '../../models/market';
import { cookieBannerQuery } from './cookie-banner.query';
import { formatMicrocopy } from '../../utils/format-microcopy';

export const cookieBannerHandler = async (
  market: Market,
): Promise<CookieBannerProperties> => {
  const contentfulRequest = new ContentfulProvider(
    market,
  ).makeContentfulRequest<CookieBannerContentfulResponse>({
    query: cookieBannerQuery,
  });

  const contentfulResponse = await contentfulRequest;

  const {
    microcopyCollection: { items },
  } = contentfulResponse.data;
  const {
    cookienotice_accept: cookieAcceptLabel = '',
    cookienotice_close: cookieCancelLabel = '',
    cookienotice_message: cookieCopy = '',
  } = formatMicrocopy(items);
  return {
    cookieCopy,
    cookieAcceptLabel,
    cookieCancelLabel,
  };
};
