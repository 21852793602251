import useBasePath from '@/hooks/use-base-path';
import Link from 'next/link';
import { forwardRef } from 'react';

type CustomLinkParameters = Parameters<typeof Link>[0];

// For internal urls, `${AVIOS_PREFIX}` prefixing is only added when href is a string prefixed with a single slash
// e.g.
// * `/collect/how-it-works` => `${AVIOS_PREFIX}/collect/how-it-works`
// * `collect/how-it-works` => `collect/how-it-works` (no changes)
// * `https://avios.com/collect/how-it-works` => `https://avios.com/collect/how-it-works` (no changes)
// * `//avios.com/collect/how-it-works` => `//avios.com/collect/how-it-works` (no changes)
export const CustomLink = forwardRef(function CustomLinkComponent(
  { href, children, ...linkArguments }: CustomLinkParameters,
  reference,
) {
  const { basePath } = useBasePath();
  if (
    typeof href === 'string' &&
    href.startsWith('/') &&
    !href.startsWith('//')
  ) {
    // eslint-disable-next-line no-param-reassign
    href = `${basePath}${href}`;
  }

  return (
    <Link
      href={href}
      {...linkArguments}
      prefetch={false}
      ref={reference as React.Ref<HTMLAnchorElement>}
    >
      {children}
    </Link>
  );
});
