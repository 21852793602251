import { ErrorResponse } from '../../utils/fetch/fetch.types';
import type FSProvider from './financial-services.provider';

export type Config = {
  BASE_URL: string;
  API_KEY: string;
};

export type RETENTION_STRING = string;

export type GetFinancialServicesResponse = {
  MembershipNumber: string;
  Scheme: string;
  Next_best_retention_FS1: RETENTION_STRING;
  Next_best_acquisition_FS1: string;
  Next_best_retention_FS2: string;
  Next_best_acquisition_FS2: string;
  widget: FinancialServicesWidgetResponse;
} & ErrorResponse;

export type FormattedAmexMGMWidget = {
  title: string;
  toggleText: string;
  closeLabel: string;
  card: {
    cardImage: string;
  };
  offers: Array<{
    title: string;
    value: string;
    extraDescription?: string;
    description: string;
  }>;
  share: {
    title: string;
    referralLink: string;
    copyText: string;
    copiedText: string;
    copiedDialogText: string;
  };
  terms: {
    termsCta: string;
    termsLabel: string;
    termsContent: string;
    termsExtraContent: string;
  };
} | null;

export type FinancialServicesWidgetResponse = {
  external_rafaas_responses: Array<{
    response_status_code: string;
    response_status_message_text: string;
    response_status_nm: string;
    http_status: string;
    short_url: string;
    gen_code: string;
    digital_asset_id: string;
    product_code: string;
    mgmer_name_jwt: string;
    header_content: {
      product_vanity_name: string;
      card_art_url: string;
      card_art_hover_text: string;
      mgm_header: string;
      product_highlight: string;
    };
    mgmer_offer_content: {
      bonus_amount_cap: string;
      offer_copy: string;
      bonus_currency_txt: string;
      bonus_amount: string;
      addnl_offer_copy: string;
      extra_copy: string;
    };
    mgmee_offer_content: {
      offer_copy: string;
      bonus_amount: string;
      addnl_offer_copy: string;
    };
    share_track_content: {
      enable_native_share: boolean;
      email_share_type: string;
      use_case: string;
      pre_populate_email_subject: string;
      mgmer_product_tracking_id: string;
      mgmer_offer_tracking_flag: string;
      refer_options: Array<{
        max_count: number;
        refer_type: string;
        status: boolean;
        order: number;
      }>;
      email_copy: string;
      email_text: string;
      pre_populate_email_copy: string;
      mgmer_poid: string;
      cheetah_mail_template_id: string;
      mgmee_offer_tracking_flag: string;
      share_prod_id: string;
      content_tracking_id: string;
      mgmeeExtLinkTrackingId: {
        twitter: string;
        facebook: string;
        email_re: string;
        copypaste: string;
        message: string;
        email: string;
      };
    };
    terms_and_conditions: {
      terms_and_condition_link: string;
      terms_and_condition_text: string;
      terms_and_condition_copy: string;
      detailed_terms?: Record<string, unknown>;
      initial_terms: {
        anchor_txt: string;
        anchor_prefix_txt: string;
      };
      display_detailed_terms: boolean;
      display_consent_check_box: boolean;
      consent_checkbox_txt: string;
    };
    additional_content: {
      HUB_ITAG: string;
      OCE_STATIC_COPY: string;
    };
    how_it_works: string[];
    spl_offer_details: {
      srl_referral_count: number;
    };
  }>;
};

export type FinancialServiceData = Awaited<
  ReturnType<FSProvider['getFinancialServices']>
>;

export enum PLACEMENT_ID {
  BRITISH_AIRWAYS = 'BRITISH_AIRWAYS',
}
