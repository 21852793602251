import { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import Button from '@/components/inputs/button';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import { DATA_IDS } from '@/types';
import { ModalProperties, MODAL_CLOSE_TYPE } from './modal.types';
import styles from './modal.module.scss';
import { closeModal } from './modal.utils';

export const Modal = ({
  label,
  closeLabel,
  children,
  closeOnOutsideClick = false,
  onCloseFunction,
  disableCloseButton = false,
  className = '',
}: ModalProperties) => {
  const handleCloseModal = useCallback(
    (closeType: MODAL_CLOSE_TYPE) => {
      if (onCloseFunction) {
        onCloseFunction(closeType);
      }
      closeModal(label);
    },
    [onCloseFunction, label],
  );
  const handleCloseButton = useCallback(
    () => handleCloseModal(MODAL_CLOSE_TYPE.BUTTON),
    [handleCloseModal],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (disableCloseButton) event.preventDefault();
        else handleCloseModal(MODAL_CLOSE_TYPE.OUTSIDE);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [disableCloseButton, handleCloseModal]);

  useEffect(() => {
    const modalElement = window.document.querySelector(
      `dialog#${label}`,
    ) as HTMLDialogElement;

    const clickOutsideListener = (event: MouseEvent) => {
      const modalDimensions = modalElement.getBoundingClientRect();

      if (
        event.clientX < modalDimensions.left ||
        event.clientX > modalDimensions.right ||
        event.clientY < modalDimensions.top ||
        event.clientY > modalDimensions.bottom
      ) {
        handleCloseModal(MODAL_CLOSE_TYPE.OUTSIDE);
      }
    };
    if (closeOnOutsideClick) {
      modalElement.addEventListener('click', clickOutsideListener);
    }

    return () => {
      if (closeOnOutsideClick) {
        modalElement.removeEventListener('click', clickOutsideListener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeOnOutsideClick, label]);

  return (
    <dialog
      className={classNames(styles.modal, className)}
      id={label}
      data-id={label}
      aria-label={label}
    >
      {!disableCloseButton && (
        <Button
          className={styles['modal__exit-button']}
          onClick={handleCloseButton}
          data-id={DATA_IDS.MODAL_CLOSE}
          icon={ICON_VARIANT.X}
          variant={BUTTON_VARIANT.Text}
          aria-label={closeLabel}
          title={closeLabel}
          autoFocus={false}
        />
      )}
      <div>{children}</div>
    </dialog>
  );
};

export default Modal;
