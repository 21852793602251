import { EVENT_NAME } from '@/utils/track-event/track-event.types';
import {
  GENERIC_VALUES,
  ROUTES_DESCRIPTIONS,
  USER_CLICK_DOM_ELEMENT_LOOKUP,
} from './track-clicks.types';

export const filterOutLocaleFromUrlArray = (
  array: string[],
  locale?: string,
) => {
  if (array.length === 0 || !locale) {
    return array;
  }
  return array.filter(item => {
    return item !== locale;
  });
};

export const trackUserClick = (event: Event) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let { target }: any = event;
  let result: {
    event: EVENT_NAME;
    nav_type: string;
    nav_action: any;
  } | null = null;
  let headerOrFooter: 'header' | 'footer' | '' = '';
  let sectionAriaLabel: string | GENERIC_VALUES | undefined;

  // Traverse up the DOM tree up to 12 levels
  for (let index = 0; index < 12 && target; index += 1) {
    // eslint-disable-next-line default-case
    switch (target.tagName) {
      case USER_CLICK_DOM_ELEMENT_LOOKUP.HEADER: {
        headerOrFooter = 'header';
        break;
      }
      case USER_CLICK_DOM_ELEMENT_LOOKUP.FOOTER: {
        headerOrFooter = 'footer';
        break;
      }
      case USER_CLICK_DOM_ELEMENT_LOOKUP.SECTION: {
        sectionAriaLabel =
          sectionAriaLabel ??
          target.getAttribute('aria-label') ??
          GENERIC_VALUES.ERROR;
        break;
      }
      case USER_CLICK_DOM_ELEMENT_LOOKUP.A: {
        result = result ?? getUserClickResultFromAnchor(target);

        break;
      }
      case USER_CLICK_DOM_ELEMENT_LOOKUP.BUTTON: {
        result = result ?? getUserClickFromButton(target);

        break;
      }
    }

    if (result && headerOrFooter && sectionAriaLabel) {
      break;
    }

    target = target.parentNode as HTMLElement;
  }

  return (
    result && {
      ...result,
      nav_action: formatNavAction(headerOrFooter, result, sectionAriaLabel),
    }
  );
};

export const formatNavAction = (
  headerOrFooterClicked: string,
  trackableClick?: {
    nav_action?: string;
  },
  sectionAriaLabel?: string,
) => {
  if (headerOrFooterClicked) {
    return `[1] ${headerOrFooterClicked} [2] ${
      trackableClick?.nav_action?.toLowerCase() ?? GENERIC_VALUES.ERROR
    }`;
  }

  const urlSlug = getUrlSlug(window.location.pathname);

  const section = sectionAriaLabel
    ? sectionAriaLabel.toLowerCase()
    : GENERIC_VALUES.ERROR;
  const navAction =
    trackableClick?.nav_action?.toLowerCase() ?? GENERIC_VALUES.ERROR;

  return `[1] ${urlSlug} [2] ${section} [3] ${navAction}`;
};

export const getUrlSlug = (
  urlPathName: string,
  locale?: string,
  pageType?: boolean,
) => {
  const hrefArray = urlPathName.split('/').filter(Boolean);
  const hrefArrayWithoutLocale = filterOutLocaleFromUrlArray(hrefArray, locale);
  const path = `/${hrefArrayWithoutLocale.join('/')}`;
  if ((ROUTES_DESCRIPTIONS as Record<string, string>)[path]) {
    return (ROUTES_DESCRIPTIONS as Record<string, string>)[path];
  }
  if (path.startsWith('/retailers/')) {
    return pageType
      ? 'partner display page'
      : hrefArrayWithoutLocale[hrefArrayWithoutLocale.length - 1];
  }
  if (path.startsWith('/retailers?search')) {
    return pageType ? 'search results page' : 'retailers';
  }
  return hrefArrayWithoutLocale[hrefArrayWithoutLocale.length - 1].replace(
    /-/g,
    ' ',
  );
};

const getUserClickFromButton = (target: any) => {
  const buttonTextOrAriaLabel =
    target.value ||
    target.getAttribute('aria-label') ||
    target.getAttribute('title');

  return {
    event: EVENT_NAME.CTA_GLOBAL,
    nav_type: 'cta',
    nav_action: buttonTextOrAriaLabel?.toLowerCase(),
  };
};

const getUserClickResultFromAnchor = (target: any) => {
  const linkLabel =
    target.childNodes[0]?.nodeValue ||
    target.getAttribute('title') ||
    target.childNodes[0].getAttribute('slug') ||
    target.childNodes[0]?.textContent;

  return {
    event: EVENT_NAME.CTA_GLOBAL,
    nav_type: 'cta',
    nav_action: linkLabel?.toLowerCase(),
  };
};
