import ContentfulProvider from '../../providers/contentful';
import MarketDataLoader from '../../utils/market-data-loader';
import { Market } from '../market/market.types';
import { collectionMechanicContentfulQuery } from './collection-mechanic.query';
import {
  ContentfulTAndC,
  ContentfulTAndCsResponse,
} from './collection-mechanic.types';

const fetchData = (market: Market, keys: readonly string[]) =>
  new ContentfulProvider(
    market,
  ).makeContentfulRequest<ContentfulTAndCsResponse>({
    query: collectionMechanicContentfulQuery,
    variables: {
      collectionMechanics: keys,
    },
  });

const mapResponse = (keys: readonly string[], data: ContentfulTAndCsResponse) =>
  keys.map(key =>
    Object.freeze(
      data.collectionMethodCollection.items.find(
        collectionMechanic => collectionMechanic.collectionMethodId === key,
      ),
    ),
  );

const batcher = (market: Market) => async (keys: readonly string[]) => {
  const response = await fetchData(market, keys);

  return mapResponse(keys, response.data);
};

class CollectionMechanicContentfulLoaderSingleton extends MarketDataLoader<
  ContentfulTAndC,
  string
> {
  private static instance: CollectionMechanicContentfulLoaderSingleton =
    new CollectionMechanicContentfulLoaderSingleton();

  constructor() {
    if (CollectionMechanicContentfulLoaderSingleton.instance) {
      throw new Error(
        'Error: Instantiation failed: Use CollectionMechanicLoaderSingleton.getInstance() instead of new.',
      );
    }

    super(batcher);

    CollectionMechanicContentfulLoaderSingleton.instance = this;
  }

  public static getInstance() {
    return CollectionMechanicContentfulLoaderSingleton.instance;
  }
}

const CollectionMechanicContentfulLoader =
  CollectionMechanicContentfulLoaderSingleton.getInstance();

export default CollectionMechanicContentfulLoader;
