import type { DataDogRumProperties } from './data-dog-rum.types';

function createDataDogRumMethod<T extends keyof DataDogRumProperties>(
  methodName: T,
): DataDogRumProperties[T] {
  return ((...arguments_: Parameters<DataDogRumProperties[T]>) => {
    if (window.DD_RUM && typeof window.DD_RUM[methodName] === 'function') {
      const method = window.DD_RUM[methodName] as (
        ...arguments__: Parameters<DataDogRumProperties[T]>
      ) => void;
      method(...arguments_);
    }
  }) as DataDogRumProperties[T];
}

const dataDogRum: DataDogRumProperties = {
  setGlobalContextProperty: createDataDogRumMethod('setGlobalContextProperty'),
  setUser: createDataDogRumMethod('setUser'),
  addAction: createDataDogRumMethod('addAction'),
  addError: createDataDogRumMethod('addError'),
};

export default dataDogRum;
