import { COUNTRY_ID, Market, OPCO_ID } from '../../models/market/market.types';
import { MAPPED_COUNTRY, MAPPED_OPCO } from './recommended-retailers.types';

export function mapMarket(market: Market) {
  const opCoMapping = {
    [OPCO_ID.AVIOS]: MAPPED_OPCO.BAEC,
    [OPCO_ID.BAEC]: MAPPED_OPCO.BAEC,
    [OPCO_ID.VYC]: MAPPED_OPCO.VUEL,
    [OPCO_ID.IBP]: MAPPED_OPCO.IBPLU,
    [OPCO_ID.EIC]: MAPPED_OPCO.EIAC,
  };

  const programmeInfoMapping = {
    [OPCO_ID.AVIOS]: MAPPED_OPCO.BAEC,
    [OPCO_ID.BAEC]: MAPPED_OPCO.BAEC,
    [OPCO_ID.VYC]: MAPPED_OPCO.ATRP,
    [OPCO_ID.IBP]: MAPPED_OPCO.ATRP,
    [OPCO_ID.EIC]: MAPPED_OPCO.ATRP,
  };

  const countryMapping = {
    [COUNTRY_ID.US]: MAPPED_COUNTRY.US,
    [COUNTRY_ID.GB]: MAPPED_COUNTRY.GB,
    [COUNTRY_ID.ES]: MAPPED_COUNTRY.ES,
    [COUNTRY_ID.IE]: MAPPED_COUNTRY.IE,
    [COUNTRY_ID.FR]: MAPPED_COUNTRY.FR,
    [COUNTRY_ID.IT]: MAPPED_COUNTRY.IT,
  };

  const mappedOPCO = opCoMapping[market.opCoId] || market.opCoId;
  const countryCode = countryMapping[market.countryId] || market.countryCode;
  const ProgrammeInfo = programmeInfoMapping[market.opCoId] || market.opCoId;

  return {
    OPCO: mappedOPCO,
    countryCode,
    ProgrammeInfo,
  };
}
