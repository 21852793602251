import { ButtonAsButton } from '@/components/inputs/button/button.types';
import { YoutubeEmbedProperties } from '@/components/social-media/youtube-embed/youtube-embed.types';
import { ToogleFeatureType } from '@/hooks/use-handle-favourites/use-handle-favourites.types';
import { PartnerTileProperties } from '@/components/integrated/partner-tile/partner-tile.types';
import { ReactElement } from 'react';

export type ButtonCollectionProperties = {
  image?: {
    url: string;
    alt: string;
    height?: number;
    width?: number;
    description?: string;
  };
  label?: string;
} & ButtonAsButton;

export type VersatileCardProperties = {
  id?: string;
  htmlId?: string | null;
  backgroundColor?: string | null;
  content: VersatileCardContent;
  image?: VersatileCardImage;
  links?: VersatileCardLinks;
  ctas?: VersatileCardCtas;
  className?: string;
  contentClassName?: string;
  ctasClassName?: string;
  youtube?: YoutubeEmbedProperties | null;
  favourite?: VersatileCardFavourite;
  borderless?: boolean;
  spaceBetweenHeadingAndBody?: boolean;
  buttonsCollection?: ButtonCollectionProperties[] | null;
};

export type VersatileCardFavourite = {
  favouriteId?: number;
  showFavourite?: boolean;
  isFavourite?: boolean;
  toggleFavourite?: (
    value: boolean,
    partnerId?: number,
    favouriteId?: number,
  ) => Promise<ToogleFeatureType> | void;
  favouritesLabels?: {
    addToFavourites: string;
    removeFromFavourites: string;
  };
};

export type VersatileCardContent = {
  headingOne?: string | null;
  headingTwo?: string | null;
  headingThree?: string | null;
  headingFour?: string | null;
  headingFive?: string | null;
  body?: string | null;
  textAlign?: TEXT_ALIGN | null;
  textColor?: TEXT_COLOR | null;
  partner?: PartnerTileProperties | null;
};

export type VersatileCardImageOptimised = {
  description: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
} | null;

export type VersatileCardImage = {
  src: string;
  alt: string;
  position: IMAGE_POSITION;
  height?: number;
  mobileHeight?: number;
  width?: string | null;
  contained?: boolean;
  optimised: VersatileCardImageOptimised;
};

export type VersatileCardLinks = {
  linkLabelPrimary?: string | null;
  linkPrimary?: string | null;
  linkLabelSecondary?: string | null;
  linkSecondary?: string | null;
  openLinksInNewTab?: boolean | null;
  cardIsPrimaryLink?: boolean | null;
  linkFunction?: () => void;
};

export type VersatileCardCtas = {
  ctaFullWidth?: boolean;
  imageButtonPrimary?: ImageButtonProperties;
  imageButtonSecondary?: ImageButtonProperties;
  openCtasInNewTab?: boolean | null;
  ctaFunction?: () => void;
};

export type ImageButtonProperties = {
  url: string;
  src: string;
  alt: string;
  height?: number;
  width?: number;
  handleOnClick?: () => void;
};

export enum IMAGE_POSITION {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

export enum TEXT_ALIGN {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum TEXT_COLOR {
  Primary = 'primary',
  Black = 'black',
  White = 'white',
}

export interface ClickableProperties {
  linkPrimary: string;
  linkLabelPrimary: string;
  linkAnalyticsTag: string;
}

export interface ClickWrapperProperties {
  clickableProperties: ClickableProperties | null;
  children: ReactElement;
}
