import { APPLICATION_ENVIRONMENT } from '../../middleware/application-environment/application-environment.types';
import { Market } from '../../models/market/market.types';
import { B2BToken } from '../../types';
import Fetch from '../../utils/fetch';
import getRecommendedRetailersConfig from './recommended-retailers.config';
import {
  GetAcessTokenResponseType,
  RecommendedRetailersResponse,
} from './recommended-retailers.types';

class RecommendedRetailersProvider extends Fetch {
  private config;

  constructor(private market: Market, b2bToken: B2BToken) {
    const recommendedRetailersConfig = getRecommendedRetailersConfig(market);

    super('');

    this.config = recommendedRetailersConfig;

    if (this.config.ENV === APPLICATION_ENVIRONMENT.Production) {
      this.setHeaders(b2bToken);
    }
  }

  private setHeaders(accessToken: string) {
    this.headers = {
      api_key: this.config.RECOMMENDED_RETAILERS_API_KEY,
      authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    };
  }

  async getRecommendedRetailers(membershipId: string) {
    await this.checkAccessToken();

    const response = await this.get<RecommendedRetailersResponse>(
      `${this.config.RECOMMENDED_RETAILERS_BASE_URL}/member-recommendations?MembershipId=${membershipId}&Schema=${this.config.programmeInfo.OPCO}&CountryCode=${this.config?.programmeInfo?.countryCode}`,
    );

    if (response?.hasError) return [];
    return response?.merchants;
  }

  async getRelatedRetailers(merchantId: number) {
    await this.checkAccessToken();

    const response = await this.get<RecommendedRetailersResponse>(
      `${this.config.RECOMMENDED_RETAILERS_BASE_URL}/related-merchants?MerchantId=${merchantId}&CountryCode=${this.config?.programmeInfo?.countryCode}`,
    );
    if (response?.hasError) return [];
    return response?.merchants;
  }

  private setAcessTokenHeaders() {
    this.headers = {
      'programme-identifier': this.config?.programmeInfo?.ProgrammeInfo,
      Authorization: `Basic ${this.config.RECOMMENDED_RETAILERS_ACCESS_TOKEN_KEY}`,
      'content-type': 'application/x-www-form-urlencoded',
    };
  }

  private async checkAccessToken() {
    if (this.config.ENV !== APPLICATION_ENVIRONMENT.Production) {
      const accessToken = await this.getAccessToken();
      if (accessToken) {
        this.setHeaders(accessToken);
      }
    }
  }

  async getAccessToken() {
    this.setAcessTokenHeaders();
    const response = await this.post<GetAcessTokenResponseType>(
      `${this.config.RECOMMENDED_RETAILERS_ACCESS_TOKEN_URL}?grant_type=client_credentials`,
    );

    return response.access_token;
  }
}

export default RecommendedRetailersProvider;
