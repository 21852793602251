import { NoAutoClaimMerchant } from '@/modules/missing-avios-page/missing-avios-page-module.types';
import { ErrorResponse } from '../../utils/fetch/fetch.types';

export type Config = {
  BASE_URL: string;
  BASE_URL_AUTOCLAIM: string;
  AUTH_HASH: string;
  PARTNER_HASH: string;
  API_KEY: string;
  PARTNER_ID: string;
};

export type GetParamatersRequest = {
  limit?: string;
  page?: string;
  status?: string;
};

export type Response<T> = {
  data: T;
} & ErrorResponse;

export type ClicksData = {
  id: string;
  name: string;
  date: string;
  merchant_id: string;
  clickDate?: string;
  partner?: {
    id?: number | null;
    name?: string;
    slug?: string;
    altLogo?: string;
    logo?: string;
    destinationUrl?: string;
    externalReference?: string;
  };
};

export type GetClicksResponse = Response<ClicksData[]> &
  ErrorResponse &
  CollinsonPagination;

export type CollinsonPagination = {
  stats?: {
    total: number;
    pages: number;
    page: number;
    count: number;
  };
};

export type GetPurchaseHistoryResponse = Response<PurchaseHistoryData[]> &
  ErrorResponse &
  CollinsonPagination;

export type PurchaseHistoryData = {
  id: string;
  name: string;
  date: string;
  status: string;
  currency: string;
  amount: string;
  commission: string;
  merchant_id?: string;
};

export type CreateNewClickRequest = {
  merchant_id: string;
  promocode_id?: string;
  'extra[copartner]'?: string;
  'extra[language]'?: string;
  'extra[device]'?: string;
  'extra[source]'?: string;
  'extra[country]'?: string;
};

export type CreateNewClickResponse = Response<{
  tracking_url: string;
  id: string;
}>;

export type GetUserResponse = Response<{
  id: string;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  tracking_id: string;
  last_login: string;
  newsletter_subscription: string;
  merchant_subscription: string;
  blocked: string;
  suspected: string;
  balance: string;
}>;

export enum SHOP_NOW_STATUS {
  SUCCESS = 'shop_now.successful',
  FAILED = 'shop_now.failed',
  RETRY_SUCCESS = 'shop_now.retry.successful',
  RETRY_FAILED = 'shop_now.retry.failed',
}

export type CreateNewAutoClaimRequest = {
  clickID: number;
  purchaseAmount: number;
  purchaseTransactionID: string;
};

export type CreateNewAutoClaimResponse = Response<{
  purchaseID: string;
}>;

export type GetNoAutoClaimMerchantsResponse = Response<NoAutoClaimMerchant[]>;
