import { CustomImage as Image } from '@/components/data-display/image';
import {
  FOOTER_LABEL,
  LOCALE,
  LocaleSelectorLabelProperties,
} from './footer.types';
import styles from './footer.module.scss';

export const footerLocaleConfig = (
  footerLabels: { [key: string]: string },
  locale: string,
) => {
  switch (locale) {
    case LOCALE.EN_GB: {
      return {
        value: LOCALE.EN_GB,
        label: (
          <LocaleSelectorLabel
            language={footerLabels[FOOTER_LABEL.EN_GB]}
            icon="/images/flags/gb.png"
          />
        ),
      };
    }
    case LOCALE.EN_US: {
      return {
        value: LOCALE.EN_US,
        label: (
          <LocaleSelectorLabel
            language={footerLabels[FOOTER_LABEL.EN_US]}
            icon="/images/flags/us.png"
          />
        ),
      };
    }
    case LOCALE.EN_IE: {
      return {
        value: LOCALE.EN_IE,
        label: (
          <LocaleSelectorLabel
            language={footerLabels[FOOTER_LABEL.EN_IE]}
            icon="/images/flags/ie.png"
          />
        ),
      };
    }
    case LOCALE.ES_ES: {
      return {
        value: LOCALE.ES_ES,
        label: (
          <LocaleSelectorLabel
            language={footerLabels[FOOTER_LABEL.ES_ES]}
            icon="/images/flags/es.png"
          />
        ),
      };
    }
    default: {
      return {
        value: '',
        label: <LocaleSelectorLabel language="" icon="" />,
      };
    }
  }
};

export const LocaleSelectorLabel = ({
  icon,
  language,
}: LocaleSelectorLabelProperties) => (
  <>
    <Image
      className={styles['footer__top-country-selector-image']}
      width={24}
      height={16}
      src={icon}
      alt=""
      aria-hidden
    />

    <span
      className={styles['footer__top-country-selector-label']}
    >{`${language}`}</span>
  </>
);
