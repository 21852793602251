import { OPCO_ID_EXTERNAL_SERVICE } from '@/constants';
import { Market } from '../../models/market/market.types';
import Fetch from '../../utils/fetch';
import getQRCodeConfig from './qrcode.config';
import { Config } from './qrcode.types';

class QRCodeProvider extends Fetch {
  private config: Config;

  private opCoId: Market['opCoId'];

  constructor(private market: Market) {
    const QRCodeConfig = getQRCodeConfig();
    super(QRCodeConfig.API_URL);
    this.config = QRCodeConfig;
    this.opCoId = OPCO_ID_EXTERNAL_SERVICE[this.market.opCoId];
  }

  private setHeaders(accessToken: string) {
    this.headers = {
      api_key: this.config.API_KEY,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  public getCode(accessToken: string, memberId: string) {
    this.setHeaders(accessToken);
    return this.get<string>(`/act/qrcode/${this.opCoId}/${memberId}`);
  }
}

export default QRCodeProvider;
