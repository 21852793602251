import { isAndroid, isIOS } from 'react-device-detect';
import { useUser } from '@auth0/nextjs-auth0/client';
import Button from '@/components/inputs/button';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import NextImage from '@/components/data-display/image';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import { EVENT_NAME, NAV_ELEMENT } from '@/utils/track-event/track-event.types';
import trackEvent from '@/utils/track-event';
import { DownloadAppPromptProperties } from './download-app-prompt.types';
import styles from './download-app-prompt.module.scss';

const DownloadAppPrompt = ({
  title,
  description,
  appStoreLink,
  googlePlayLink,
  iconAlt,
  iconUrl,
  primaryCtaLabel,
  secondaryCtaLabel,
  closePrompt,
}: DownloadAppPromptProperties) => {
  const { user } = useUser();

  let primaryCTAurl = '';
  if (isAndroid) {
    primaryCTAurl = googlePlayLink;
  }
  if (isIOS) {
    primaryCTAurl = appStoreLink;
  }

  if (!title) return null;

  const handleButtonClicked = (location: string) => {
    trackEvent({
      event: EVENT_NAME.NAVIGATION,
      nav_element: NAV_ELEMENT.CTA,
      nav_action: `download app prompt ${location}`,
      isLoggedIn: Boolean(user),
    });
    if (closePrompt) {
      closePrompt();
    }
  };

  return (
    <div className={styles['download-app-prompt']}>
      <div className={styles['download-app-prompt__container']}>
        <NextImage width={64} height={64} src={iconUrl} alt={iconAlt} />
        <div className={styles['download-app-prompt__sub-container']}>
          <div className={styles['download-app-prompt__header']}>
            <p className={styles['download-app-prompt__title']}>{title}</p>
            <Button
              variant={BUTTON_VARIANT.Unstyled}
              icon={ICON_VARIANT.X}
              onClick={() => handleButtonClicked('dismissed')}
              title={secondaryCtaLabel}
              aria-label={secondaryCtaLabel}
              className={styles['download-app-prompt__close-icon']}
            />
          </div>
          <p className={styles['download-app-prompt__description']}>
            {description}
          </p>
          <div className={styles['download-app-prompt__buttons']}>
            <Button
              as="link"
              title={primaryCtaLabel}
              href={primaryCTAurl}
              target="_blank"
              className={styles['download-app-prompt__buttons__primary']}
              onClick={() => handleButtonClicked(primaryCtaLabel)}
            >
              {primaryCtaLabel}
            </Button>
            <Button
              type="button"
              onClick={() => handleButtonClicked(secondaryCtaLabel)}
              title={secondaryCtaLabel}
              className={styles['download-app-prompt__buttons__secondary']}
            >
              {secondaryCtaLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPrompt;
